import React from "react";
import * as ReactDOMClient from "react-dom/client";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { Button } from "react-bootstrap";

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: rgb(0, 0, 0, 0.5);
  z-index: 1100;
`;

export const CrossContainer = styled.div`
  cursor: pointer;
  font-size: 28px;
  position: absolute;
  right: 10px;
  top: -4px;
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background: white;
  border-radius: 5px;
  min-width: 200px;
  position: relative;
`;

export const ModalChildren = styled.div`
  padding: 20px;
`;

export const ModalButtons = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  > button {
    margin-left: 10px;
  }
`;

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  children,
  confirmButtonText,
  cancelButtonText,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  children: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ModalOverlay onClick={onClose}>
      <ModalContainer>
        <ModalContent>
          <ModalChildren>{children}</ModalChildren>
          <hr style={{ margin: 0 }} />
          <ModalButtons>
            <Button variant="secondary" onClick={onClose}>
              {cancelButtonText || "Cancel"}
            </Button>
            <Button variant="primary" onClick={onConfirm}>
              {confirmButtonText || "Confirm"}
            </Button>
          </ModalButtons>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>,
    document.body
  );
};

const confirmWithModal = async (
  content: React.ReactNode,
  options?: {
    confirmButtonText?: string;
    cancelButtonText?: string;
  }
): Promise<Boolean> => {
  return new Promise((resolve) => {
    const closeModal = () => {
      root.unmount();
      resolve(false); // User canceled the action
    };

    const confirmAction = () => {
      root.unmount();
      resolve(true); // User confirmed the action
    };

    const modalContainer = document.createElement("div");
    document.body.appendChild(modalContainer);

    const root = ReactDOMClient.createRoot(modalContainer);

    root.render(
      <ConfirmationModal
        isOpen={true}
        onClose={closeModal}
        onConfirm={confirmAction}
        confirmButtonText={options?.confirmButtonText}
        cancelButtonText={options?.cancelButtonText}
      >
        {content}
      </ConfirmationModal>
    );
  });
};

export default confirmWithModal;