import { gql } from "@apollo/client";

export const DELETE_CANDIDATE = gql`
  mutation deleteCandidate($candidateId: Int!) {
    deleteCandidate(candidateId: $candidateId) {
      id
    }
  }
`;

export const AMEND_CANDIDATE = gql`
  mutation amendCandidate(
    $id: Int!
    $firstName: String!
    $lastName: String!
    $localityId: Int!
    $salaryMin: Decimal!
    $salaryMax: Decimal!
    $industry: String!
    $yearsOfExperience: Int!
    $idealJobTitles: String!
    $share: Boolean
    $interviewed: Boolean!
    $summary: String!
    $workType: CandidateWorkType!
    $audience: Audience!
    $currency: Currency!
    $ownerUserIds: [Int!]
    $projectCandidateBoardIds: [String!]
  ) {
    amendCandidate(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        workType: $workType
        localityId: $localityId
        summary: $summary
        industry: $industry
        salaryMin: $salaryMin
        salaryMax: $salaryMax
        yearsOfExperience: $yearsOfExperience
        idealJobTitles: $idealJobTitles
        share: $share
        interviewed: $interviewed
        audience: $audience
        currency: $currency
        ownerUserIds: $ownerUserIds
        projectCandidateBoardIds: $projectCandidateBoardIds
      }
    ) {
      id
      firstName
      lastName
      workType
      location {
        displayName
      }
      summary
      salaryMin
      salaryMax
      yearsOfExperience
      idealJobTitles
    }
  }
`;

export const ADD_CANDIDATE = gql`
  mutation addCandidate(
    $firstName: String!
    $lastName: String!
    $middleName: String!
    $summary: String!
    $salaryMin: Decimal!
    $salaryMax: Decimal!
    $yearsOfExperience: Int!
    $idealJobTitles: String!
    $workType: CandidateWorkType!
    $industry: String!
    $jobTitle: String!
    $interviewed: Boolean!
    $share: Boolean!
    $audience: Audience!
    $localityId: Int!
    $currency: Currency!
    $ownerUserIds: [Int!]
    $projectCandidateBoardIds: [String!]
  ) {
    addCandidate(
      input: {
        firstName: $firstName
        lastName: $lastName
        middleName: $middleName
        summary: $summary
        salaryMin: $salaryMin
        salaryMax: $salaryMax
        yearsOfExperience: $yearsOfExperience
        idealJobTitles: $idealJobTitles
        workType: $workType
        industry: $industry
        jobTitle: $jobTitle
        interviewed: $interviewed
        share: $share
        audience: $audience
        localityId: $localityId
        currency: $currency
        ownerUserIds: $ownerUserIds
        projectCandidateBoardIds: $projectCandidateBoardIds
      }
    ) {
      id
      externalId
    }
  }
`;

export const IMPORT_CANDIDATE = gql`
  mutation ImportCandidate($candidateId: Int!) {
    importCandidate(candidateId: $candidateId) {
      id
      externalId
    }
  }
`;

export const ARCHIVE_CANDIDATE = gql`
  mutation ArchiveCandidate($candidateId: String!) {
    archiveCandidate(candidateId: $candidateId) {
      id
      status
    }
  }
`;

export const ACCEPT_PROJECT_CANDIDATE = gql`
  mutation AcceptProjectCandidate(
    $projectCandidateBoardId: String!
    $candidateId: String!
  ) {
    acceptProjectCandidate(
      projectCandidateBoardId: $projectCandidateBoardId
      candidateId: $candidateId
    ) {
      projectCandidateBoardId
      candidateId
      status
    }
  }
`;

export const REJECT_PROJECT_CANDIDATE = gql`
  mutation RejectProjectCandidate(
    $projectCandidateBoardId: String!
    $candidateId: String!
  ) {
    rejectProjectCandidate(
      projectCandidateBoardId: $projectCandidateBoardId
      candidateId: $candidateId
    ) {
      projectCandidateBoardId
      candidateId
      status
    }
  }
`;

export const SHARE_CANDIDATE_PROJECT_ATTACHMENTS = gql`
  mutation ShareCandidateProjectAttachments(
    $candidateId: String!
    $externalIds: [String!]!
    $projectId: String!
  ) {
    shareCandidateProjectAttachments(
      input: {
        candidateId: $candidateId
        externalIds: $externalIds
        projectId: $projectId
      }
    ) {
      candidateId
      externalId
      id
      projectId
    }
  }
`;
