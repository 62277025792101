import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Button, Form } from "react-bootstrap";
import { PlusSquare, XSquare } from "react-bootstrap-icons";
import { GET_PUBLIC_CANDIDATES, GET_PARTNER_CANDIDATES } from "../../GraphQL/candidates/queries";
import { GET_PARTNER_ROLES, GET_PUBLIC_ROLES } from "../../GraphQL/roles/queries";
import { CandidateWorkType } from "../../interfaces";
import { useLocation } from 'react-router-dom';
import styled, { css } from "styled-components";
import { getTabType } from "../../utils";
import advancedFilterQuery from "../../utils/advancedSearch/advancedFilterQuery";
import advancedSearchSummary from "../../utils/advancedSearch/advancedSearchSummary";

const Container = styled.div<{screen: boolean}>`
  border: 1px solid #ccc;
  z-index: 10;
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;

  ${(props => !props.screen && css`
    position: fixed;
    width: 420px;
    max-height: 360px;
    top: 50%;
    left: 50%;
    margin-top: -180px;
    margin-left: -210px;

    @media only screen and (min-width: 640px) {
      width: 500px;
      margin-left -250px;
    }

    @media only screen and (min-width: 768px) {
      width: 640px;
      margin-left: -320px;
    }
  `)}

  ${(props => props.screen && css`
    position: absolute;
    top: 50px;
    right: 100px;
    width: 700px;
  `)}
`;

const BackgroundMobileModal = styled.div<{screen: boolean}>`
  ${(props => props.screen && css`
    position: fixed;
    z-index: 5;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.4);
  `)}
`;

const ModalTitle = styled.p`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2rem;
  font-size: 22px;
  font-weight: 700;
`;

const FormContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const InputFormContainer = styled.div<{idx: boolean, mr: boolean}>`
  margin-right: ${props => props?.mr ? '10px' : ''};
  width: ${props => props?.idx ? '100%': '180px'};
`;

const ButtonSearchContainer = styled.div`
  margin-top: .5rem;
  float: right;
`;

const AddFormContainer = styled.div`
  margin: 1rem 0;
`;

const RemoveFormButton = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

interface Props {
  onShowSummary: (data: boolean) => void;
  onHandleSummaryContent: (data: string) => void;
  searchType: string;
  isMobile: boolean;
  onHandleModal: () => void;
  onHandleSearch: (data: any) => void;
  onHandleFilterQuery: (data: any) => void;
}

interface IPropsSearchField {
  relation: string;
  fieldName: string;
  criteria: string;
  searchValues: string | CandidateWorkType;
}

const AdvancedSearchModal = ({
  onShowSummary = () => {},
  onHandleSummaryContent = () => {},
  searchType,
  isMobile,
  onHandleModal,
  onHandleFilterQuery = () => {},
  onHandleSearch = () => {}}: Props
) => {
  const { pathname } = useLocation();
  const isOpenRoles = pathname === "/public-roles";
  const isOpenCandidates = pathname === "/public-candidates";

  let gqlQuery: any;
  let initFieldName: string = "";
  if (isOpenRoles) {
    gqlQuery = searchType === "public" ? GET_PUBLIC_ROLES : GET_PARTNER_ROLES;
    initFieldName = "title";
  } else if (isOpenCandidates) {
    gqlQuery = searchType === "public" ? GET_PUBLIC_CANDIDATES: GET_PARTNER_CANDIDATES;
    initFieldName = "idealJobTitles";
  }

  const { refetch: refetchRoles } = useQuery(gqlQuery, {
    variables: {
      search: "",
      skip: 0,
      take: 25,
      where: {},
    },
  });

  const [searchQuery, setSearchQuery] = useState<any>({});
  const [inputSearch, setInputSearch] = useState<IPropsSearchField[]>([{
    relation: "and",
    fieldName: initFieldName,
    criteria: "",
    searchValues: "",
  }]);

  const addNewInputField = () => {
    if (inputSearch.length < 5){
      setInputSearch([...inputSearch, {
        relation: "and",
        fieldName: initFieldName,
        criteria: "",
        searchValues: "",
      }]);
    }
  }

  const deleteInputField = (index: any) => {
    if (inputSearch.length > 1) {
      const rows = [...inputSearch];
      rows.splice(index, 1);
      setInputSearch(rows);
    }
  }

  const handleInputSearchChange = (index : any, event: any) => {
    const {name, value} = event.target;
    if (value === "-- Select --") return;
    const list: any = [...inputSearch];
    list[index][name] = value;
    setInputSearch(list);
  }

  const handleRunSearch = () => {
    const summaryContent = advancedSearchSummary(inputSearch, getTabType(pathname, searchType));
    const filterData = async () => {
      const { data } = await refetchRoles({
        skip: 0,
        take: 25,
        search: "",
        where: searchQuery,
      });

      if (isOpenRoles){
        searchType === "public" ?
          onHandleSearch(data?.publicJobs?.items) :
          onHandleSearch(data?.partnerJobs?.items);
      } else if (isOpenCandidates) {
        searchType === "public" ?
          onHandleSearch(data?.publicCandidates?.items) :
          onHandleSearch(data?.partnerCandidates?.items);
      }

      if (data){
        setTimeout(() => onHandleModal(), 500);
      }
    }
    filterData();
    onShowSummary(true);
    onHandleSummaryContent(summaryContent);
    onHandleFilterQuery(searchQuery);

    if (isOpenRoles){
      let storeSearchRoles = {};
      const getAdvancedSearchStorage = window?.localStorage?.getItem("advancedSearchRoles");
      const searchStorage = getAdvancedSearchStorage ? JSON.parse(getAdvancedSearchStorage ?? '') : '';
      if (searchType === "public") {
        storeSearchRoles = {
          publicRolesStatus: true,
          publicRolesFilter: searchQuery,
          publicRolesSummary: summaryContent,
          partnerRolesStatus: searchStorage?.partnerRolesStatus,
          partnerRolesFilter: searchStorage?.partnerRolesFilter,
          partnerRolesSummary: searchStorage?.partnerRolesSummary,
        };
      } else {
        storeSearchRoles = {
          publicRolesStatus: searchStorage?.publicRolesStatus,
          publicRolesFilter: searchStorage?.publicRolesFilter,
          publicRolesSummary: searchStorage?.publicRolesSummary,
          partnerRolesStatus: true,
          partnerRolesFilter: searchQuery,
          partnerRolesSummary: summaryContent,
        }
      }
      window.localStorage.setItem("advancedSearchRoles", JSON.stringify(storeSearchRoles));
    } else if (isOpenCandidates) {
      let storeSearchCandidates = {};
      const getStorageCandidates = window?.localStorage?.getItem("advancedSearchCandidates");
      const searchStorage = getStorageCandidates ? JSON.parse(getStorageCandidates ?? '') : '';
      if (searchType === "public"){
        storeSearchCandidates = {
          publicCandidatesStatus: true,
          publicCandidatesFilter: searchQuery,
          publicCandidatesSummary: summaryContent,
          partnerCandidatesStatus: searchStorage?.partnerCandidatesStatus,
          partnerCandidatesFilter: searchStorage?.partnerCandidatesFilter,
          partnerCandidatesSummary: searchStorage?.partnerCandidatesSummary,
        }
      } else {
        storeSearchCandidates = {
          publicCandidatesStatus: searchStorage?.publicCandidatesStatus,
          publicCandidatesFilter: searchStorage?.publicCandidatesFilter,
          publicCandidatesSummary: searchStorage?.publicCandidateSummary,
          partnerCandidatesStatus: true,
          partnerCandidatesFilter: searchQuery,
          partnerCandidatesSummary: summaryContent,
        }
      }
      window.localStorage.setItem("advancedSearchCandidates", JSON.stringify(storeSearchCandidates));
    }
  }

  useEffect(() => {
    const query = advancedFilterQuery(inputSearch);
    setSearchQuery(query);
  }, [inputSearch]);

  return (
    <BackgroundMobileModal screen={isMobile}>
      <Container screen={!isMobile}>
        <ModalTitle>Advanced Search</ModalTitle>
        {inputSearch.map((data, index) => {
          return (
            <FormContainer key={index}>
              {index > 0 && (
                <div style={{minWidth: '90px', marginRight: '10px'}}>
                  <Form.Select name="relation" onChange={(event) => handleInputSearchChange(index, event)}>
                    <option value="and">AND</option>
                    <option value="or">OR</option>
                  </Form.Select>
                </div>
              )}
              <InputFormContainer idx={index === 0} mr={true}>
                <Form.Select name="fieldName" onChange={(event) => handleInputSearchChange(index, event)}>
                  {pathname === "/public-roles" && <option value="title">Job Title</option>}
                  {pathname === "/public-candidates" && <option value="idealJobTitles">Job Titles</option>}
                  <option value="workType">Work Type</option>
                  <option value="summary">Summary</option>
                  <option value="industry">Industry</option>
                  <option value="location">Location</option>
                  {searchType === "partner" && <option value="companyName">Company Name</option>}
                </Form.Select>
              </InputFormContainer>
              <InputFormContainer idx={index === 0} mr={true}>
                <Form.Select name="criteria" onChange={(event) => handleInputSearchChange(index, event)}>
                  <option>-- Select --</option>
                  {inputSearch[index].fieldName !== "workType" && <option value="contains">Contains</option>}
                  {inputSearch[index].fieldName !== "workType" && <option value="ncontains">Does not contain</option>}
                  <option value="eq">Matches</option>
                  <option value="neq">Does not match</option>
                </Form.Select>
              </InputFormContainer>
              <InputFormContainer idx={index === 0} mr={false}>
                {inputSearch[index].fieldName !== "workType" && (
                  <Form.Group>
                    <Form.Control type="text" name="searchValues" value={data?.searchValues} placeholder="Search..." onChange={(event) => handleInputSearchChange(index, event)}/>
                  </Form.Group>
                )}
                {inputSearch[index].fieldName === "workType" && (
                  <Form.Select name="searchValues" onChange={(event) => handleInputSearchChange(index, event)}>
                    <option>-- Select --</option>
                    <option value="UNKNOWN">Unknown</option>
                    <option value="TEMP">Temporary</option>
                    <option value="PERMANENT">Permanent</option>
                  </Form.Select>
                )}

              </InputFormContainer>
              {index > 0 && (
                <RemoveFormButton onClick={deleteInputField}>
                  <XSquare size={35} />
                </RemoveFormButton>
              )}
            </FormContainer>
          )
        })}

        {inputSearch.length < 5 && (
          <AddFormContainer>
            <PlusSquare size={30} color="primary" onClick={addNewInputField} style={{cursor: 'pointer'}}/>
          </AddFormContainer>
        )}

        <ButtonSearchContainer>
          <Button size="sm" className="m-1" variant="secondary" onClick={onHandleModal}>Cancel</Button>{' '}
          <Button size="sm" className="m-1" variant="primary" onClick={handleRunSearch}>Search</Button>
        </ButtonSearchContainer>
      </Container>
    </BackgroundMobileModal>
  )
}

export default AdvancedSearchModal;
