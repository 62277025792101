import * as Yup from "yup";
import config from "../../config";
import styled from "styled-components";
import { useFormik } from "formik";
import { Button, Card, Form } from "react-bootstrap";
import { ButtonRightCorner, ErrorWrapper, PageContainer } from "../../sharedStyles";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const newCompanySchema = Yup.object().shape({
  companyName: Yup.string().required("company name is required"),
  email: Yup.string().email().required(),
});

const CustomCard = styled(Card)`
  width: 36rem;
  min-height: 22rem;
`;

const NewCompany = () => {
  const formik = useFormik({
    initialValues: {
      companyName: '',
      email: '',
    },
    validationSchema: newCompanySchema,
    onSubmit: (values) => {
      // console.log(values); // please remove if unused again
    },
    enableReinitialize: true,
  });

  const { errors, touched } = formik;

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={config?.reCaptchaKey}
      language="en"
      useRecaptchaNet={true}
      useEnterprise={true}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
      container={{
        element: "new-company-recaptcha",
        parameters: {
          badge: 'inline',
        }
      }}
    >
      <PageContainer className="d-flex align-items-center justify-content-center vh-100">
        <CustomCard>
          <Card.Body>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-1">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  name="companyName"
                  type="text"
                  placeholder="Your company name"
                  onChange={formik.handleChange}
                  value={formik?.values?.companyName}
                />
                <ErrorWrapper>
                  {errors?.companyName && touched?.companyName ? errors?.companyName : ''}
                </ErrorWrapper>
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Your email"
                  onChange={formik.handleChange}
                  value={formik?.values?.email}
                />
                <ErrorWrapper>
                {errors?.email && touched?.email ? errors?.email : ''}
                </ErrorWrapper>
              </Form.Group>
              <div id="new-company-recaptcha"></div>
            </Form>
          </Card.Body>
          <Card.Footer>
            <ButtonRightCorner>
              <Button
                variant="primary"
                onClick={formik.submitForm}
              >
                Save
              </Button>
            </ButtonRightCorner>
          </Card.Footer>
        </CustomCard>
      </PageContainer>
    </GoogleReCaptchaProvider>
  )
}

export default NewCompany;
