import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Card, Table, Button } from "react-bootstrap";
import { GET_PROJECTS } from "../../../GraphQL/settings/queries";
import { ButtonRightCorner } from "../../../sharedStyles";
import { ArchiveFill, PencilFill, PersonLinesFill } from "react-bootstrap-icons";
import { EToastColorStatus, EToastPosition, IToastData, IProject } from "../../../interfaces";
import AddProjectModal from "./AddProjectModal";
import EditProjectModal from "./EditProjectModal";
import ArchiveProjectModal from "./ArchiveProjectModal";
import MemberProjectModal from "./MemberProjectModal";
import Notification from "../../Notification";
import InviteMemberModal from "./InviteMemberModal";

const Projects = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [projectId, setProjectId] = useState<number>(0);
  const [projectList, setProjectList] = useState([] as IProject[]);
  const [toastData, setToastData] = useState<IToastData>({
    show     : false,
    color    : EToastColorStatus.SUCCESS,
    position : EToastPosition.BOTTOM_END,
    message  : "",
  });

  const { loading, error, data, refetch } = useQuery(GET_PROJECTS, {
    variables: { skip: 0, take: 20 },
  });

  useEffect(() => {
    setProjectList(data?.projects?.items);
  }, [data]);

  const handleShowToast = (
    color    : EToastColorStatus,
    position : EToastPosition,
    message  : string,
  ) => {
    setToastData({ show: true, color, position, message });
  };

  const handleAddProjectModal = () => {
    setShowAddModal(true);
  }

  const handleEditProjectModal = (id: number) => {
    setProjectId(id);
    setShowEditModal(true);
  }

  const handleOpenMemberUsers = (id: number) => {
    setProjectId(id);
    setShowMemberModal(true);
  }

  const handleArchiveProjectModal = (id: number) => {
    setProjectId(id);
    setShowArchiveModal(true);
  }

  return (
    <Card className="border-0">
      <Card.Body className="p-0">
        <Table bordered hover>
          <thead>
            <tr>
              <th className="w-50">Name</th>
              <th className="w-50 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading && <tr><td>Loading</td></tr>}
            {error && <tr><td>Error: {error?.message}</td></tr>}
            {!loading && !error && projectList && projectList.map((item) => (
              <tr key={item?.id}>
                <td>{item?.title}</td>
                <td align="center">
                  <Button
                    size="sm"
                    variant="secondary"
                    className="mx-1"
                    onClick={() => handleEditProjectModal(item?.id)}
                  >
                    <PencilFill />
                  </Button>
                  <Button
                    size="sm"
                    variant="danger"
                    className="mx-1"
                    onClick={() => handleArchiveProjectModal(item?.id)}
                  >
                    <ArchiveFill />
                  </Button>
                  <Button
                    size="sm"
                    variant="secondary"
                    className="mx-1"
                    onClick={() => handleOpenMemberUsers(item?.id)}
                  >
                    <PersonLinesFill />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ButtonRightCorner>
          <Button
            size="sm"
            onClick={handleAddProjectModal}
          >
            Add Project
          </Button>
        </ButtonRightCorner>
      </Card.Body>

      <AddProjectModal
        showAddModal={showAddModal}
        onCloseAddModal={() => setShowAddModal(false)}
        reloadProjects={refetch}
        onHandleCreateToast={(
          color    : EToastColorStatus,
          position : EToastPosition,
          message  : string,
        ) => handleShowToast(color, position, message)}
      />

      <EditProjectModal
        showEditModal={showEditModal}
        onCloseModal={() => setShowEditModal(false)}
        projectId={projectId}
        projectList={projectList}
        reloadProjects={refetch}
        onHandleCreateToast={(
          color    : EToastColorStatus,
          position : EToastPosition,
          message  : string,
        ) => handleShowToast(color, position, message)}
      />

      <ArchiveProjectModal
        showArchiveModal={showArchiveModal}
        onCloseModal={() => setShowArchiveModal(false)}
        projectId={projectId}
        reloadProjects={refetch}
        onHandleCreateToast={(
          color    : EToastColorStatus,
          position : EToastPosition,
          message  : string,
        ) => handleShowToast(color, position, message)}
      />

      <MemberProjectModal
        showMemberModal={showMemberModal}
        onHandleInviteMember={() => setShowInviteModal(true)}
        onCloseModal={() => setShowMemberModal(false)}
      />

      <InviteMemberModal
        showInviteModal={showInviteModal}
        onCloseModalInvite={() => setShowInviteModal(false)}
        projectId={projectId}
        onHandleCreateToast={(
          color    : EToastColorStatus,
          position : EToastPosition,
          message  : string,
        ) => handleShowToast(color, position, message)}
      />

      <Notification
        delay={3000}
        toastData={toastData}
        onClose={() => setToastData({ ...toastData, show: false })}
      />
    </Card>
  )
}

export default Projects;
