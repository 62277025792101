import { useState } from "react";
import { Card, Table, Button } from "react-bootstrap";
import { ButtonRightCorner } from "../../sharedStyles";
import Toggle from "react-toggle";

interface IPropsCheckedValues {
  roleEmail: boolean;
  roleMobile: boolean;
  candidateEmail: boolean;
  candidateMobile: boolean;
  platformEmail: boolean;
  platformMobile: boolean;
}

const Communications = () => {
  const [checkedValues, setCheckedValues] = useState<IPropsCheckedValues>({
    roleEmail: false,
    roleMobile: false,
    candidateEmail: false,
    candidateMobile: false,
    platformEmail: false,
    platformMobile: false,
  });

  const handleChecked = (isChecked: boolean, checkedTitle: string) => {
    setCheckedValues({...checkedValues, [checkedTitle]: isChecked});
  }

  const handleSaveCommunicationsSetting = () => {
    // trigger save data here
    // console.log(checkedValues);
  }

  return (
    <Card className="border-0">
      <Card.Body className="p-0">
        <p>Please indicate your communication preferences below</p>
        <Table bordered>
          <thead>
            <tr>
              <th className="w-50"></th>
              <th className="w-25 text-center">Email</th>
              <th className="w-25 text-center">Mobile</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Role Notifications</td>
              <td className="text-center">
                {<Toggle
                  icons={false}
                  checked={checkedValues?.roleEmail}
                  onChange={(e) => handleChecked(e.target.checked, "roleEmail")}
                />}
              </td>
              <td className="text-center">
                {<Toggle
                  icons={false}
                  checked={checkedValues?.roleMobile}
                  onChange={(e) => handleChecked(e.target.checked, "roleMobile")}
                />}
              </td>
            </tr>
            <tr>
              <td>Candidate Notifications</td>
              <td className="text-center">
                {<Toggle
                  icons={false}
                  checked={checkedValues?.candidateEmail}
                  onChange={(e) => handleChecked(e.target.checked, "candidateEmail")}
                />}
              </td>
              <td className="text-center">
                {<Toggle
                  icons={false}
                  checked={checkedValues?.candidateMobile}
                  onChange={(e) => handleChecked(e.target.checked, "candidateMobile")}
                />}
              </td>
            </tr>
            <tr>
              <td>Platform Announcement</td>
              <td className="text-center">
                {<Toggle
                  icons={false}
                  checked={checkedValues?.platformEmail}
                  onChange={(e) => handleChecked(e.target.checked, "platformEmail")}
                />}
              </td>
              <td className="text-center">
                {<Toggle
                  icons={false}
                  checked={checkedValues?.platformMobile}
                  onChange={(e) => handleChecked(e.target.checked, "platformMobile")}
                />}
              </td>
            </tr>
          </tbody>
        </Table>
        <ButtonRightCorner>
          <Button size="sm" variant="primary" onClick={handleSaveCommunicationsSetting}>
            Save
          </Button>
        </ButtonRightCorner>
      </Card.Body>
    </Card>
  )
}

export default Communications;
