import { gql } from "@apollo/client";

export const GET_PROFILE = gql`
  query GetProfile {
    profile {
      email
      firstName
      lastName
      fullName
      phoneNumber
      role
      userId
      companyId
      industryPreferences {
        regions
        industries
        localityIds
      }
      company {
        id
        settings {
          features {
            projects
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_USERS = gql`
  query CompanyUsers ($skip: Int, $take: Int) {
    companyUsers (skip: $skip, take: $take) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        email
        status
      }
    }
  }
`;

export const GET_CONNECTIONS = gql`
  query GetConnections {
    connections {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        type
        status
        createdAt
      }
    }
  }
`;

export const GET_LOCALITIES = gql`
  query GetLocalities {
    localities {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        state
        name
      }
    }
  }
`;

export const GET_PARTNERS = gql`
  query GetPartners {
    partners {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        companyAId
        companyBId
        companyAName
        companyBName
        status
      }
    }
  }
`;

export const START_PAYMENT_SESSION = gql`
  query StartPaymentSession($quantity: Int!) {
    startPaymentSession(quantity: $quantity) {
      url
    }
  }
`;

export const GET_SUBSCRIPTION = gql`
  query subscription {
    subscription {
      quantity
      status
      startDate
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query Industries {
    industries
  }
`;

export const GET_PROJECTS = gql`
  query Projects ($skip: Int, $take: Int) {
    projects (skip: $skip, take: $take){
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        title
        description
        createdAt
        updatedAt
      }
    }
  }
`;