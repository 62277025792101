import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const JoinWrapper = styled.div`
  margin: 1rem;
`;

const Join = ({ user }: any) => {
  const navigate = useNavigate();

  if (user) navigate("/public-roles");

  return (
    <JoinWrapper>
      <h3>Join Page</h3>
      <p>a new UI to enable invited users to onboard into the platform.</p>
    </JoinWrapper>
  )
}

export default Join;
