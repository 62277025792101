const relayTheme = {
  name: "relayTheme",
  tokens: {
    colors: {
      brand: {
        primary: {
          10: { value: "#EFEFEF" },
          20: { value: "#227B7E" },
          40: { value: "#227B7E" },
          60: { value: "#227B7E" },
          80: { value: "#227B7E" },
          90: { value: "#0A414E" },
          100: { value: "#083B4A" },
        },
      },
    },
  },
};

export default relayTheme;