import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import PhoneInput from "./PhoneInput";
import { ButtonRightCorner } from "../../sharedStyles";
import { UPDATE_PROFILE } from "../../GraphQL/settings/mutations";
import { IProfile } from "../../interfaces";

interface FormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const UserSettings = ({ profile }: IProfile) => {
  const [updateProfile, updateResponse] = useMutation(UPDATE_PROFILE);

  useEffect(() => {
    if (updateResponse.called && updateResponse.error) {
      alert("Something went wrong, please try again later");
      return;
    }
    if (updateResponse.called && !updateResponse.loading) {
      alert("User details updated successfully");
    }
  }, [updateResponse]);

  const getFormData = () => {
    return {
      firstName: profile.firstName || "",
      lastName: profile.lastName || "",
      phoneNumber: profile.phoneNumber || "",
    };
  };

  const doSubmit = (formData: FormData) => {
    updateProfile({
      variables: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
      },
    });
  };

  return (
    <Formik initialValues={getFormData()} onSubmit={doSubmit}>
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Card className="border-0">
              <Card.Body className="p-0">
                <Row>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-3" controlId="formFirstName">
                      <Form.Label>First name*</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        placeholder="Enter first name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-3" controlId="formLastName">
                      <Form.Label>Last name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        placeholder="Enter last name"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formMobile">
                      <Form.Label>Mobile phone*</Form.Label>
                      <Field
                        component={PhoneInput}
                        field={{
                          name: "phoneNumber",
                          value: values.phoneNumber,
                        }}
                        onChange={handleChange}
                        form={{
                          errors: errors,
                          handleBlur: handleBlur,
                          setFieldValue: setFieldValue,
                          touched: touched,
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col></Col>
                </Row>
                <ButtonRightCorner>
                  <Button size="sm" type="submit">
                    Save Details
                  </Button>
                </ButtonRightCorner>
              </Card.Body>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserSettings;
