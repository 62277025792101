import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Form, Card, Button, Col, Row } from "react-bootstrap";
import config from "../../config";
import { Auth } from "aws-amplify";
import { ButtonRightCorner } from "../../sharedStyles";
import { GET_CONNECTIONS } from "../../GraphQL/settings/queries";
import { DISCONNECT_ATS } from "../../GraphQL/settings/mutations";
import terms from "../../assets/role_relay_terms.pdf";

const connectToJobAdder = async () => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const headers = new Headers();
  headers.set("Authorization", `Bearer ${token}`);
  const response = await fetch(config.jobAdder.integrationUrl, { headers });
  const url = (await response.json()).url;
  window.location = url;
};

// const connectToVincere = async () => {
//   const session = await Auth.currentSession();
//   const token = session.getIdToken().getJwtToken();
//   const headers = new Headers();
//   headers.set("Authorization", `Bearer ${token}`);
//   const response = await fetch(config.vincere.integrationUrl, { headers });
//   const url = (await response.json()).url;
//   window.location = url;
// };

// const syncVincereJobs = async () => {
//   const session = await Auth.currentSession();
//   const token = session.getIdToken().getJwtToken();
//   const headers = new Headers();
//   headers.set("Authorization", `Bearer ${token}`);
//   await fetch(config.vincere.syncJobs, { headers, method: "POST" });
// };

// interface Connection {
//   id: string;
//   type: string;
//   createdAt: any;
// }

// interface ConnectionData {
//   connections: Connection[];
// }

const Connections = () => {
  const [disconnected, setDisconnected] = useState(false);
  const [disconnectAts, disconnectAtsResponse] = useMutation(DISCONNECT_ATS);
  const { loading, error, data, refetch } = useQuery(GET_CONNECTIONS);

  const onDisconnect = () => {
    disconnectAts({
      variables: {
        connectionType: "JobAdder",
      },
    });
  };

  useEffect(() => {
    if (!disconnected && disconnectAtsResponse.called && !disconnectAtsResponse.loading && disconnectAtsResponse?.data?.disconnect) {
      setDisconnected(true);
      alert("Disconnection successful");
      refetch();
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disconnectAtsResponse, refetch]);

  // TODO Add an error message
  if (loading || error) return null;

  const {connections} = data;

  if (connections?.items?.length > 0) {
    return (
      <Card className="border-0">
        <Card.Body className="p-0">
          You organisation is currently connected to JobAdder
          <ButtonRightCorner>
            <Button size="sm" variant="danger" onClick={onDisconnect}>
              Disconnect
            </Button>
          </ButtonRightCorner>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="border-0">
      <Card.Body className="p-0">
        Please select your Applicant Tracking System (ATS) to connect.<br/>
        <strong>By connecting your ATS you also confirm you have read and agree with our <a href={terms} target="_blank" rel="noreferrer">terms and conditions</a>.</strong>
        <br /><br/>
        <Row>
          <Col lg="3">
            {" "}
            <Form.Group className="mb-3" controlId="formMobile">
              <Form.Control
                as="select"
                name="Location"
                placeholder="Select location"
                value="JobAdder"
                onChange={() => {}}
              >
                <option value="JobAdder">JobAdder</option>
                <option disabled value="Vincere">
                  Vincere
                </option>
                <option disabled value="Vincere">
                  Bullhorn
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <ButtonRightCorner>
          <Button size="sm" variant="primary" onClick={connectToJobAdder}>
            Connect
          </Button>
        </ButtonRightCorner>
      </Card.Body>
    </Card>

    // <Card>
    //   <CardHeader>Connections</CardHeader>
    //   <Card.Body>
    //     You organisation is currently connected to JobAdder
    //     <button onClick={callApi}>Connect</button>
    //     <br />
    //     <br />
    //     <button onClick={connectToVincere}>Connect to Vincere</button>
    //     <br />
    //     <button onClick={syncVincereJobs}>Sync jobs</button>
    //   </Card.Body>
    //   <CardFooter>
    //     <div></div>
    //     <Button size="sm" variant="danger">
    //       Disconnect
    //     </Button>
    //   </CardFooter>
    // </Card>
  );
};

export default Connections;
