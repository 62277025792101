

const advancedFilterQuery = (inputSearch: any) => {
  let data = {};

  if (inputSearch.length === 1) {
    const value = {[inputSearch[0].criteria]: inputSearch[0].searchValues};
    const isLocation = inputSearch[0].fieldName === "location";
    data = {...(isLocation) && {"location": { "displayName": value }}, ...(!isLocation) && {[inputSearch[0].fieldName]: value }};
  }

  if (inputSearch.length === 2){
    const FD1 = inputSearch[0].fieldName;
    const FD2 = inputSearch[1].fieldName;
    const VAL1 = {[inputSearch[0].criteria] : inputSearch[0].searchValues};
    const VAL2 = {[inputSearch[1].criteria] : inputSearch[1].searchValues};
    data = {
      [inputSearch[1].relation]: [
        FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
        FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
      ]
    }
  }

  if (inputSearch.length === 3){
    const RE1 = inputSearch[1].relation;
    const RE2 = inputSearch[2].relation;
    const FD1 = inputSearch[0].fieldName;
    const FD2 = inputSearch[1].fieldName;
    const FD3 = inputSearch[2].fieldName;
    const VAL1 = {[inputSearch[0].criteria]: inputSearch[0].searchValues};
    const VAL2 = {[inputSearch[1].criteria]: inputSearch[1].searchValues};
    const VAL3 = {[inputSearch[2].criteria]: inputSearch[2].searchValues};

    if (RE1 === RE2){
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
        ],
      }
    } else {
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
        ],
        [RE2]: [
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
        ],
      }
    }
  }

  if (inputSearch.length === 4){
    const RE1 = inputSearch[1].relation;
    const RE2 = inputSearch[2].relation;
    const RE3 = inputSearch[3].relation;
    const FD1 = inputSearch[0].fieldName;
    const FD2 = inputSearch[1].fieldName;
    const FD3 = inputSearch[2].fieldName;
    const FD4 = inputSearch[3].fieldName;
    const VAL1 = {[inputSearch[0].criteria]: inputSearch[0].searchValues};
    const VAL2 = {[inputSearch[1].criteria]: inputSearch[1].searchValues};
    const VAL3 = {[inputSearch[2].criteria]: inputSearch[2].searchValues};
    const VAL4 = {[inputSearch[3].criteria]: inputSearch[3].searchValues};

    if ((new Set([RE1, RE2, RE3])).size === 1){
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
          FD4 !== "location" ? {[FD4]: VAL4} : {"location": {"displayName": VAL4}},
        ]
      }
    } else if(RE2 === RE3) {
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
        ],
        [RE3]: [
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
          FD4 !== "location" ? {[FD4]: VAL4} : {"location": {"displayName": VAL4}},
        ],
      }
    } else if (RE1 === RE3){
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
          FD4 !== "location" ? {[FD4]: VAL4} : {"location": {"displayName": VAL4}},
        ],
        [RE2]: [
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
        ],
      }
    } else if (RE1 === RE2){
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
          FD3 !== "location" ? {[FD3]: VAL2} : {"location": {"displayName": VAL3}},
        ],
        [RE3]: [
          FD4 !== "location" ? {[FD4]: VAL4} : {"location": {"displayName": VAL4}},
        ],
      }
    }
  }

  if (inputSearch.length === 5){
    const RE1 = inputSearch[1].relation;
    const RE2 = inputSearch[2].relation;
    const RE3 = inputSearch[3].relation;
    const RE4 = inputSearch[4].relation;
    const FD1 = inputSearch[0].fieldName;
    const FD2 = inputSearch[1].fieldName;
    const FD3 = inputSearch[2].fieldName;
    const FD4 = inputSearch[3].fieldName;
    const FD5 = inputSearch[4].fieldName;
    const VAL1 = {[inputSearch[0].criteria]: inputSearch[0].searchValues};
    const VAL2 = {[inputSearch[1].criteria]: inputSearch[1].searchValues};
    const VAL3 = {[inputSearch[2].criteria]: inputSearch[2].searchValues};
    const VAL4 = {[inputSearch[3].criteria]: inputSearch[3].searchValues};
    const VAL5 = {[inputSearch[4].criteria]: inputSearch[4].searchValues};

    if (new Set([RE1, RE2, RE3, RE4]).size === 1){
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
          FD4 !== "location" ? {[FD4]: VAL4} : {"location": {"displayName": VAL4}},
          FD5 !== "location" ? {[FD5]: VAL5} : {"location": {"displayName": VAL5}},
        ],
      }
    } else if (new Set([RE1, RE2, RE3]).size === 1){
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
          FD4 !== "location" ? {[FD4]: VAL4} : {"location": {"displayName": VAL4}},
        ],
        [RE4]: [
          FD5 !== "location" ? {[FD5]: VAL5} : {"location": {"displayName": VAL5}},
        ],
      }
    } else if (new Set([RE2, RE3, RE4]).size === 1){
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
        ],
        [RE2]: [
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
          FD4 !== "location" ? {[FD4]: VAL4} : {"location": {"displayName": VAL4}},
          FD5 !== "location" ? {[FD5]: VAL5} : {"location": {"displayName": VAL5}},
        ],
      }
    } else if (new Set([RE1, RE2, RE4]).size === 1){
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
          FD5 !== "location" ? {[FD5]: VAL5} : {"location": {"displayName": VAL5}},
        ],
        [RE3]: [
          FD4 !== "location" ? {[FD4]: VAL4} : {"location": {"displayName": VAL4}},
        ],
      }
    } else if (new Set([RE1, RE3, RE4]).size === 1){
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
          FD4 !== "location" ? {[FD4]: VAL4} : {"location": {"displayName": VAL4}},
          FD5 !== "location" ? {[FD5]: VAL5} : {"location": {"displayName": VAL5}},
        ],
        [RE2]: [
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
        ],
      }
    } else if (new Set([RE1, RE2]).size === 1 && new Set([RE3, RE4]).size === 1){
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
        ],
        [RE3]: [
          FD4 !== "location" ? {[FD4]: VAL4} : {"location": {"displayName": VAL4}},
          FD5 !== "location" ? {[FD5]: VAL5} : {"location": {"displayName": VAL5}},
        ],
      }
    } else if (new Set([RE1, RE3]).size === 1 && new Set([RE2, RE4]).size === 1){
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
          FD4 !== "location" ? {[FD4]: VAL4} : {"location": {"displayName": VAL4}},
        ],
        [RE4]: [
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
          FD5 !== "location" ? {[FD5]: VAL5} : {"location": {"displayName": VAL5}},
        ],
      }
    } else if (new Set([RE1, RE4]).size === 1 && new Set([RE2, RE3]).size === 1){
      data = {
        [RE1]: [
          FD1 !== "location" ? {[FD1]: VAL1} : {"location": {"displayName": VAL1}},
          FD2 !== "location" ? {[FD2]: VAL2} : {"location": {"displayName": VAL2}},
          FD5 !== "location" ? {[FD5]: VAL5} : {"location": {"displayName": VAL5}},
        ],
        [RE2]: [
          FD3 !== "location" ? {[FD3]: VAL3} : {"location": {"displayName": VAL3}},
          FD4 !== "location" ? {[FD4]: VAL4} : {"location": {"displayName": VAL4}},
        ],
      }
    }
  }

  return data;
}

export default advancedFilterQuery;
