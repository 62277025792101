import { gql } from "@apollo/client";

export const AMEND_ROLE_AGENT_MATCH = gql`
  mutation amendJobAgentMatch(
    $email: String
    $expectedFee: String!
    $feedback: String
    $id: String!
    $name: String
    $phone: String
    $status: MatchAgentStatus!
    $expectedStartDate: Date
    $expectedSchedule: String
    $settlementFee: String
    $settlementSchedule: String
  ) {
    amendJobAgentMatch(
      input: {
        email: $email
        expectedFee: $expectedFee
        feedback: $feedback
        id: $id
        name: $name
        phone: $phone
        status: $status
        expectedStartDate: $expectedStartDate
        expectedSchedule: $expectedSchedule
        settlementFee: $settlementFee
        settlementSchedule: $settlementSchedule
      }
    ) {
      id
    }
  }
`;

export const AMEND_CANDIDATE_AGENT_MATCH = gql`
  mutation AmendCandidateAgentMatch(
    $id: String!
    $expectedFee: String!
    $feedback: String
    $name: String
    $phone: String
    $status: MatchAgentStatus!
    $email: String
    $expectedStartDate: Date
    $expectedSchedule: String
    $settlementFee: String
    $settlementSchedule: String
  ) {
    amendCandidateAgentMatch(
      input: {
        id: $id
        expectedFee: $expectedFee
        name: $name
        email: $email
        phone: $phone
        status: $status
        feedback: $feedback
        expectedStartDate: $expectedStartDate
        expectedSchedule: $expectedSchedule
        settlementFee: $settlementFee
        settlementSchedule: $settlementSchedule
      }
    ) {
      id
    }
  }
`;

export const ADD_CANDIDATE_MATCH = gql`
  mutation addCandidateMatch($candidateId: Int!, $jobIds: [Int!]!) {
    addCandidateMatch(input: { candidateId: $candidateId, jobIds: $jobIds }) {
      ids
      candidateId
      jobIds
    }
  }
`;

export const ADD_JOB_MATCH = gql`
  mutation addJobMatch($jobId: Int!, $candidateIds: [Int!]!) {
    addJobMatch(input: { jobId: $jobId, candidateIds: $candidateIds }) {
      ids
      jobId
      candidateIds
    }
  }
`;
