import React, {useState} from "react";
import { Navbar, Nav, Modal, Button } from "react-bootstrap";
import {
  FileTextFill,
  FilePersonFill,
  GearFill,
  BoxArrowRight,
  EnvelopeFill,
  FileLock2Fill,
} from "react-bootstrap-icons";
import {
  StyledNavbar,
  Logo,
  StyledNavLink,
  StyledToggle,
  Hamburger,
} from "./styles";
import config from "../../config";
import logo from "../../assets/logo.svg";
import terms from "../../assets/role_relay_terms.pdf";
import { useFeatureFlagEnabled } from "posthog-js/react";

interface HeaderProps {
  signOut: () => void;
  user: any;
}

const Header = ({ signOut, user }: HeaderProps): JSX.Element => {
  const hideWelcome = localStorage.getItem("hideWelcome") === "true" || window.location.pathname === "/privacy";
  const showMatchesFlag = useFeatureFlagEnabled(config?.postHog?.featureFlags?.showMatchesPage);

  const [showFirstTimeModal, setShowFirstTimeModal] = useState(!hideWelcome);

  const closeFirstTime = () => {
    localStorage.setItem("hideWelcome", "true");
    setShowFirstTimeModal(false);
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        fullscreen={"true"}
        show={showFirstTimeModal}
        onHide={closeFirstTime}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Welcome to Role Relay!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            By proceeding you accept our{" "}
            <a href={terms} target="_blank" rel="noreferrer">
              terms and conditions
            </a>{" "}
            and{" "}
            <a href="/privacy" target="_blank">
              privacy policy
            </a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeFirstTime} variant="primary">
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
      <StyledNavbar variant="dark" expand="lg">
        <Navbar.Brand href="/">
          <Logo alt="Role Relay" src={logo} height="30" />{" "}
        </Navbar.Brand>
        <StyledToggle
          children={
            <Hamburger>
              <div></div>
              <div></div>
              <div></div>
            </Hamburger>
          }
          aria-controls="responsive-navbar-nav"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav style={{ marginRight: "auto" }} activeKey={window?.location?.pathname}>
            <StyledNavLink href="/my-roles" firstnav="true">
              <FileTextFill size={20} /> My Roles{" "}
            </StyledNavLink>
            <StyledNavLink href="/my-candidates">
              <FilePersonFill size={20} /> My Candidates{" "}
            </StyledNavLink>
            <StyledNavLink href="/messages/roles">
              <EnvelopeFill size={20} /> Messages{" "}
            </StyledNavLink>
            {showMatchesFlag && (
              <StyledNavLink href="/matches">
                <FileLock2Fill size={20} /> Matches{" "}
              </StyledNavLink>
            )}
            <StyledNavLink href="/public-roles">
              <FileTextFill size={20} /> Open Roles{" "}
            </StyledNavLink>
            <StyledNavLink href="/public-candidates">
              <FilePersonFill size={20} /> Open Candidates{" "}
            </StyledNavLink>
          </Nav>
          <Nav activeKey={window?.location?.pathname}>
            <StyledNavLink href="/settings">
              <GearFill size={20} /> Settings
            </StyledNavLink>
            <StyledNavLink onClick={signOut}>
              <BoxArrowRight size={20} /> Logout
            </StyledNavLink>
          </Nav>
        </Navbar.Collapse>
      </StyledNavbar>
    </>
  );
};

export default Header;
