import React from "react";
import styled from "styled-components";
import { Card as BSCard, Modal, Form, Button } from "react-bootstrap";
import { SendFill, EnvelopeFill } from "react-bootstrap-icons";

const Container = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ccc;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Card = styled(BSCard)`
  width: 320px;
  border-bottom: 5px solid rgb(0, 176, 0);
  cursor: pointer;
`;

const DemoProjects = () => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Container>
      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Company:</strong> Power Recruitment
          <br />
          <strong>Industry:</strong> Mining &amp; Technical
          <br />
          <strong>Description:</strong>
          <br />
          <p>
            Power Recruitment is leading a pioneering initiative to collaborate
            with various recruitment agencies in addressing the escalating
            talent needs of the Mining and Technical industry. This strategic
            partnership endeavor is designed to harness the unique strengths of
            each agency, creating a synergistic network aimed at filling diverse
            roles within the sector. Emphasizing diversity and inclusion, the
            project seeks to leverage the collective expertise of partner
            agencies to actively engage with underrepresented talent pools and
            promote a more inclusive workforce.
          </p>
          <strong>Roles:</strong> Mining Supervisor, Project Managers, Process
          Engineers, Electrical Engineers
          <br />
          <strong>Start date:</strong> 1 March 2024
          <br />
          <strong>End date:</strong> 30 April 2024
          <br />
          <strong>Support agency expectations:</strong>
          <br />
          Sourcing candidated, screening candidates, payrolling candidates,
          managing timesheets
          <hr />
          <Form.Group controlId="formFamilyName">
            <Form.Label>Name*</Form.Label> <Form.Control type="text" required />
          </Form.Group>
          <Form.Group controlId="formFamilyName">
            <Form.Label>Company*</Form.Label>{" "}
            <Form.Control type="text" required />
          </Form.Group>
          <Form.Group controlId="formFamilyName">
            <Form.Label>Email*</Form.Label>{" "}
            <Form.Control type="text" required />
          </Form.Group>
          <Form.Group controlId="formFamilyName">
            <Form.Label>Phone*</Form.Label>{" "}
            <Form.Control type="text" required />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">
            <EnvelopeFill /> Agent Query
          </Button>
          <Button variant="primary">
            <SendFill /> Express Interest
          </Button>
        </Modal.Footer>
      </Modal>
      <h5>Projects</h5>
      <CardContainer>
        <Card onClick={() => setShowModal(true)}>
          <Card.Body>
            <Card.Text>
              <div>
                <strong>Company:</strong> Power Recruitment
              </div>
              <div>
                <strong>Industry:</strong> Mining &amp; Technical
              </div>
              <div>
                <strong>Description:</strong>
              </div>
              <div>
                Power Recruitment is an MSP seeking to partner with agencies
                specialising in engaging diverse talent ...
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card onClick={() => setShowModal(true)}>
          <Card.Body>
            <Card.Text>
              <div>
                <strong>Company:</strong> Lloyd Recruitment
              </div>
              <div>
                <strong>Industry:</strong> Education
              </div>
              <div>
                <strong>Description:</strong>
              </div>
              <div>
                Lloyd Recruitment is looking for agencies to support recruitment
                of ELT specialists into ...
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </CardContainer>
    </Container>
  );
};

export default DemoProjects;
