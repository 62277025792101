import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Form, Row, Col, Popover, OverlayTrigger } from "react-bootstrap";
import { LockFill, QuestionCircleFill } from "react-bootstrap-icons";
import Toggle from "react-toggle";
import { PageContainer } from "../../../sharedStyles";

export const LabelNote = styled.span`
  color: #888;
  font-weight: normal;
  font-size: 16px;
`;

const SalaryInputs = styled.div`
  display: flex;
  justify-content: space-between;
  & input {
    width: 100%;
  }
  & select {
    width: 90px;
  }

  gap: 10px;
`;

const CheckWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const ToggleContainer = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: left;

  & div {
    margin: 0 4px;
  }
`;

const Hyphen = styled.div`
  padding-top: 5px;
`;

const renderCandidate = () => {
  return (
    <>
      <Form>
        <Row>
          <Col>
            <br />
            <p>
              <strong>Note:</strong> These details will be visible to other
              agencies on the platform when sharing is activated. Please ensure
              you check all fields (particularly the Description/Profile) for
              any identifiable information.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            {" "}
            <Form.Group controlId="formFamilyName">
              <Form.Label>Family name*</Form.Label>{" "}
              <LabelNote>
                <LockFill />
              </LabelNote>{" "}
              <Form.Control
                type="text"
                name="lastName"
                required
              />
            </Form.Group>
          </Col>
          <Col>
            {" "}
            <Form.Group controlId="firstName">
              <Form.Label>First name*</Form.Label>
              <LabelNote>
                <LockFill />
              </LabelNote>{" "}
              <Form.Control
                type="text"
                name="firstName"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <br />

        <Row>
          <Col>
            <Row>
              <Col>
                <Form.Group controlId="formLocation">
                  <Form.Label>Location*</Form.Label>
                  <Form.Control
                    as="select"
                    name="localityId"
                    placeholder="Select location"
                  >
                    <option></option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formType">
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    as="select"
                    name="workType"
                    placeholder="Select type"
                  >
                    <option value=""></option>
                    <option value="PERMANENT">PERMANENT</option>
                    <option value="TEMP">CONTRACT/TEMP</option>
                    <option value="ANY">ANY</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col>
            <Form.Group controlId="formRoleSalary">
              <Form.Label>Salary range (min. $40,000)*</Form.Label>
              <SalaryInputs>
                <div>
                  <Form.Control
                    type="number"
                    name="salaryMin"
                    placeholder="Min."
                    required
                  />{" "}
                </div>
                <Hyphen> - </Hyphen>
                <div>
                  <Form.Control
                    type="number"
                    name="salaryMax"
                    placeholder="Max."
                    required
                  />{" "}
                </div>
                <div>
                  <Form.Control
                    as="select"
                    name="currency"
                  >
                    <option value="AUD">AUD</option>
                    <option value="NZD">NZD</option>
                  </Form.Control>
                </div>
              </SalaryInputs>
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="formRole">
              <Form.Label>Description/Profile*</Form.Label><br/>
              <textarea
                name="description"
                required />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="formIndustry">
              <Form.Label>Industry*</Form.Label>
              <Form.Control
                as="select"
                name="industry"
                placeholder="Select industry"
              >
                <option></option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formRoleExperience">
              <Form.Label>Years experience*</Form.Label>
              <Form.Control
                type="number"
                name="yearsOfExperience"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="formRoleIdealJobTitle">
              <Form.Label>Ideal job titles*</Form.Label>
              <Form.Control
                type="text"
                name="idealJobTitles"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <CheckWrap>
              <Form.Check
                type="checkbox"
                label="I have received permission from the candidate to be represented on Role Relay in the last 3 months"
                name="interviewed"
              />{" "}
              &nbsp;{" "}
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Popover>
                    <Popover.Body>
                      <p>Role Relay's terms dictate that:</p>
                      <ul>
                        <li>
                          Only candidates that you have engaged with in the last
                          3 months can be represented on the platform.
                        </li>
                      </ul>
                      <ul>
                        <li>
                          You must have written permission from the candidate to
                          represent them on the platform.
                        </li>
                      </ul>
                    </Popover.Body>
                  </Popover>
                }
              >
                <QuestionCircleFill />
              </OverlayTrigger>
            </CheckWrap>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Form.Label>Sharing</Form.Label>{" "}
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Popover>
                  <Popover.Body>
                    <strong>Shared for Collaboration</strong> will push this
                    candidate to the Role Relay Network based on your{" "}
                    <strong>Audience</strong> selection. Candidates you do not
                    wish to share with the network can be set to{" "}
                    <strong>Draft</strong>.
                  </Popover.Body>
                </Popover>
              }
            >
              <QuestionCircleFill />
            </OverlayTrigger>
            <ToggleContainer>
              <span>Draft</span>
              <Toggle
                name="shared"
                icons={false}
              />{" "}
              <span>Shared for collaboration</span>
            </ToggleContainer>
          </Col>
          <Col>
            <Form.Group controlId="formAudience">
              <Form.Label>Audience</Form.Label>{" "}
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Popover>
                    <Popover.Body>
                      <p>
                        Select which audience you would like to share the
                        Candidate with.
                      </p>
                      <ul>
                        <li>
                          <strong>Private</strong> - only flagged for Role Relay
                          automatic matching. You will receive notifications of
                          opportunities Role Relay detects for this candidate,
                          but their anonymous details will not be searchable for
                          other agencies on the Public or Partner Candidate
                          Boards.
                        </li>
                        <li>
                          <strong>Partners Only</strong> - flagged for Role
                          Relay automatic matching, and listed on the Partner
                          Candidate Board. The candidate's anonymous details
                          will not be searchable on the Public Candidate Board.
                        </li>
                        <li>
                          <strong>Public</strong> - flagged for matching, and
                          listed on the Partner and Public Candidate Board.
                        </li>
                      </ul>
                    </Popover.Body>
                  </Popover>
                }
              >
                <QuestionCircleFill />
              </OverlayTrigger>
              <Form.Control
                as="select"
                name="audience"
                placeholder="Select type"
              >
                <option value=""></option>
                <option value="PRIVATE">Private</option>
                <option value="PARTNER">Partners only</option>
                <option value="PUBLIC">Public (incl. Partners)</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col>
         
          </Col>
        </Row>
      </Form>
      <hr />
      <p>
        <strong>What happens next?</strong>
      </p>
      <p>
        Your Candidate will be shared with the Role Relay network based on the
        Audience you have selected. Only the anonymous details you have provided
        will be shared with other agencies.
      </p>
    </>
  );
};

const BullhornAction = () => {
  const { action } = useParams();

  return (
    <PageContainer  className="">
      {action === "candidate" && renderCandidate()}
    </PageContainer>
  );
};

export default BullhornAction;
