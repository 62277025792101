import { Job } from "../../../interfaces";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { PencilSquare, TrashFill } from "react-bootstrap-icons";
import styled from "styled-components";
import SelectRolesMobile from "./SelectRolesMobile";

const List = styled.p`
  margin-bottom: 0.15rem;
`;

const ListBold = styled(List)`
  font-weight: bold;
`;

const ContainerRolesMobile = styled.div`
  margin-bottom: 1rem;
  margin-top: -1rem;
`;

const CardRoleMobile = styled.div`
  border-top: 1px solid #ccc;
  padding: 1rem 1rem 0 1rem;
  margin: 0 -1rem;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: .75rem;
  Button {
    margin-left: .5rem;
  }
`;

interface IRolesProps {
  onShowRoleModal: () => void,
  onSelectRole: (data: any) => void,
  onSortingRolesData: (data: any) => void,
  rolesData?: Job[] | undefined,
  handleShowModalDelete: (data: number) => void,
  locationName: (x: string) => string[],
}

const RolesMobileView = ({
  onShowRoleModal = () => {},
  onSelectRole,
  onSortingRolesData,
  rolesData,
  handleShowModalDelete,
  locationName,
}: IRolesProps) => {
  const navigate = useNavigate();

  const handleShowData = (dataRole: Job) => {
    onShowRoleModal();
    onSelectRole(dataRole);
    navigate(`/my-roles/${dataRole?.id}`);
  }

  return (
    <ContainerRolesMobile>
      <SelectRolesMobile onSortingRolesData={onSortingRolesData} />
      {rolesData !== undefined && rolesData.map((role, i) => (
        <div key={i}>
          <CardRoleMobile onClick={() => handleShowData(role)}>
            <ListBold>Client: {role?.clientName}</ListBold>
            <List>Job Title: {role?.title ?? "-"}</List>
            <List>Industry: {role?.industry ?? "-"}</List>
            <List>Location: {locationName(role?.location?.displayName)}</List>
            <List>Type: {role?.workType ?? "-"}</List>
            <List>Audience: {role?.audience ?? "-"}</List>
            <List>Matches: {role?.matches ?? "-"}</List>
            <List>Status: {role?.status ?? "-"}</List>
          </CardRoleMobile>
          <ButtonContainer>
            <Button size="sm" variant="secondary" onClick={() => handleShowData(role)}>
              <PencilSquare />
            </Button>
            <Button size="sm" variant="danger" onClick={() => handleShowModalDelete(role?.id)}>
              <TrashFill />
            </Button>
          </ButtonContainer>
        </div>
      ))}
    </ContainerRolesMobile>
  );
}

export default RolesMobileView;
