import React from "react";
import { Card, Container } from "react-bootstrap";
import terms from "../../assets/role_relay_terms.pdf";

const Dispute = () => (
  <>
    {/* <Header /> */}
    <Container>
      <br />
      <br />
      <h4>Dispute Resolution</h4>
      <br />
      <Card>
        <Card.Body>
          <p>
            Agencies must ensure that they and their staff have reviewed the
            Role Relay{" "}
            <a href={terms} target="_blank" rel="noreferrer">
              Terms and Conditions
            </a>{" "}
            prior to utilising the platform. Any misconduct via the must be
            reported to Pilot Manager John Towey (
            <a href="mailto:jtowey@rcsa.com.au">jtowey@rcsa.com.au</a>). Based
            on the Terms and Conditions of the platform, agency misconduct
            includes, but is not limited to:
          </p>

          <ul>
            <li>
              Contacting a candidate without Candidate Agency permission -
              Contacting a client without Client Agency Permission
            </li>

            <li> Placing a candidate in a role not listed on the platform </li>

            <li>
              Approaching a client of a Client Agency within 12 months of a
              connection on the platform
            </li>

            <li> Failing to flag a Successful Placement on the platform</li>
          </ul>

          <p>
            Agencies are reminded that misconduct on the platform may represent
            a breach of the RCSA Code of Professional Conduct which could result
            in a grievance being raised with your agency.
          </p>

          <p>
            Should you have a dispute relating to a placement, you are
            encouraged to contact Pilot Manager John Towey (
            <a href="mailto:jtowey@rcsa.com.au">jtowey@rcsa.com.au</a>) to
            discuss mediation. Role Relay reserves the right to deny agencies
            access to the platform at their discretion.
          </p>
        </Card.Body>
      </Card>
    </Container>
  </>
);

export default Dispute;
