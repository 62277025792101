import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, Form, Row, Col, Modal, OverlayTrigger } from "react-bootstrap";
import {
  CheckCircleFill,
  XCircleFill,
  SendFill,
  QuestionCircleFill,
} from "react-bootstrap-icons";
import { CardFooter, Popover } from "../../../sharedStyles";
import DatePicker from "react-datepicker";
import { MatchType, MatchAgentStatus } from "../../../interfaces";
import { Auth } from "aws-amplify";
import confirmWithModal from '../../Global/confirmWithModal';
import {
  AMEND_CANDIDATE_AGENT_MATCH,
  AMEND_ROLE_AGENT_MATCH,
} from "../../../GraphQL/matches/mutations";

interface Props {
  match: MatchType;
  type: "role" | "candidate";
  setParentStatus: (status: MatchAgentStatus) => void;
  agency?: string;
}

const MatchFooter = ({ match, type, setParentStatus, agency }: Props) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>("");

  const client = useApolloClient();

  const [status, setStatus] = useState<MatchAgentStatus>(
    type === "role" ? match.candidateAgentStatus : match.jobAgentStatus
  );

  const otherStatus =
    type === "role" ? match.jobAgentStatus : match.candidateAgentStatus;

  console.log(status, otherStatus);

  const [agentName, setAgentName] = useState<string>("");
  const [agentEmail, setAgentEmail] = useState<string>("");
  const [agentPhone, setAgentPhone] = useState<string>("");
  const [expectedFee, setExpectedFee] = useState<string>("");
  const [expectedStartDate, setExpectedStartDate] = useState<string | null>(
    null
  );
  const [expectedSchedule, setExpectedSchedule] = useState<string>("");
  const [settlementFee, setSettlementFee] = useState<string>("");
  const [settlementSchedule, setSettlementSchedule] = useState<string>("");

  const getSession = async () => {
    if (agentName === "") {
      const session = await Auth.currentSession();
      if (session?.getIdToken()?.payload?.email) {
        setAgentName(
          `${session.getIdToken().payload.given_name} ${
            session.getIdToken().payload.family_name
          }`
        );
        setAgentEmail(session.getIdToken().payload.email);
        setAgentPhone(session.getIdToken().payload.phone_number);
      }
    }
  };
  useEffect(() => {
    getSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateContactName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgentName(e.target.value);
  };

  const updateContactEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgentEmail(e.target.value);
  };

  const updateContactPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgentPhone(e.target.value);
  };

  const updateExpectedFee = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExpectedFee(e.target.value);
  };

  const handleUnsuccessfulPlacement = async () => {
    setShowFeedbackModal(true);
  };

  const hideFeedbackModal = () => {
    setShowFeedbackModal(false);
  };

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(e.target.value);
  };

  const handleFeedbackSubmit = async () => {
    onUpdateStatus("UNSUCCESSFUL");
  };

  const handleAgentConfirm = async () => {
    if (type === "candidate") {
      const confirmation = await confirmWithModal(
        <>
          <p>By clicking 'Yes' you confirm:</p>
          <ul>
            <li>
              You will not contact any candidates you become aware of through
              Role Relay without prior written consent from the Candidate Agency
            </li>
            <li>
              You will only represent the candidate to roles where you have
              received permission from the Candidate Agency
            </li>
            <li>
              If the candidate is successfully placed in this role you will pay
              the Candidate Agency the confirmed fee split amount per Section 11
              of the Role Relay Marketplace Terms and Conditions
            </li>
          </ul>
        </>
      );
      if (!confirmation) return;
    }
    onUpdateStatus("AGENT_CONFIRMED");
  };

  const handleClaimPlacement = async () => {
    if (type === "role") {
      const confirmation = await confirmWithModal(
        <>
          <p>
            By clicking yes, you confirm that the candidate has been placed in
            the role and satisfied the terms of a Successful Role Placement
            (10.8 or 10.9 of the Role Relay Terms and Conditions).
          </p>
        </>
      );
      if (!confirmation) return;
    }

    if (type === "candidate") {
      const confirmation = await confirmWithModal(
        <>
          <p>
            By clicking claim placement, you are flagging that the candidate has
            been placed in the role and satisfied the terms of a Successful Role
            Placement (10.8 or 10.9 of the Role Relay Terms and Conditions).
          </p>
        </>
      );
      if (!confirmation) return;
    }

    onUpdateStatus("SUCCESSFUL_PLACEMENT");
  };

  const onUpdateStatus = async (status: MatchAgentStatus) => {
    if (status === "AGENT_CONFIRMED") {
      if (
        !agentName ||
        !agentEmail ||
        !agentPhone ||
        agentName === "" ||
        agentEmail === "" ||
        agentPhone === ""
      ) {
        alert("Please enter your contact details");
        return;
      }
    }

    if (status === "SUCCESSFUL_PLACEMENT") {
      if (!expectedFee || expectedFee === "") {
        alert("Please enter the expected fee for this placement");
        return;
      }
    }

    let savedAgentName =
      type === "role" ? match.candidateAgentName : match.jobAgentName;
    let savedAgentEmail =
      type === "role" ? match.candidateAgentEmail : match.jobAgentEmail;
    let savedAgentPhone =
      type === "role" ? match.candidateAgentPhone : match.jobAgentPhone;

    await client.mutate({
      mutation:
        type === "role" ? AMEND_CANDIDATE_AGENT_MATCH : AMEND_ROLE_AGENT_MATCH,
      variables: {
        expectedFee: expectedFee,
        id: `${match.id}`,
        status: status,
        feedback: feedback,
        name: status === "SUCCESSFUL_PLACEMENT" ? savedAgentName : agentName,
        email: status === "SUCCESSFUL_PLACEMENT" ? savedAgentEmail : agentEmail,
        phone: status === "SUCCESSFUL_PLACEMENT" ? savedAgentPhone : agentPhone,
        expectedStartDate: expectedStartDate,
        expectedSchedule: expectedSchedule,
        settlementFee: settlementFee,
        settlementSchedule: settlementSchedule,
      },
    });

    // TODO: Detect if an error
    setFeedback("");
    setStatus(status);
    setParentStatus(status);
  };

  const messages = [
    `Are you potentially interested in this ${
      type === "role" ? "role" : "candidate"
    }?`, // TODO: Still
    "Are you happy to collaborate with agency: ",
  ];

  if (status === "PENDING") {
    return (
      <>
        <CardFooter>
          <div>
            <p>
              <strong>{messages[0]}</strong>
            </p>
          </div>
          <div>
            <Button
              onClick={() => onUpdateStatus("INTEREST_CONFIRMED")}
              size="sm"
              variant="primary"
            >
              <CheckCircleFill /> Yes
            </Button>{" "}
            <Button
              onClick={() => onUpdateStatus("INTEREST_REJECTED")}
              size="sm"
              variant="secondary"
            >
              <XCircleFill /> No
            </Button>
          </div>
        </CardFooter>
        {type === "role" ? (
          <>
            <p>
              If you click yes, the Client Agency will be identified and you
              will be able to decide whether you wish to work with them to fill
              this role.
            </p>
            <p>
              If you click no, the other agency will be notified you are not
              interested and the match will be closed.
            </p>
          </>
        ) : (
          <>
            <p>
              If you click yes, the Candiate Agency will be identified and you
              will be able to decide whether you wish to work with them to fill
              this role.
            </p>
            <p>
              If you click no, the other agency will be notified you are not
              interested and the match will be closed.
            </p>
          </>
        )}
      </>
    );
  } else if (status === "INTEREST_CONFIRMED") {
    return (
      <>
        <p>
          These are <strong>your</strong> contact details that will be shared
          with the other agency for them to connect with you and discuss how the
          recruitment process will be managed. Please ensure these are correct.
        </p>
        <Row>
          <Col>
            <Form.Group controlId="formContactName">
              <Form.Label>Contact name*</Form.Label>
              <Form.Control
                type="text"
                name="contactName"
                value={agentName}
                onChange={updateContactName}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formContactEmail">
              <Form.Label>Contact email*</Form.Label>
              <Form.Control
                type="text"
                name="contactEmail"
                value={agentEmail}
                onChange={updateContactEmail}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formContactPhone">
              <Form.Label>Contact phone*</Form.Label>
              <Form.Control
                type="text"
                name="contacPhone"
                value={agentPhone}
                onChange={updateContactPhone}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <CardFooter>
          <div>
            {messages[1]}
            <strong>{agency}</strong>?
          </div>
          <div>
            <Button onClick={handleAgentConfirm} size="sm" variant="primary">
              <CheckCircleFill /> Yes
            </Button>{" "}
            <Button
              onClick={() => onUpdateStatus("AGENT_REJECTED")}
              size="sm"
              variant="secondary"
            >
              <XCircleFill /> No
            </Button>
          </div>
        </CardFooter>
        {type === "role" ? (
          <p>
            If you click 'yes', the Client agency will be notified of the match.
            If the Client Agency also clicks 'yes' and chooses to proceed, your
            contact details will be shared with them.
          </p>
        ) : (
          <p>
            If you click yes, the Candidate Agency will be notified of the
            match. If the Candidate Agency also clicks yes, your contact details
            will be shared.{" "}
          </p>
        )}
      </>
    );
  } else if (
    status === "AGENT_CONFIRMED" &&
    (otherStatus === "AGENT_CONFIRMED" ||
      otherStatus === "SUCCESSFUL_PLACEMENT")
  ) {
    return (
      <>
        <Modal show={showFeedbackModal} onHide={hideFeedbackModal}>
          {" "}
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please provide some feedback as to why this placement was
              unsuccessful.
            </p>
            <Form.Control
              as="textarea"
              name="description"
              rows={5}
              placeholder="Enter feedback ..."
              onChange={handleFeedbackChange}
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={hideFeedbackModal} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleFeedbackSubmit} variant="primary">
              <SendFill /> Send
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col>
            {" "}
            <p>
              If you make a placement, you will need to confirm the placement by
              clicking the Claim Placement’ button.
            </p>
            <p>
              {" "}
              If the candidate is unsuccessful, please let us know by clicking
              the ‘Unsuccessful’ button.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formExpectedFee">
              <Form.Label>Expected fee*</Form.Label>{" "}
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Popover>
                    <Popover.Body>
                      The total fee or amount per hour you expect to{" "}
                      {type === "role" ? "receive from" : "pay"} the{" "}
                      {type === "role" ? "client" : "candidate"} agency
                    </Popover.Body>
                  </Popover>
                }
              >
                <QuestionCircleFill />
              </OverlayTrigger>
              <Form.Control
                type="text"
                name="expectedFee"
                value={expectedFee}
                onChange={updateExpectedFee}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formExpectedStartDate">
              <Form.Label>Expected start date*</Form.Label>{" "}
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Popover>
                    <Popover.Body>
                      The date the candidate is expected to start in the role
                    </Popover.Body>
                  </Popover>
                }
              >
                <QuestionCircleFill />
              </OverlayTrigger>
              <DatePicker
                locale="enAU"
                dateFormat="dd/MM/yyyy"
                selected={
                  expectedStartDate ? new Date(expectedStartDate) : null
                }
                onChange={(date) => {
                  if (date) {
                    setExpectedStartDate(date.toISOString());
                  }
                }}
                className="form-control"
                minDate={new Date()}
                customInput={
                  <Form.Control
                    type="text"
                    id="validationCustom01"
                    placeholder="Select start date"
                  />
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formExpectedSchedule">
              <Form.Label>Expected schedule*</Form.Label>{" "}
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Popover>
                    <Popover.Body>
                      The payment terms and schedule agreed with the{" "}
                      {type === "role" ? "client" : "candidate"} agency. Eg.
                      Payment will be made in full 1 month after start date with
                      a 6 month replacement guarantee
                    </Popover.Body>
                  </Popover>
                }
              >
                <QuestionCircleFill />
              </OverlayTrigger>
              <Form.Control
                type="text"
                name="expectedSchedule"
                value={expectedSchedule}
                onChange={(evt) => setExpectedSchedule(evt.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <CardFooter>
          <div></div>
          <div>
            <Button onClick={handleClaimPlacement} size="sm" variant="primary">
              <CheckCircleFill /> Claim placement
            </Button>{" "}
            <Button
              onClick={() => handleUnsuccessfulPlacement()}
              size="sm"
              variant="secondary"
            >
              <XCircleFill /> Unsuccessful
            </Button>
          </div>
        </CardFooter>
        {type === "role" ? (
          <>
            <strong>What happens next</strong>
            <p>
              We will check the details of your Successful Role Placement align
              with the Client Agency. If they do, we will collect your share and
              pay this to you within 14 days of receiving this from the Client
              Agency. If they do not, we will be in touch with you.
            </p>
          </>
        ) : (
          <>
            <strong>What happens next</strong>
            <p>
              We will check the details of your Successful Role Placement align
              with those supplied by the Candidate Agency. If they do, we will
              collect the Candidate Agency Share from you and pay this to the
              Candidate Agency. If they do not, we will be in touch with you.
            </p>
          </>
        )}
      </>
    );
  } else if (status === "SUCCESSFUL_PLACEMENT") {
    return (
      <>
        <Row>
          <Col>
            <p>
              This is to be completed once placement has been settled and fees
              have been paid
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formExpectedFee">
              <Form.Label>Settlement fee*</Form.Label>{" "}
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Popover>
                    <Popover.Body>
                      {type === "role"
                        ? "The amount received from the candidate agency"
                        : "The amount paid to the candidate agency"}
                    </Popover.Body>
                  </Popover>
                }
              >
                <QuestionCircleFill />
              </OverlayTrigger>
              <Form.Control
                type="text"
                name="settlementFee"
                value={settlementFee}
                onChange={(evt) => setSettlementFee(evt.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formExpectedFee">
              <Form.Label>Settlement schedule*</Form.Label>{" "}
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Popover>
                    <Popover.Body>
                      {type === "role"
                        ? "The amount paid to the candidate agency"
                        : "The date/s payment was made to the candidate agency"}
                    </Popover.Body>
                  </Popover>
                }
              >
                <QuestionCircleFill />
              </OverlayTrigger>
              <Form.Control
                type="text"
                name="settlementSchedule"
                value={settlementSchedule}
                onChange={(evt) => setSettlementSchedule(evt.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <CardFooter>
          <div></div>
          <div>
            <Button
              onClick={() => onUpdateStatus("SETTLED")}
              size="sm"
              variant="primary"
            >
              <CheckCircleFill /> Claim settled
            </Button>{" "}
            {/* <Button
              onClick={() => handleUnsuccessfulPlacement()}
              size="sm"
              variant="secondary"
            >
              <XCircleFill /> Unsuccessful
            </Button> */}
          </div>
        </CardFooter>
      </>
    );
  }

  return (
    <CardFooter>
      <div></div>
      <div>
        {/* <Button
          onClick={() => onUpdateStatus("PENDING")}
          size="sm"
          variant="primary"
        >
          <CheckCircleFill /> Reset
        </Button> */}
      </div>
    </CardFooter>
  );
};

export default MatchFooter;
