import { Modal, Button, Form } from "react-bootstrap";
import { ErrorWrapper } from "../../../../sharedStyles";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { SEND_INVITATION } from "../../../../GraphQL/settings/mutations";
import { EToastColorStatus, EToastPosition } from "../../../../interfaces";

const InviteEmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

interface IInviteModalProps {
  showModalInvite: boolean;
  onCloseModalInvite: () => void;
  onHandleCreateToast: (
    color: EToastColorStatus,
    position: EToastPosition,
    message: string,
  ) => void;
}

const InviteUserModal = ({
  showModalInvite,
  onCloseModalInvite,
  onHandleCreateToast = () => {},
}: IInviteModalProps) => {
  const [inviteEmail] = useMutation(SEND_INVITATION, {
    onCompleted(data) {
      if (data?.sendInvitation?.ok) {
        onHandleCreateToast(
          EToastColorStatus.SUCCESS,
          EToastPosition.BOTTOM_END,
          "Successfully invited!",
        );
      } else {
        onHandleCreateToast(
          EToastColorStatus.FAILED,
          EToastPosition.BOTTOM_END,
          "Something went wrong!",
        );
      }
    },
    onError() {
      onHandleCreateToast(
        EToastColorStatus.FAILED,
        EToastPosition.BOTTOM_END,
        "Something went wrong!",
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: InviteEmailSchema,
    onSubmit: (values) => {
      const { email } = values;
      inviteEmail({ variables: { email }});
      onCloseModalInvite();
    },
  });

  const { errors, touched } = formik;

  return (
    <Modal centered show={showModalInvite} onHide={onCloseModalInvite}>
      <Modal.Header closeButton>
        <Modal.Title>Invite a user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <p>Enter email address of someone you would like to invite to join your company on Role Relay</p>
          <Form.Group>
            <Form.Label>Email*</Form.Label>
            <Form.Control name="email" type="email" placeholder="e.g. your@mail.com" onChange={formik.handleChange} />
            <ErrorWrapper>{errors.email && touched.email ? errors?.email : ''}</ErrorWrapper>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => formik.submitForm()}>
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default InviteUserModal;
