import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { EnvelopeFill } from "react-bootstrap-icons";
import confirmWithModal from "../../Global/confirmWithModal";
import {
  PublicCandidate,
  Job,
  JobData,
  ProjectCandidate,
} from "../../../interfaces";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_ROLES } from "../../../GraphQL/roles/queries";
import { ADD_CANDIDATE_MATCH } from "../../../GraphQL/matches/mutations";
import { CREATE_CANDIDATE_TOPIC } from "../../../GraphQL/messages/mutations";
import { Auth } from "aws-amplify";
import parse from "html-react-parser";
import ReactSelect from "react-select";
import { ButtonRightCorner } from "../../../sharedStyles";
import {
  ACCEPT_PROJECT_CANDIDATE,
  REJECT_PROJECT_CANDIDATE,
} from "../../../GraphQL/candidates/mutations";
import {
  GET_PROJECT_CANDIDATE_DETAILS_FROM_ATS,
  TEMP_GET_CANDIDATE_ATTACHMENTS,
} from "../../../GraphQL/candidates/queries";
import { currencyFormat } from "../../../utils";

interface Props {
  variantType: string;
  show: boolean;
  onHide: () => void;
  candidate: PublicCandidate | ProjectCandidate;
  refetch?: () => void;
  boardId?: string | null;
}

const PublicCandidateDetailModal = ({
  variantType,
  show,
  onHide,
  candidate,
  refetch,
  boardId,
}: Props) => {
  const { loading, error, data } = useQuery<JobData>(GET_ROLES);
  const [addJobMatch] = useMutation(ADD_CANDIDATE_MATCH);
  const [createTopic] = useMutation(CREATE_CANDIDATE_TOPIC);
  const [acceptProjectCandidate] = useMutation(ACCEPT_PROJECT_CANDIDATE);
  const [rejectProjectCandidate] = useMutation(REJECT_PROJECT_CANDIDATE);

  const [query, setQuery] = useState<string>("");
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [multipleJobs, setMultipleJobs] = useState<any[]>([]);
  const [jobIds, setJobIds] = useState<number[]>([]);
  const [tempAttachmentNames, setTempAttachmentNames] = useState<any[]>([]);
  const [candidateDetail, setCandidateDetail] = useState<any>(null);

  const client = useApolloClient();

  useEffect(() => {
    let data: number[] = [];
    for (let i in multipleJobs) data.push(multipleJobs[i]?.value as number);
    setJobIds(data);
  }, [multipleJobs]);

  useEffect(() => {
    const fetchData = async () => {
      if (variantType === "project" && boardId && candidate) {
        console.log({ candidate });
        // fetch extra details from ATS
        const candidateDetailData = await client.query({
          query: GET_PROJECT_CANDIDATE_DETAILS_FROM_ATS,
          variables: { boardId, candidateId: `${candidate.id}` },
        });
        if (candidateDetailData.data?.projectCandidateDetailsFromATS) {
          setCandidateDetail(
            candidateDetailData.data?.projectCandidateDetailsFromATS
          );
        }

        const tempAttachmentDataResult: any = await client.query({
          query: TEMP_GET_CANDIDATE_ATTACHMENTS,
          variables: {
            candidateId: candidate.id,
          },
        });
        console.log({ tempAttachmentDataResult });
        if (tempAttachmentDataResult?.data?.candidateAttachments) {
          setTempAttachmentNames(
            tempAttachmentDataResult.data.candidateAttachments
          );
        }
      }
    };
    fetchData();
  }, [variantType, boardId, candidate]);

  if (!candidate || loading || error) return null;

  const roleOptions = data?.jobs?.items?.filter(
    (role: Job) => role.status === "SHARED"
  );
  // TODO: Filter roles so only opted in are included

  let proposedRoleValue = [];
  if (roleOptions) {
    for (let i in roleOptions) {
      const value = roleOptions[i]?.id;
      const label = `${roleOptions[i]?.title} - ${roleOptions[i]?.location?.displayName}`;
      proposedRoleValue.push({ value, label });
    }
  }

  const handleDownload = async (
    _id: string,
    _candidateId: string,
    _projectId: string,
    _fileName: string
  ) => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    console.log({ token });
    const url = `https://dev.api.rolerelay.com/api/projects/${_projectId}/candidates/${_candidateId}/attachments/${_id}`;

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(url, options)
      .then((res) => res.blob())
      .then((blob) => {
        // var file = window.URL.createObjectURL(blob);
        // window.location.assign(file);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = _fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  };

  const handleClick = async () => {
    if (!jobIds)
      return alert("Please select a role to put forward for this candidate");

    const confirmed = await confirmWithModal(
      <>
        <p>Before sharing a role, you confirm that</p>
        <ul>
          <li>
            You have removed any information identifying your client from the
            description{" "}
          </li>
          <li>
            there is a genuine casual, part-time, temporary, permanent or
            fixed-term opportunity.
          </li>
        </ul>
      </>
    );
    if (!confirmed) return;

    try {
      await addJobMatch({
        variables: { candidateId: candidate?.id, jobIds: jobIds },
      });
    } catch (err) {
      return alert(
        "Something went wrong, have you already put forward this role for this candidate? If not please try again soon"
      );
    }
    alert("Role has been put forward for this candidate!");
    onHide();
  };

  const handleSendQuery = async () => {
    try {
      await createTopic({
        variables: {
          candidateId: candidate?.id,
          message: query,
        },
      });
      alert("Your query has been sent to the client agent");
      setShowQuery(false);
    } catch (error) {
      alert("Something went wrong, please try again soon");
      setShowQuery(false);
    }
  };

  const handleProjectAccept = async () => {
    try {
      await acceptProjectCandidate({
        variables: {
          candidateId: `${candidate?.id}`,
          projectCandidateBoardId: boardId,
        },
      });
      alert("Candidate has been accepted");
      if (refetch) refetch();
      onHide();
    } catch (error) {
      alert("Something went wrong, please try again soon");
    }
  };
  const handleProjectReject = async () => {
    try {
      await rejectProjectCandidate({
        variables: {
          candidateId: `${candidate?.id}`,
          projectCandidateBoardId: boardId,
        },
      });
      alert("Candidate has been rejected");
      if (refetch) refetch();
      onHide();
    } catch (error) {
      alert("Something went wrong, please try again soon");
    }
  };

  return (
    <Modal show={show} size="lg" onHide={onHide}>
      <Modal.Header>
        {variantType === "project" && (
          <Modal.Title>
            {candidateDetail?.firstName} {candidateDetail?.lastName}
          </Modal.Title>
        )}

        {variantType !== "project" && (
          <Modal.Title>
            {candidate.idealJobTitles} - {candidate?.location?.displayName}
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            {variantType === "project" && (
              <>
                
                <strong>Phone:</strong> {candidateDetail?.phone ?? ""}
                <br />
                <strong>Mobile:</strong> {candidateDetail?.mobile ?? ""}
                <br />
                <strong>Email:</strong> {candidateDetail?.email ?? ""}
                <br />
              </>
            )}
            {variantType !== "project" && (
              <>
                <strong>Work type:</strong>{" "}
                {candidate.workType === "TEMP" ? "CONTRACT/TEMP" : "PERMANENT"}
                <br />
                <strong>Industry:</strong> {candidate.industry}
                <br />
                <strong>Salary:</strong> {currencyFormat(candidate.salaryMin)} -{" "}
                {currencyFormat(candidate.salaryMax)} {candidate?.currency}
                <br />
                <strong>Years Experience: </strong>
                {candidate.yearsOfExperience}
              </>
            )}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>{parse(candidate?.summary)}</Col>
        </Row>
        {variantType === "project" && candidate?.attachments?.length && (
          <>
            <hr />
            <Row>
              <Col>
                <strong>Documents:</strong>
                <br />

                {candidate.attachments.map((attachment: any) => {
                  const fileNameRecord = tempAttachmentNames.find(
                    (temp: any) => temp.externalId === attachment.externalId
                  );
                  const fileName = fileNameRecord?.fileName ?? "unknown";
                  return (
                    <Button
                      key={attachment.externalId}
                      variant="link"
                      onClick={() =>
                        handleDownload(
                          attachment.id,
                          attachment.candidateId,
                          attachment.projectId,
                          fileName
                        )
                      }
                    >
                      {fileName}
                    </Button>
                  );
                })}

                <br />
              </Col>
            </Row>
          </>
        )}
        {variantType === "project" && (
          <>
            <hr />
          </>
        )}
        {showQuery && (
          <>
            <Form.Control
              as="textarea"
              name="description"
              rows={5}
              placeholder="Enter query ..."
              onChange={(e) => setQuery(e.target.value)}
              required
            />
            {variantType !== "project" ? (
              <p className="my-3">
                Queries will be anonymous. No information to identify you or
                your agency will be exchanged when you submit a query.
              </p>
            ) : (
              <br />
            )}
            <ButtonRightCorner>
              <Button
                className="mx-1"
                variant="secondary"
                onClick={() => setShowQuery(false)}
              >
                Cancel
              </Button>
              <Button
                className="mx-1"
                variant="primary"
                onClick={handleSendQuery}
              >
                Send
              </Button>
            </ButtonRightCorner>
          </>
        )}
        {variantType !== "project" && (
          <>
            <h5>Propose a matching roles</h5>
            <Form.Group controlId="formWorkType">
              <Form.Label>
                Select a roles you would like to put forward for this candidate:
              </Form.Label>
              <ReactSelect
                onChange={(data: any) => setMultipleJobs(data)}
                name="proposedRole"
                isMulti={true}
                options={proposedRoleValue}
              />
            </Form.Group>
          </>
        )}
        <br />
        {!showQuery && (
          <Button onClick={() => setShowQuery(true)} size="sm">
            <EnvelopeFill /> Agent Query
          </Button>
        )}
        {variantType !== "project" && (
          <>
            <hr />
            <p>
              <strong>What happens next?</strong>
            </p>
            <p>
              The Candidate Agency that has listed this candidate will receive
              the anonymous details of your role along with your agency name. If
              they choose to proceed, you'll receive details of their agency to
              confirm whether you'd like to proceed. If they decline, we'll let
              you know.
            </p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {variantType === "project" ? (
          <>
            <Button onClick={handleProjectReject} variant="secondary">
              Reject
            </Button>
            <Button onClick={handleProjectAccept}>Accept/Progress</Button>
          </>
        ) : (
          <>
            <Button onClick={onHide} variant="secondary">
              Close
            </Button>
            <Button onClick={handleClick}>Submit</Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default PublicCandidateDetailModal;
