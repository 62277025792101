import { Candidate } from "../../../interfaces";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { PencilSquare, TrashFill } from "react-bootstrap-icons";
import styled from "styled-components";
import SelectCandidatesMobile from "./SelectCandidatesMobile";

const List = styled.p`
  margin-bottom: 0.15rem;
`;

const ListBold = styled(List)`
  font-weight: bold;
`;

const ContainerMobile = styled.div`
  margin-bottom: 1rem;
  margin-top: -1rem;
`;

const CardCandidateMobile = styled.div`
  border-top: 1px solid #ccc;
  padding: 1rem 1rem 0 1rem;
  margin: 0 -1rem;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: .75rem;
  Button {
    margin-left: .5rem;
  }
`;

interface ICandidateProps {
  onShowCandidateModal?: () => void,
  onSelectCandidate: (data: any) => void,
  onSortingCandidatesData: (data: any) => void,
  onSetNonATS?: () => void,
  candidatesData?: Candidate[],
  handleShowModalDelete: (data: number) => void,
  locationName: (x: string) => string[],
}

const CandidatesMobileView = ({
  onShowCandidateModal = () => {},
  onSelectCandidate,
  onSortingCandidatesData,
  onSetNonATS = () => {},
  candidatesData,
  handleShowModalDelete,
  locationName,
}: ICandidateProps) => {
  const navigate = useNavigate();

  const handleShowData = (dataCandidate: Candidate) => {
    onShowCandidateModal();
    onSelectCandidate(dataCandidate);
    onSetNonATS();
    navigate(`/my-candidates/${dataCandidate?.id}`);
  }

  return (
    <ContainerMobile>
      <SelectCandidatesMobile onSortingCandidatesData={onSortingCandidatesData} />
      {candidatesData !== undefined && candidatesData.map((candidate, i) => (
        <div key={i}>
          <CardCandidateMobile onClick={() => handleShowData(candidate)}>
            <ListBold>Name: {`${candidate?.firstName} ${candidate?.lastName?.slice(0, 1)}.` ?? '-'}</ListBold>
            <List>Job Title: {candidate?.idealJobTitles ?? "-"}</List>
            <List>Industry: {candidate?.industry?.trim().length === 0 ? "-": candidate?.industry ?? "-"}</List>
            <List>Location: {locationName(candidate?.location?.displayName as string)}</List>
            <List>Type: {candidate?.workType ?? "-"}</List>
            <List>Audience: {candidate?.audience ?? "-"}</List>
            <List>Years Exp: {candidate?.yearsOfExperience ?? "-"}</List>
            <List>Matches: {candidate?.matches ?? "-"}</List>
            <List>Status: {candidate?.status ?? "-"}</List>
          </CardCandidateMobile>
          <ButtonContainer>
            <Button size="sm" variant="secondary" onClick={() => handleShowData(candidate)}>
              <PencilSquare />
            </Button>
            <Button size="sm" variant="danger" onClick={() => handleShowModalDelete(candidate?.id)}>
              <TrashFill />
            </Button>
          </ButtonContainer>
        </div>
      ))}
    </ContainerMobile>
  );
}

export default CandidatesMobileView;
