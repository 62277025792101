import React, { useState } from "react";
import styled from "styled-components";
import { Badge as BSBadge, Button, Card, Row, Col } from "react-bootstrap";
import { EnvelopeFill } from "react-bootstrap-icons";
import { MatchType } from "../../../interfaces";
import { GET_MATCHED_ROLE } from "../../../GraphQL/roles/queries";
import { useQuery } from "@apollo/client";
import { currencyFormat } from "../../../utils";
import MatchFooter from "../MatchFooter";
import { Well } from "../../../sharedStyles";
import parse from "html-react-parser";

const Badge = styled(BSBadge)`
  font-size: 1rem;

  ::before {
    content: "Status: ";
  }
  margin-bottom: 8px;
`;

interface Props {
  match: MatchType;
  setShowQuery: (show: boolean) => void;
}

const renderStatus = (status: any, otherStatus: any) => {
  if (status === "PENDING" || status === "INTEREST_CONFIRMED") {
    return <Badge bg="warning">Pending your response</Badge>;
  } else if (
    status === "AGENT_CONFIRMED" &&
    (otherStatus === "INTEREST_CONFIRMED" || otherStatus === "PENDING")
  ) {
    return (
      <Badge bg="warning">Accepted - pending response from other agent</Badge>
    );
  } else if (
    status === "AGENT_CONFIRMED" &&
    otherStatus === "AGENT_CONFIRMED"
  ) {
    return (
      <>
        <Badge>Connected</Badge>
        <Well>
          You and the Client Agency have been connected - please find their
          details below. You can now contact the Client Agency to confirm how
          the recruitment process will be managed. Please ensure you have
          confirmation of how the process will be managed in writing before any
          party contacts a referred candidate or client.
        </Well>
      </>
    );
  } else if (status === "AGENT_REJECTED" || status === "INTEREST_REJECTED") {
    return <Badge bg="danger">Rejected</Badge>;
  } else if (
    otherStatus === "AGENT_REJECTED" ||
    otherStatus === "INTEREST_REJECTED"
  ) {
    return <Badge bg="danger">Rejected by other agent</Badge>;
  } else if (
    status === "SUCCESSFUL_PLACEMENT" &&
    otherStatus === "AGENT_CONFIRMED"
  ) {
    return (
      <Badge bg="warning">
        Placement claimed - pending confirmation from other agent
      </Badge>
    );
  } else if (
    status === "AGENT_CONFIRMED" &&
    otherStatus === "SUCCESSFUL_PLACEMENT"
  ) {
    return (
      <Badge bg="warning">Placement claimed - pending your confirmation</Badge>
    );
  } else if (
    status === "SUCCESSFUL_PLACEMENT" &&
    otherStatus === "SUCCESSFUL_PLACEMENT"
  ) {
    return <Badge>Placement confirmed</Badge>;
  } else if (status === "UNSUCCESSFUL" || otherStatus === "UNSUCCESSFUL") {
    return <Badge bg="danger">Unsuccessful</Badge>;
  } else if (status === "SETTLED" && otherStatus !== "SETTLED") {
    return (
      <Badge bg="warning">
        Settlement claimed - pending confirmation from other agent
      </Badge>
    );
  } else if (status !== "SETTLED" && otherStatus === "SETTLED") {
    return (
      <Badge bg="warning">Settlement claimed - pending your confirmation</Badge>
    );
  } else if (status === "SETTLED" && otherStatus === "SETTLED") {
    return <Badge>Placement settled</Badge>;
  }

  return <Badge>Unknown status</Badge>;
};

const RoleMatch = ({ match, setShowQuery }: Props) => {
  const [status, setStatus] = useState(match.candidateAgentStatus);

  const { loading, error, data } = useQuery(GET_MATCHED_ROLE, {
    variables: { matchId: `${match.id}` },
  });

  if (loading) return <p>Loading role...</p>;
  if (error) return <p>Error loading role</p>;

  const role = data?.matchedJob;

  return (
    <>
      <Card.Body>
        <div>{renderStatus(status, match.jobAgentStatus)}</div>
        <Row>
          <Col>
            <strong>Job title:</strong> {role.title}
            <br />
            <strong>Work type:</strong>{" "}
            {role.workType === "TEMP" ? "CONTRACT/TEMP" : "PERMANENT"}
            <br />
            <strong>Industry:</strong> {role.industry}
            <br />
            {role.workType === "PERMANENT" && (
              <>
                <strong>Salary:</strong> {currencyFormat(role.salaryMin)} -{" "}
                {currencyFormat(role.salaryMax)}
              </>
            )}
            {role.workType === "TEMP" && (
              <>
                <strong>Rate:</strong> {currencyFormat(role.salaryMin)} -{" "}
                {currencyFormat(role.salaryMax)} per hr
              </>
            )}
            <br />
            {role.workType === "PERMANENT" && (
              <>
                <strong>Start date:</strong>{" "}
                {new Date(role.startDate).toLocaleDateString()}
                <br />
              </>
            )}
            {role.workType === "TEMP" && (
              <>
                <strong>Placement start date:</strong>{" "}
                {new Date(role.placementStartDate).toLocaleDateString()}
                <br />
              </>
            )}
            {role.workType === "TEMP" && (
              <>
                <strong>Placement end date:</strong>{" "}
                {new Date(role.placementEndDate).toLocaleDateString()}
                <br />
              </>
            )}
          </Col>
          <Col>
            {role.workType === "TEMP" && (
              <>
                <strong>Working hours:</strong> {role.workingHours}
                <br />
              </>
            )}
            <strong>Engagement type:</strong> {role.engagementType}
            <br />
            <strong>Terms signed:</strong> {role.termsSigned ? "Yes" : "No"}
            <br />
            <strong>Replacement guarantee:</strong>{" "}
            {role.replacementGuarantee ? "Yes" : "No"}
            <br />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>{parse(role.summary)}</Col>
        </Row>

        <hr />

        {(status === "AGENT_CONFIRMED" || status === "SUCCESSFUL_PLACEMENT") &&
          (match.jobAgentStatus === "AGENT_CONFIRMED" ||
            match.jobAgentStatus === "SUCCESSFUL_PLACEMENT") && (
            <p>
              <br />
              {match.jobAgentName}
              <br />
              {match.jobAgentEmail}
              <br />
              {match.jobAgentPhone}
            </p>
          )}

        {match.jobAgentStatus === "SUCCESSFUL_PLACEMENT" && (
          <p>
           <strong>Claimed expected fee:</strong>{" "}
            {match.jobAgentExpectedFee}<br/>
            <strong>Claimed expected start date:</strong>{" "}
            {match.jobAgentExpectedStartDate && new Date(match.candidateAgentExpectedStartDate).toLocaleDateString()}<br/>
          <strong>Claimed expected schedule:</strong>{" "}
            {match.jobAgentExpectedSchedule}
          </p>
        )}
      </Card.Body>

      <br />
       <Button
        onClick={() => {
          setShowQuery(true);
        }}
        size="sm"
      >
        <EnvelopeFill /> Agent Query
      </Button>

      <hr />
      <MatchFooter
        type="role"
        match={match}
        setParentStatus={setStatus}
        agency={role?.companyName}
      />
      <hr />
    </>
  );
};

export default RoleMatch;
