import { Modal, Button, Form } from "react-bootstrap";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ErrorWrapper } from "../../../../sharedStyles";
import { useMutation } from "@apollo/client";
import { ADD_PROJECT } from "../../../../GraphQL/settings/mutations";
import { EToastColorStatus, EToastPosition } from "../../../../interfaces";
import Toggle from 'react-toggle';

const ToggleContainer = styled.div`
  display: flex;
align-items: center;
  div {
    margin-right: 6px;
  }

`;

const addProjectSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string(),
});

interface IAddModalProps {
  showAddModal: boolean;
  onCloseAddModal: () => void;
  reloadProjects: () => void;
  onHandleCreateToast: (
    color    : EToastColorStatus,
    position : EToastPosition,
    message  : string,
  ) => void;
}

const AddProjectModal = ({
  showAddModal,
  onCloseAddModal,
  reloadProjects,
  onHandleCreateToast = () => {},
}: IAddModalProps) => {
  const [addProject] = useMutation(ADD_PROJECT, {
    onCompleted() {
      onCloseAddModal();
      onHandleCreateToast(
        EToastColorStatus.SUCCESS,
        EToastPosition.BOTTOM_END,
        "Added new project successfully",
      );
      reloadProjects();
    },
    onError() {
      onHandleCreateToast(
        EToastColorStatus.FAILED,
        EToastPosition.BOTTOM_END,
        "Error, something went wrong",
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: addProjectSchema,
    onSubmit: (values) => {
      const { title, description } = values;
      addProject({ variables: { title, description }});
    },
  });

  const { errors, touched } = formik;

  return (
    <Modal centered show={showAddModal} onHide={onCloseAddModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="title"
              type="text"
              placeholder="Your project title"
              onChange={formik.handleChange}
              value={formik?.values?.title}
            />
            <ErrorWrapper>{errors?.title && touched?.title ? errors?.title : ''}</ErrorWrapper>
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              name="description" as="textarea"
              rows={3}
              placeholder="Your project description"
              onChange={formik.handleChange}
              value={formik?.values?.description}
            />
          </Form.Group><br/>
          <ToggleContainer><Toggle /><div>Allow invitees to add roles &amp; candidates </div></ToggleContainer>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onCloseAddModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={formik.submitForm}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddProjectModal;
