import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Card, Form } from "react-bootstrap";
import { ACCEPT_INVITATION } from "../../GraphQL/settings/mutations";
import { EToastPosition, EToastColorStatus, IToastData } from "../../interfaces";
import { ButtonRightCorner, PageContainer, ErrorWrapper } from "../../sharedStyles";
import Notification from "../Notification";

const formInvitationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string().required("Phone is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, 'Password must have a minimum of 8 characters')
    .matches(/\d+/, "Password must contain at least one number")
    .matches(/[a-z]+/, "Password must contain lowercase character (a-z)")
    .matches(/[A-Z]+/, "Password must contain uppercase character (A-Z)")
    .matches(/[@$/!%*<>+#?&~()^:{}]+/, "Password must contain a special character like : @$/!%*<>+#?&~()^:{}"),
  confirmPassword: Yup.string()
    .required('Please retype your password')
    .oneOf([Yup.ref('password')], 'Passwords does not match'),
  token: Yup.string(),
});

const UserInvitation = () => {
  const navigate = useNavigate();
  const token = new URLSearchParams(useLocation().search).get("token") ?? "";
  const [toastData, setToastData] = useState<IToastData>({
    show: false,
    color: EToastColorStatus.SUCCESS,
    position: EToastPosition.BOTTOM_END,
    message: "",
  });

  const [acceptUser] = useMutation(ACCEPT_INVITATION, {
    onCompleted(data) {
      if (data?.acceptInvitation?.ok) {
        setToastData({
          show: true,
          color: EToastColorStatus.SUCCESS,
          position: EToastPosition.BOTTOM_END,
          message: 'Congratulations, you have successfully registered!',
        });
        setTimeout(() => navigate('/', { replace: true }), 2000);
      } else {
        setToastData({
          show: true,
          color: EToastColorStatus.FAILED,
          position: EToastPosition.BOTTOM_END,
          message: 'Something went wrong!',
        });
      }
    },
    onError(error: any) {
      const errorMessage = error?.networkError?.result?.errors[0]?.message;
      setToastData({
        show: true,
        color: EToastColorStatus.FAILED,
        position: EToastPosition.BOTTOM_END,
        message: errorMessage ?? 'Something went wrong!',
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      token: token,
    },
    validationSchema: formInvitationSchema,
    onSubmit: (values) => {
      acceptUser({variables: {
        token: values?.token,
        firstName: values?.firstName,
        lastName: values?.lastName,
        phoneNumber: values?.phoneNumber,
        password: values?.password,
      }});
    },
    enableReinitialize: true,
  });

  const { errors, touched } = formik;

  return (
    <PageContainer className="d-flex align-items-center justify-content-center vh-100">
      <Card style={{ width: '36rem', minHeight: '22rem'}}>
        <Card.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-1">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                name="firstName"
                type="text"
                placeholder="Enter your first name"
                onChange={formik.handleChange}
                value={formik?.values?.firstName}
              />
              <ErrorWrapper>{errors?.firstName && touched?.firstName ? errors?.firstName : ''}</ErrorWrapper>
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                name="lastName"
                type="text"
                placeholder="Enter your last name"
                onChange={formik.handleChange}
                value={formik?.values?.lastName}
              />
              <ErrorWrapper>{errors?.lastName && touched?.lastName ? errors?.lastName : ''}</ErrorWrapper>
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                name="phoneNumber"
                type="text"
                placeholder="Enter your phone number"
                onChange={formik.handleChange}
                value={formik?.values?.phoneNumber}
              />
              <ErrorWrapper>{errors?.phoneNumber && touched?.phoneNumber ? errors?.phoneNumber : ''}</ErrorWrapper>
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder="Enter your password"
                onChange={formik.handleChange}
                value={formik?.values?.password}
              />
              <ErrorWrapper>{errors?.password && touched?.password ? errors?.password : ''}</ErrorWrapper>
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                name="confirmPassword"
                type="password"
                placeholder="Enter your confirm password"
                onChange={formik.handleChange}
                value={formik?.values?.confirmPassword}
              />
              <ErrorWrapper>{errors?.confirmPassword && touched?.confirmPassword ? errors?.confirmPassword : ''}</ErrorWrapper>
            </Form.Group>
          </Form>
        </Card.Body>
        <Card.Footer>
          <ButtonRightCorner>
            <Button
              variant="primary"
              onClick={formik.submitForm}
            >
              Save
            </Button>
          </ButtonRightCorner>
        </Card.Footer>
      </Card>
      <Notification
        delay={3000}
        toastData={toastData}
        onClose={() => setToastData({ ...toastData, show: false })}
      />
    </PageContainer>
  )
}

export default UserInvitation;
