import { useEffect, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonRightCorner, PageContainer } from "../../../sharedStyles";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  GET_CANDIDATE_BY_ID,
  GET_PROJECT_CANDIDATE_BOARDS,
  GET_CANDIDATE_ATTACHMENTS,
} from "../../../GraphQL/candidates/queries";
import {
  IMPORT_CANDIDATE,
  AMEND_CANDIDATE,
  SHARE_CANDIDATE_PROJECT_ATTACHMENTS,
} from "../../../GraphQL/candidates/mutations";
import ReactSelect from "react-select";

const AddToProject = ({ user }: any) => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const { action, id } = useParams();
  const [projectList, setProjectList] = useState<any>([]);
  const [projectIds, setProjectIds] = useState<any>([]);
  const [attachmentOptions, setAttachmentOptions] = useState<any>([]);
  const [documentIds, setDocumentIds] = useState<any>([]);

  const [messageValue, setMessageValue] = useState<string>("");
  const [importCandidate] = useMutation(IMPORT_CANDIDATE);
  const [amendCandidate] = useMutation(AMEND_CANDIDATE);
  const [shareAttachments] = useMutation(SHARE_CANDIDATE_PROJECT_ATTACHMENTS);

  const {
    error: errorProjects,
    loading: loadingProjects,
    data: dataProjects,
  } = useQuery(GET_PROJECT_CANDIDATE_BOARDS);

  useEffect(() => {
    const runFetchDocs = async () => {
      if(id){
      
      const dataDocuments:any = await client.query({query: GET_CANDIDATE_ATTACHMENTS, variables: { jobAdderId: id }});

      if (dataDocuments?.data?.candidateAttachments?.length) {
        
        setAttachmentOptions(
          dataDocuments.data.candidateAttachments.map((attachment: any) => ({
            value: attachment.externalId,
            label: attachment.fileName,
          }))
        );
      }
      
    }
    };
    if (id) {
      runFetchDocs();
    }
  }, []);

  // const { data: dataDocuments } = useQuery(GET_CANDIDATE_ATTACHMENTS, {
  //   variables: ,
  // });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!user) return navigate("/");
    }, 2500);

    return () => clearTimeout(timeout);
  }, [user]);



  useEffect(() => {
    console.log({ dataProjects });
    let val = [];
    if (dataProjects?.projectCandidateBoards?.items) {
      for (let i in dataProjects?.projectCandidateBoards?.items) {
        val.push({
          value: dataProjects?.projectCandidateBoards?.items[i]?.id,
          label: dataProjects?.projectCandidateBoards?.items[i]?.title,
          projectId: dataProjects?.projectCandidateBoards?.items[i]?.projectId,
        });
      }
    }
    setProjectList(val);
  }, [dataProjects]);

  if (!user) return <p>Not Authenticated!</p>;

  const handleAdd = async () => {
    if(!id) return;

    try {

      const res = await importCandidate({
        variables: {
          candidateId: parseInt(id),
        },
      });

      const candidateId = res.data?.importCandidate?.id;


      const candidate = await client.query({
        query: GET_CANDIDATE_BY_ID,
        variables: { id: candidateId },
      });

      const candidateData = candidate.data?.candidates?.items[0];
      console.log(candidateData);
      console.log(projectList);
      if (candidateData) {
        try {
          await amendCandidate({
            variables: {
              audience: candidateData.audience || "PRIVATE",
              currency: candidateData.currency || "AUD",
              firstName: candidateData.firstName || "",
              id: candidateId,
              idealJobTitles: candidateData.idealJobTitles || "",
              industry: candidateData.industry || "",
              interviewed: candidateData.interviewed || false,
              lastName: candidateData.lastName || "",
              localityId: candidateData.location?.localityId || 3,
              ownerUserIds: candidateData.candidateOwners || [],
              projectCandidateBoardIds: projectIds.map(
                (project: any) => project.value
              ),
              salaryMax: candidateData.salaryMax || 0,
              salaryMin: candidateData.salaryMin || 0,
              share: true,
              summary: candidateData.summary || "",
              workType: candidateData.workType || "",
              yearsOfExperience: candidateData.yearsOfExperience || 0,
            },
          });
        } catch (error) {
          console.log(error);
        }
      }

      // Share documents
      projectIds.forEach((project: any) => {
        try {
          const shareData = shareAttachments({
            variables: {
              candidateId: `${candidateId}`,
              externalIds: documentIds.map((doc: any) => doc.value),
              projectId: project.projectId,
            },
          });
          console.log({ shareData });
        } catch (err) {
          console.error("Error sharing document", err);
        }
      });

      alert("Candidate imported!");
    } catch (err) {
      alert("Error importing candidate. Please try again.");
    }
  };

  // const handleNotify = () => {

  // };

  return (
    <PageContainer className="d-flex align-items-center justify-content-center vh-100">
      <Card style={{ width: "36rem", minHeight: "22rem" }}>
        <Card.Body>
          {errorProjects && <p>Error: {errorProjects?.message}</p>}
          {loadingProjects && <p>Loading...</p>}
          {dataProjects && (
            <>
              <h4 className="mb-3">Projects</h4>
              <ReactSelect
                name="projectList"
                isMulti={true}
                options={projectList}
                onChange={(data: any) => {
                  setProjectIds(data);
                }}
                value={projectIds}
              />
              <br />
              <div>Documents</div>
              <ReactSelect
                name="documentList"
                isMulti={true}
                options={attachmentOptions}
                onChange={(data: any) => {
                  setDocumentIds(data);
                }}
                value={documentIds}
              />

              {action === "notify" && (
                <Form.Group className="my-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={(e) => setMessageValue(e.target.value)}
                  />
                </Form.Group>
              )}
            </>
          )}
        </Card.Body>
        <Card.Footer>
          <ButtonRightCorner>
            {/* <Button
              className="mx-1"
              variant="danger"
              // Todo: cancel button or redirect
            >
              Cancel
            </Button> */}
            {action === "notify" ? (
              <Button className="mx-1" variant="primary" onClick={handleAdd}>
                Send
              </Button>
            ) : (
              <Button className="mx-1" variant="primary" onClick={handleAdd}>
                Add
              </Button>
            )}
          </ButtonRightCorner>
        </Card.Footer>
      </Card>
    </PageContainer>
  );
};

export default AddToProject;
