import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_MATCH } from "../../../GraphQL/matches/queries";
import { GET_MATCHED_CANDIDATE } from "../../../GraphQL/candidates/queries";
import { GET_MATCHED_ROLE } from "../../../GraphQL/roles/queries";
import { Modal, Button, Card, Row, Col } from "react-bootstrap";
import { EnvelopeFill } from "react-bootstrap-icons";
import { currencyFormat } from "../../../utils";
import parse from "html-react-parser";
import RenderStatus from "../RenderStatus";
import QueryModal from "../../QueryModal";
import MatchFooter from "../../Matches/MatchFooter";

interface Props {
  show: boolean;
  onHide: () => void;
  matchContent: any;
  tabType: string;
  atsServer: string;
}

const MatchesDetailModal = ({show, onHide, matchContent, tabType, atsServer}: Props) => {
  const matchId = matchContent?.id;

  const {data: dataMatch} = useQuery(GET_MATCH, {
    variables: {
      id: matchId,
    },
    skip: !matchId,
  });

  const {
    error   : candidateErrorMatched,
    loading : candidateLoadingMatched,
    data    : candidateDataMatched,
  } = useQuery(GET_MATCHED_CANDIDATE, {
    variables: {
      matchId: matchId,
    },
    skip: !matchId,
  });

  const {
    error   : roleErrorMatched,
    loading : roleLoadingMatched,
    data    : roleDataMatched,
  } = useQuery(GET_MATCHED_ROLE, {
    variables: {
      matchId: matchId,
    },
    skip: !matchId,
  });

  const match = dataMatch?.match;
  const candidate = candidateDataMatched?.matchedCandidate;
  const role = roleDataMatched?.matchedJob;
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [statusRole, setStatusRole] = useState(match?.candidateAgentStatus);
  const [statusCandidate, setStatusCandidate] = useState(match?.jobAgentStatus);

  useEffect(() => {
    setStatusRole(match?.candidateAgentStatus);
  }, [match?.candidateAgentStatus]);

  useEffect(() => {
    setStatusCandidate(match?.jobAgentStatus);
  }, [match?.jobAgentStatus]);

  if (tabType === "roles-matches") {
    return (
      <>
        <QueryModal
          show={showQuery}
          hide={() => setShowQuery(false)}
          jobId={matchContent?.jobId}
        />
        <Modal show={show} onHide={onHide} size="lg" centered>
          <Modal.Header>
            <Modal.Title>
              {matchContent?.clientName} - {matchContent?.jobTitle}
              <div className="clearfix"></div>
              {matchContent?.jobLocation?.displayName}
            </Modal.Title>
            <a
              href={`https://${atsServer}.jobadder.com/joborders/${role?.externalId?.split(":").slice(-1)}`}
              target="_blank"
              rel="noreferrer"
            >
              View this role in JobAdder
            </a>
          </Modal.Header>
          <Modal.Body>
            {candidateLoadingMatched && <p>Loading...</p>}
            {candidateErrorMatched && <p>Error: {candidateErrorMatched?.message}</p>}
            {!candidateErrorMatched && !candidateLoadingMatched && candidateDataMatched && (
              <Card.Body>
                <RenderStatus
                  position="modal"
                  status={statusCandidate}
                  otherStatus={statusRole}
                />
                <Row>
                  <Col>
                    <strong>Job Title:</strong>{candidate?.jobTitle}<br />
                    <strong>Ideal Job Titles: </strong>{candidate?.idealJobTitles}<br />
                    <strong>Location: </strong>{candidate?.location?.displayName}<br />
                    <strong>Work Type: </strong>{candidate?.workType === "TEMP" ? "CONTRACT/TEMP": "PERMANENT"}<br />
                    <strong>Industry: </strong>{candidate?.industry}<br />
                    <strong>Salary: </strong>{currencyFormat(candidate?.salaryMin)} -{" "}{currencyFormat(candidate?.salaryMax)} {matchContent?.currency}<br />
                    <strong>Years Experience: </strong>{candidate?.yearsOfExperience}<br />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>{parse(candidate?.summary)}</Col>
                </Row>
                <hr />
                {match &&
                  (statusCandidate === "AGENT_CONFIRMED" || statusCandidate === "SUCCESSFUL_PLACEMENT") &&
                  (match?.candidateAgentStatus === "AGENT_CONFIRMED" || match?.candidateAgentStatus === "SUCCESSFUL_PLACEMENT") && (
                    <p>
                      <br />
                      {match?.candidateAgentName}
                      <br />
                      {match?.candidateAgentEmail}
                      <br />
                      {match?.candidateAgentPhone}
                    </p>
                )}
                {match && match?.candidateAgentStatus === "SUCCESSFUL_PLACEMENT" && (
                  <p>
                    <strong>Claimed expected fee:</strong>{" "}
                    {match?.candidateAgentExpectedFee}<br/>
                    <strong>Claimed expected start date:</strong>{" "}
                    {match?.candidateAgentExpectedStartDate && new Date(match?.candidateAgentExpectedStartDate).toLocaleDateString()}<br/>
                    <strong>Claimed expected schedule:</strong>{" "}
                    {match?.candidateAgentExpectedSchedule}
                  </p>
                )}
              </Card.Body>
            )}
            <br />
            <Button size="sm" onClick={() => setShowQuery(true)}>
              <EnvelopeFill /> Agent Query
            </Button>
            <hr />
            {match && (
              <MatchFooter
                type="candidate"
                match={match}
                setParentStatus={setStatusCandidate}
                agency={candidate?.companyName}
              />
            )}
          </Modal.Body>
        </Modal>
      </>
    )
  } else {
    return (
      <>
        <QueryModal
          show={showQuery}
          hide={() => setShowQuery(false)}
          candidateId={matchContent?.candidateId}
        />
        <Modal show={show} onHide={onHide} size="lg" centered>
          <Modal.Header>
            <Modal.Title>
              {matchContent?.candidateName} - {matchContent?.candidateJobTitle}
              <div className="clearfix"></div>
              {matchContent?.candidateLocation?.displayName}
            </Modal.Title>
            <a
              href={`https://${atsServer}.jobadder.com/candidates/${candidate?.externalId?.split(":").slice(-1)}`}
              target="_blank"
              rel="noreferrer"
            >
              View this candidate in JobAdder
            </a>
          </Modal.Header>
          <Modal.Body>
            {roleLoadingMatched && <p>Loading...</p>}
            {roleErrorMatched && <p>Error: {roleErrorMatched?.message}</p>}
            {!roleLoadingMatched && !roleErrorMatched && roleDataMatched && (
              <Card.Body>
                <RenderStatus
                  position="modal"
                  status={statusRole}
                  otherStatus={statusCandidate}
                />
                <Row>
                  <Col>
                    <strong>Job title:</strong> {role?.title}<br />
                    <strong>Work type:</strong> {role?.workType === "TEMP" ? "CONTRACT/TEMP" : "PERMANENT"}<br />
                    <strong>Industry:</strong> {role?.industry}<br />
                    {role.workType === "PERMANENT" && (
                      <>
                        <strong>Salary:</strong> {currencyFormat(role?.salaryMin)} -{" "}
                        {currencyFormat(role?.salaryMax)} {matchContent?.currency}
                      </>
                    )}
                    {role.workType === "TEMP" && (
                      <>
                        <strong>Rate:</strong> {currencyFormat(role?.salaryMin)} -{" "}
                        {currencyFormat(role?.salaryMax)} per hr
                      </>
                    )}
                    <br />
                    {role?.workType === "PERMANENT" && (
                      <>
                        <strong>Start date:</strong>{" "}
                        {new Date(role?.startDate).toLocaleDateString()}
                        <br />
                      </>
                    )}
                    {role.workType === "TEMP" && (
                      <>
                        <strong>Placement start date:</strong>{" "}
                        {new Date(role?.placementStartDate).toLocaleDateString()}
                        <br />
                      </>
                    )}
                    {role.workType === "TEMP" && (
                      <>
                        <strong>Placement end date:</strong>{" "}
                        {new Date(role?.placementEndDate).toLocaleDateString()}
                        <br />
                      </>
                    )}
                  </Col>
                  <Col>
                    <strong>Engagement type:</strong> {role?.engagementType}<br />
                    <strong>Terms signed:</strong> {role?.termsSigned ? "Yes" : "No"}<br />
                    <strong>Replacement guarantee:</strong> {role?.replacementGuarantee ? "Yes" : "No"}<br />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>{parse(role?.summary)}</Col>
                </Row>
                <hr />
                {match && (statusRole === "AGENT_CONFIRMED" || statusRole === "SUCCESSFUL_PLACEMENT") &&
                  (match?.candidateAgentStatus === "AGENT_CONFIRMED" || match?.candidateAgentStatus === "SUCCESSFUL_PLACEMENT") && (
                    <p>
                      <br />
                      {match?.candidateAgentName}
                      <br />
                      {match?.candidateAgentEmail}
                      <br />
                      {match?.candidateAgentPhone}
                    </p>
                )}
                {match && match?.candidateAgentStatus === "SUCCESSFUL_PLACEMENT" && (
                  <p>
                    <strong>Claimed expected fee:</strong>{" "}
                    {match?.candidateAgentExpectedFee}<br/>
                    <strong>Claimed expected start date:</strong>{" "}
                    {match?.candidateAgentExpectedStartDate && new Date(match?.candidateAgentExpectedStartDate).toLocaleDateString()}<br/>
                    <strong>Claimed expected schedule:</strong>{" "}
                    {match?.candidateAgentExpectedSchedule}
                  </p>
                )}
              </Card.Body>
            )}
            <br />
            <Button size="sm" onClick={() => setShowQuery(true)}>
              <EnvelopeFill /> Agent Query
            </Button>
            <hr />
            {match && (
              <MatchFooter
                type="role"
                match={match}
                setParentStatus={setStatusRole}
                agency={role?.companyName}
              />
            )}
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default MatchesDetailModal;
