import { useEffect, useState } from "react";
import { ButtonRightCorner } from "../../../sharedStyles";
import { regions } from "../../../const";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_PROFILE } from "../../../GraphQL/settings/mutations";
import { GET_INDUSTRIES, GET_LOCALITIES } from "../../../GraphQL/settings/queries";
import { Button } from "react-bootstrap";
import { EToastColorStatus, EToastPosition, IProfile } from "../../../interfaces";
import Notification from "../../Notification";
import Select from 'react-select';

const list_region = regions.map(item => ({value: item, label: item}));

const IndustrySettings = ({ profile }: IProfile ) => {
  const { error, loading, data } = useQuery(GET_INDUSTRIES, {
    onCompleted: (data) => setIndustryTypes(data?.industries),
  });

  const {
    error: errorLocalities,
    loading: loadingLocalities,
    data: dataLocalities,
  } = useQuery(GET_LOCALITIES);

  useEffect(() => {
    setLocalities(dataLocalities?.localities?.items)
  }, [dataLocalities]);

  let initRegion = [];
  for (let i in profile?.industryPreferences?.regions){
    const x = profile?.industryPreferences?.regions[i];
    initRegion.push({value: x, label: x});
  }

  let initIndustry = [];
  for (let i in profile?.industryPreferences?.industries) {
    const x = profile?.industryPreferences?.industries[i];
    initIndustry.push({value: x, label: x});
  }

  const [showToast, setShowToast] = useState(false);
  const [regionValues, setRegionValues] = useState(initRegion);
  const [industryValues, setIndustryValues] = useState(initIndustry);
  const [industryTypes, setIndustryTypes] = useState([]);
  const listOfIndustries = industryTypes.map(item => ({value: item, label: item}));
  const [localities, setLocalities] = useState([]);

  let listOfLocalities: any[] = [];
  for (let i in localities) {
    listOfLocalities.push({value: localities[i]['id'], label: localities[i]['name']});
  }

  const [localityValues, setLocalityValues] = useState<any[]>([]);

  useEffect(() => {
    let initLocalities = [];
    for (let i in profile?.industryPreferences?.localityIds) {
      for (let j in listOfLocalities) {
        if (profile?.industryPreferences?.localityIds[i] === listOfLocalities[j]['value']) {
          initLocalities.push({value: listOfLocalities[j]['value'], label: listOfLocalities[j]['label']});
        }
      }
    }
    setLocalityValues(initLocalities);
  }, [profile, localities]);

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onCompleted: () => {
      setShowToast(true);
    },
  });

  const handleSaveIndustrySettings = () => {
    let regionData: string[] = [];
    for (let i in regionValues) regionData.push(regionValues[i]['value']);
    let industryData: string[] = [];
    for (let i in industryValues) industryData.push(industryValues[i]['value']);
    let localityData: number[] = [];
    for (let i in localityValues) localityData.push(localityValues[i]['value']);

    updateProfile({
      variables: {
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        phoneNumber: profile?.phoneNumber,
        industryPreferences: {
          regions: regionData,
          industries: industryData,
          localityIds: localityData,
        },
      },
    });
  }

  return (
    <>
      <Notification
        delay={3000}
        toastData={{show: showToast, color: EToastColorStatus.SUCCESS, position: EToastPosition.BOTTOM_END, message: "Success Update Industry Settings"}}
        onClose={() => setShowToast(false)}
      />
      <div className="my-3">
        <p>Region</p>
        <Select
          onChange={(data: any) => setRegionValues(data)}
          name="regions"
          options={list_region}
          isMulti={true}
          value={regionValues}
        />
      </div>
      <div className="my-3">
        <p>Industry</p>
        {error && <p>Error: {error?.message}</p>}
        {loading && <p>Loading...</p>}
        {!error && !loading && data &&
          <Select
            onChange={(data: any) => setIndustryValues(data)}
            name="industries"
            options={listOfIndustries}
            isMulti={true}
            value={industryValues}
          />
        }
      </div>
      <div className="my-3">
        <p>Localities</p>
        {!errorLocalities && !loadingLocalities && dataLocalities &&
          <Select
            onChange={(data: any) => setLocalityValues(data)}
            name="localities"
            options={listOfLocalities}
            isMulti={true}
            value={localityValues}
          />
        }
      </div>
      <ButtonRightCorner>
        <Button size="sm" variant="primary" onClick={handleSaveIndustrySettings}>
          Save
        </Button>
      </ButtonRightCorner>
    </>
  )
}

export default IndustrySettings;
