import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { SendFill } from "react-bootstrap-icons";
import { useMutation } from "@apollo/client";
import {
  CREATE_CANDIDATE_TOPIC,
  CREATE_JOB_TOPIC,
} from "../../GraphQL/messages/mutations";
interface Props {
  show: boolean;
  hide: () => void;
  jobId?: number;
  candidateId?: number;
}
const QueryModal = ({ show, hide, jobId, candidateId }: Props) => {
  const [createTopic] = useMutation(
    jobId ? CREATE_JOB_TOPIC : CREATE_CANDIDATE_TOPIC
  );

  const [query, setQuery] = React.useState<string>("");

  const handleSend = async () => {
    if (jobId) {
      try {
        await createTopic({ variables: { jobId: jobId, message: query } });
        hide();
        alert("Your query has been sent to the client agent");
      } catch (err) {
        alert("Something went wrong, please try again soon");
        hide();
      }
    } else if (candidateId) {
      try {
        await createTopic({
          variables: { candidateId: candidateId, message: query },
        });
        alert("Your query has been sent to the candidate agent");
        hide();
      } catch (err) {
        alert("Something went wrong, please try again soon");
        hide();
      }
    }
  };

  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>{"Agent query"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          as="textarea"
          name="description"
          rows={5}
          placeholder="Enter query ..."
          onChange={(e) => setQuery(e.target.value)}
          required
        />
        <hr />
        Queries will be anonymous. No information to identify you or your agency
        will be exchanged when you submit a query.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hide} variant="secondary">
          Cancel
        </Button>
        <Button onClick={handleSend} variant="primary">
          <SendFill /> Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QueryModal;
