import { gql } from "@apollo/client";

export const GET_MATCHES_FOR_CANDIDATE = gql`
  query GetMatchesForCandidate($id: Int!) {
    matches(candidateId: $id) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        jobId
        candidateId
        feedback
        jobAgentStatus
        jobAgentExpectedFee
        jobAgentName
        jobAgentEmail
        jobAgentPhone
        jobAgentExpectedStartDate
        jobAgentExpectedSchedule
        jobAgentSettlementFee
        jobAgentSettlementSchedule
        candidateAgentStatus
        candidateAgentExpectedFee
        candidateAgentName
        candidateAgentEmail
        candidateAgentPhone
        candidateAgentExpectedStartDate
        candidateAgentExpectedSchedule
        candidateAgentSettlementFee
        candidateAgentSettlementSchedule
      }
    }
  }
`;

export const GET_MATCHES_FOR_ROLE = gql`
  query GetMatchesForRole($id: Int!) {
    matches(jobId: $id) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        jobId
        candidateId
        feedback
        jobAgentStatus
        jobAgentExpectedFee
        jobAgentName
        jobAgentEmail
        jobAgentPhone
        jobAgentExpectedStartDate
        jobAgentExpectedSchedule
        jobAgentSettlementFee
        jobAgentSettlementSchedule
        candidateAgentStatus
        candidateAgentExpectedFee
        candidateAgentName
        candidateAgentEmail
        candidateAgentPhone
        candidateAgentExpectedStartDate
        candidateAgentExpectedSchedule
        candidateAgentSettlementFee
        candidateAgentSettlementSchedule
    }
  }
}
`;

export const GET_JOB_MATCHES = gql`
  query JobMatches($order: [JobMatchSortInput!]) {
    jobMatches(order: $order) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        jobId
        clientName
        jobTitle
        candidateJobTitle
        candidateIdealJobTitles
        jobAgentStatus
        candidateAgentStatus
        currency
        createdAt
        updatedAt
        candidateLocation {
          localityId
          displayName
        }
        jobLocation {
          countryCode
          suburb
          state
          postCode
          displayName
          localityId
        }
      }
    }
  }
`;

export const GET_CANDIDATE_MATCHES = gql`
  query CandidateMatches($order: [CandidateMatchSortInput!]) {
    candidateMatches(order: $order) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        candidateId
        candidateName
        jobTitle
        candidateJobTitle
        candidateIdealJobTitles
        jobAgentStatus
        candidateAgentStatus
        currency
        createdAt
        updatedAt
        jobLocation {
          countryCode
          suburb
          state
          postCode
          displayName
          localityId
        }
        candidateLocation {
          localityId
          displayName
        }
      }
    }
  }
`;

export const GET_MATCH = gql`
  query Match($id: String!) {
    match(id: $id) {
      id
      jobId
      candidateId
      jobAgentStatus
      candidateAgentStatus
      feedback
      jobAgentExpectedFee
      jobAgentName
      jobAgentEmail
      jobAgentPhone
      jobAgentExpectedStartDate
      jobAgentExpectedSchedule
      jobAgentSettlementFee
      jobAgentSettlementSchedule
      candidateAgentExpectedFee
      candidateAgentName
      candidateAgentEmail
      candidateAgentPhone
      candidateAgentExpectedStartDate
      candidateAgentExpectedSchedule
      candidateAgentSettlementFee
      candidateAgentSettlementSchedule
      currency
    }
  }
`;
