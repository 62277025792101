import React, { useState } from "react";
import { useMutation } from '@apollo/client';
import { Modal, Button, Form } from "react-bootstrap";
import { INVITE_PARTNER } from '../../GraphQL/settings/mutations';

interface Props {
  show: boolean;
  onHide: () => void;
}

const InvitePartnerModal = ({
 show, onHide
}: Props) => {
  const [email, setEmail] = useState<string>("");
  const [invitePartner] = useMutation(INVITE_PARTNER);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }

  const handleSubmit = async () =>  {
    const valid = email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    if(!valid){
      alert("Please enter a valid email address");
      return;
    }

    try {
      await invitePartner({ variables: { email: email } });
      alert("Partner invited successfully");
      onHide();
    }catch(err){
      console.log(err);
      alert("Something went wrong, please try again soon");
      onHide();
    }
  }


  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Invite a partner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Enter the email address of someone you would like to partner with.
          Agencies will be able to see roles and candidates that have been
          specifically shared with their partners.
        </p>
        <Form.Group controlId="formClient">
          <Form.Label>Email*</Form.Label>{" "}
          <Form.Control type="email" onChange={handleEmailChange} required />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} variant="primary">
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvitePartnerModal;
