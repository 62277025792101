import styled, {css} from "styled-components";
import { Navbar, Nav, Badge, NavDropdown } from "react-bootstrap";

// const StyledNavbar = styled(Navbar)`
//   background-image: linear-gradient(to right, #227b7e, #073948);
// `;

const StyledNavbar = styled(Navbar)`
   background-color: #227b7e;
  //  border-bottom: 5px solid #222;
   box-shadow: 0px 2px 5px #888;
   margin-bottom: 20px;
`;


const Logo = styled.img`
  margin-left: 16px;
  padding-right: 16px;
  border-right: 2px solid #227b7e;

  @media (min-width: 1100px) {
    padding-right: 32px;
  }
`;

const BetaBadge = styled(Badge)`
  background-color: white !important;
  color: #227b7e;
  margin-left: 5px;
  margin-right: 10px;
  opacity: 0.5;
`;

const StyledNavLink = styled(Nav.Link)`
  font-size: 1rem;
  color: white !important;
  font-weight: bold;
  margin: 0px 7px;
  padding: 10px;
  border-radius: 4px;
  white-space: nowrap;

  &.active {
    background-color: #2fadb1;
  }

  & svg {
    margin-bottom: 3px;
    margin-right: 3px;
  }


  @media (max-width: 991px) {
    font-size: 1rem;
    padding: 12px 25px;
    margin: 0 -10px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 0px;

    ${(props) => props.firstnav &&
    css`
      &:first-child {
        margin-top: 10px;
      }
    `}
  }

  @media (min-width: 991px) and (max-width: 1280px) {
    font-size: 0.9rem;
    padding: 12px;
    & > svg {
      display: none;
    }

  }
  


`;

const StyledToggle = styled(Navbar.Toggle)`
 border: none;
 margin-right: 5px;
 & focus, active {
    border: none;
 }

`

const Hamburger = styled.div`
  & div {
    width: 28px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
  }
`;

const StyledNavDropdown = styled(NavDropdown)`
  align-self: flex-end !important;
`;

const NotificationBadge = styled(Badge)`
  position: relative;
  top: -8px;
  right: 0;
`;

export {
  StyledNavbar,
  StyledNavLink,
  StyledToggle,
  Hamburger,
  Logo,
  BetaBadge,
  StyledNavDropdown,
  NotificationBadge,
};
