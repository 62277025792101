import { useEffect, useRef, useState } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { stateToHTML } from 'draft-js-export-html';
import Toolbar from "./Toolbar";
import styled from "styled-components";

const EditorWrapper = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  padding: 0;
`;

const EditorContainer = styled.div`
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 1rem;
`;

interface IDrafEditorProps {
  formik: any;
}

const DraftEditor = ({ formik }: IDrafEditorProps) => {
  const contentSummary = formik?.values?.summary;
  const blocksFromHTML = convertFromHTML(contentSummary);
  const dataContentEditor = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );

  const [inputSummary, setInputSummary] = useState<string>(contentSummary);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(dataContentEditor));

  useEffect(() => {
    const initContentEditor = ContentState.createFromBlockArray(
      convertFromHTML(contentSummary).contentBlocks,
      convertFromHTML(contentSummary).entityMap,
    );
    setEditorState(EditorState.createWithContent(initContentEditor));
  }, [formik?.values?.id]);

  const editor: any = useRef<HTMLInputElement>(null);

  useEffect(() => {
    focusEditor();
  }, []);

  const focusEditor = () => {
    editor?.current?.focus();
  };

  const handleKeyCommand = (command: any): any => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return true;
    }
    return false;
  };


  const styleMap: any = {
    CODE: {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
    HIGHLIGHT: {
      backgroundColor: "#F7A5F7",
    },
    UPPERCASE: {
      textTransform: "uppercase",
    },
    LOWERCASE: {
      textTransform: "lowercase",
    },
    CODEBLOCK: {
      fontFamily: "monospace",
      fontSize: "inherit",
      background: "#ffeff0",
      fontStyle: "italic",
      lineHeight: 1.5,
      padding: "0.3rem 0.5rem",
      borderRadius: " 0.2rem",
    },
    SUPERSCRIPT: {
      verticalAlign: "super",
      fontSize: "80%",
    },
    SUBSCRIPT: {
      verticalAlign: "sub",
      fontSize: "80%",
    },
  };

  const customBlockStyleFn = (contentBlock: any): any => {
    const type = contentBlock.getType();
    switch (type) {
      case "blockQuote":
        return "editor-fancy-blockquote";
      case "leftAlign":
        return "editor-left-align";
      case "rightAlign":
        return "editor-right-align";
      case "centerAlign":
        return "editor-center-align";
      case "justifyAlign":
        return "editor-justify-align";
      default:
        break;
    }
  };

  return (
    <EditorWrapper onClick={focusEditor}>
      <Toolbar editorState={editorState} setEditorState={setEditorState} />
      <EditorContainer>
        <Editor
          ref={editor}
          placeholder="Write your summary here..."
          handleKeyCommand={handleKeyCommand}
          editorState={editorState}
          customStyleMap={styleMap}
          blockStyleFn={customBlockStyleFn}
          onBlur={() => formik.setFieldValue("summary", inputSummary)}
          onChange={(editorState) => {
            const contentState = editorState.getCurrentContent();
            const contentData = stateToHTML(contentState);
            setEditorState(editorState);
            setInputSummary(contentData);
          }}
        />
      </EditorContainer>
    </EditorWrapper>
  );
};

export default DraftEditor;
