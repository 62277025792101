import React, { useState, useEffect, useContext, useRef } from "react";
import { useQuery, useApolloClient, useMutation } from "@apollo/client";
import styled, { css } from "styled-components";
import {
  Card,
  Container as BootstrapContainer,
  Table,
  Badge,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import RoleDetailModal from "./RoleDetailModal";
import RoleDeleteModal from "./RoleDeleteModal";
import {
  DesktopContainer,
  MobileContainer,
  PaginationContainer,
  TabCardContainer,
} from "../../sharedStyles";
import { GET_ROLES, GET_IMPORTED_JOBS, GET_ROLE_BY_ID } from "../../GraphQL/roles/queries";
import { GET_LOCALITIES } from "../../GraphQL/settings/queries";
import { IMPORT_ROLE } from "../../GraphQL/roles/mutations";
import { Job, JobData, LocalityData } from "../../interfaces";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useParams, useNavigate } from "react-router-dom";
import { BoxArrowDown, PencilSquare, TrashFill } from "react-bootstrap-icons";
import { ProfileContext } from "../../App";
import ChevronTableHeader from "../Icons/ChevronTableHeader";
import RolesMobileView from "./RolesMobileView";
import CustomPagination, {
  CustomPaginationType,
} from "../Global/CustomPagination";
import Notification from "../Notification";
import {
  EToastPosition,
  EToastColorStatus,
  IToastData,
} from "../../interfaces";
import config from "../../config";
import guide from "../../assets/role_relay_user_guide.pdf";

const TR = styled.tr`
  cursor: pointer;
  & td {
    vertical-align: middle;
  }
`;

const RolesHeaderTitle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const Container = styled(BootstrapContainer)`
  max-width: 1920px;
`;

const StatusPill = styled(Badge)`
  background-color: #888888 !important;
  ${(props) =>
    props.status === "PLATFORM_INCLUDED" &&
    css`
      background-color: #f5a623 !important;
    `}
  ${(props) =>
    props.status === "SHARED" &&
    css`
      background-color: rgb(0, 176, 0) !important;
    `}
`;

const AudiencePill = styled(Badge)`
  background-color: #888888 !important;
  ${(props) =>
    props.audience === "PUBLIC" &&
    css`
      background-color: rgb(0, 176, 0) !important;
    `}
  ${(props) =>
    props.audience === "PARTNER" &&
    css`
      background-color: rgb(0, 112, 0) !important;
    `}
`;

const statusLabels = {
  PLATFORM_INCLUDED: "Pending Opt-in",
  SHARED: "Shared",
  STOPPED_SHARING: "Draft",
  REMOVED: "Removed",
  UNKNOWN: "Unknown",
};

interface Props {
  user: any;
}

const Roles = ({ user }: Props) => {
  const navigate = useNavigate();
  let atsServer = "au1";
  if (
    user?.username?.includes("firefly") ||
    user?.username?.includes("worktrybe")
  )
    atsServer = "au3";
  if (user?.username?.includes("lloyd") || user?.username?.includes("candidex"))
    atsServer = "au5";

  const profile = useContext(ProfileContext);
  const myRolesPaginationRef = useRef<CustomPaginationType>(null);
  const showImportedFlag = useFeatureFlagEnabled(config?.postHog?.featureFlags?.showImported);
  const [selectedRole, setSelectedRole] = useState<Job | null>(null);
  const [showRoleDetailModal, setShowRoleDetailModal] = useState(false);
  const [showRoleDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [nonATS, setNonATS] = useState(profile.connections?.totalCount === 0);
  const [orderType, setOrderType] = useState<string>("");
  const [activeTab, setActiveTab] = useState("roles");
  const [selectMyRoleId, setSelectMyRoleId] = useState("");
  const [orderSortInput, setOrderSortInput] = useState<any[]>([
    { updatedAt: "DESC" },
  ]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [toastData, setToastData] = useState<IToastData>({
    show: false,
    color: EToastColorStatus.SUCCESS,
    position: EToastPosition.BOTTOM_END,
    message: "",
  });

  useEffect(() => {
    setNonATS(profile.connections?.totalCount === 0);
  }, [profile]);

  const { loading, error, data } = useQuery<JobData>(GET_ROLES, {
    variables: { skip: 0, take: 25, order: orderSortInput },
    onCompleted: (data) => {
      setRolesData(data?.jobs?.items);
      setHasNextPage(data?.jobs?.pageInfo?.hasNextPage);
    },
  });

  const { data: dataLocalities } = useQuery<LocalityData>(GET_LOCALITIES);
  const localities = dataLocalities?.localities?.items ? [...dataLocalities?.localities?.items] : [];
  const locationName = (x: string): string[] => {
    return localities.map((locality) => x === locality?.name ? `${locality?.name} (${locality?.state})` : "")
  }
  
  const {
    loading : importedJobsLoading,
    error   : importedJobsError,
    data    : importedJobsData,
  } = useQuery(GET_IMPORTED_JOBS);

  const { data: dataByRoleId } = useQuery(GET_ROLE_BY_ID, {
    variables: { id: selectMyRoleId },
  });

  const [importRole] = useMutation(IMPORT_ROLE, {
    onCompleted(dataImported){
      setActiveTab("roles");
      navigate(`/my-roles/${dataImported?.importJob?.id}`);
      setSelectMyRoleId(dataImported?.importJob?.id);
      setSelectedRole(dataByRoleId?.jobs?.items[0]);
      setShowRoleDetailModal(true);
    },
    onError(error: any) {
      const errorMessage = error?.networkError?.result?.errors[0]?.message;
      setToastData({
        show: true,
        color: EToastColorStatus.FAILED,
        position: EToastPosition.BOTTOM_END,
        message: `Failed, ${errorMessage}`,
      });
    },
  });

  useEffect(() => {
    setSelectedRole(dataByRoleId?.jobs?.items[0]);
  }, [dataByRoleId]);

  const client = useApolloClient();
  const refreshData = () =>
    client.refetchQueries({
      include: [GET_ROLES],
    });

  const [rolesData, setRolesData] = useState<Job[]>(data?.jobs?.items || []);

  const runPagination = (skip = 0, take = 25) => {
    const getRolesData = async () => {
      const { data } = await client.query({
        query: GET_ROLES,
        variables: { skip, take, order: orderSortInput },
      });
      setRolesData(data?.jobs?.items);
      myRolesPaginationRef.current?.setPagination({
        ...data?.jobs?.pageInfo,
        skip,
        take,
        totalCount: data?.jobs?.totalCount,
      });
    };
    getRolesData();
  };

  /**
   * Logic for deeplinked roles
   */
  const [routeRoleId, setRouteRoleId] = useState<number | null>(null);
  const { roleId, matchId } = useParams<{ roleId: string; matchId: string }>();

  const [modalKey, setModalKey] = useState(0);

  const handleNewRole = () => {
    setModalKey(modalKey + 1);
    setSelectedRole(null);
    setShowRoleDetailModal(true);
  };

  useEffect(() => {
    roleId ? setRouteRoleId(Number.parseInt(roleId)) : setRouteRoleId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    runPagination();
  }, [data?.jobs?.items]);

  useEffect(() => {
    setRolesData(rolesData);
  }, [rolesData]);

  if (routeRoleId !== null && !loading && !showRoleDetailModal) {
    const role = rolesData.find((role) => role.id === routeRoleId);
    if (role) {
      setSelectedRole(role);
      setShowRoleDetailModal(true);
    }
  }

  //TODO: RR-10
  // if (loading || error) return null;

  const handleSorting = (tableTitle: string) => {
    if (tableTitle === "") return;
    if (
      orderType === "DESC" &&
      tableTitle === Object.keys(orderSortInput[0])[0]
    ) {
      setOrderSortInput([
        tableTitle === "location"
          ? { location: { displayName: "DESC" } }
          : { [tableTitle]: "DESC" },
        { updatedAt: "DESC" },
      ]);
      setOrderType("ASC");
    } else {
      setOrderSortInput([
        tableTitle === "location"
          ? { location: { displayName: "ASC" } }
          : { [tableTitle]: "ASC" },
        { updatedAt: "DESC" },
      ]);
      setOrderType("DESC");
    }
  };

  const handleShowModalDelete = (id: number) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const handleRoleDetails = (data: Job) => {
    navigate(`/my-roles/${data?.id}`);
    setSelectedRole(data);
    setShowRoleDetailModal(true);
  };

  const handleImportRoleModal = (data: Job) => {
    const string = data?.externalId;
    const roleId = string.replace(/\D/g, "");
    importRole({
      variables: {
        jobId: Number(roleId),
      },
    });
  }

  const onHandleActiveTab = (activeTab: string | null) => {
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }

  const handleShowToast = (
    color: EToastColorStatus,
    position: EToastPosition,
    message: string
  ) => {
    setToastData({ show: true, color, position, message });
  };

  return (
    <Container>
      <RoleDetailModal
        key={modalKey}
        isNonATS={nonATS}
        show={showRoleDetailModal}
        role={selectedRole}
        reloadRoles={refreshData}
        onHide={() => {
          window.history.replaceState(null, "", `/my-roles/`);
          setRouteRoleId(null);
          setShowRoleDetailModal(false);
        }}
        atsServer={atsServer}
        matchId={matchId}
        onCreateToast={(
          color: EToastColorStatus,
          position: EToastPosition,
          message: string
        ) => handleShowToast(color, position, message)}
        onShowDelete={(data: boolean) => setShowDeleteModal(data)}
        onSetDeleteId={(data: number) => setDeleteId(data)}
      />
      <RoleDeleteModal
        id={deleteId}
        show={showRoleDeleteModal}
        onHideModal={() => setShowDeleteModal(false)}
        reloadRoles={refreshData}
        onCreateToast={(
          color: EToastColorStatus,
          position: EToastPosition,
          message: string
        ) => handleShowToast(color, position, message)}
      />
      <Tabs activeKey={activeTab} onSelect={onHandleActiveTab}>
        <Tab eventKey="roles" title="Roles">
          <TabCardContainer>
            <Card.Body>
              {loading && <div style={{ marginBottom: "8px" }}>Loading...</div>}
              {!loading && error && (
                <div style={{ marginBottom: "8px" }}>
                  Unable to load roles at this time - please try again soon
                </div>
              )}
              <DesktopContainer>
                <Table striped bordered hover hidden={loading}>
                  <thead>
                    <tr>
                      <th onClick={() => handleSorting("clientName")}>
                        <RolesHeaderTitle>
                          <div>Client</div>
                          <div>
                            {orderSortInput[0]?.clientName && (
                              <ChevronTableHeader
                                ascending={orderSortInput[0]?.clientName === "ASC"}
                              />
                            )}
                          </div>
                        </RolesHeaderTitle>
                      </th>
                      <th onClick={() => handleSorting("title")}>
                        <RolesHeaderTitle>
                          <div>Job Title</div>
                          <div>
                            {orderSortInput[0]?.title && (
                              <ChevronTableHeader
                                ascending={orderSortInput[0]?.title === "ASC"}
                              />
                            )}
                          </div>
                        </RolesHeaderTitle>
                      </th>
                      <th onClick={() => handleSorting("industry")}>
                        <RolesHeaderTitle>
                          <div>Industry</div>
                          <div>
                            {orderSortInput[0]?.industry && (
                              <ChevronTableHeader
                                ascending={orderSortInput[0]?.industry === "ASC"}
                              />
                            )}
                          </div>
                        </RolesHeaderTitle>
                      </th>
                      <th onClick={() => handleSorting("location")}>
                        <RolesHeaderTitle>
                          <div>Location</div>
                          <div>
                            {orderSortInput[0]?.location?.displayName && (
                              <ChevronTableHeader
                                ascending={
                                  orderSortInput[0]?.location?.displayName === "ASC"
                                }
                              />
                            )}
                          </div>
                        </RolesHeaderTitle>
                      </th>
                      <th
                        className="text-center"
                        onClick={() => handleSorting("workType")}
                      >
                        {!orderSortInput[0]?.workType ? (
                          "Type"
                        ) : (
                          <RolesHeaderTitle>
                            <div>Type</div>
                            <div>
                              {orderSortInput[0]?.workType && (
                                <ChevronTableHeader
                                  ascending={orderSortInput[0]?.workType === "ASC"}
                                />
                              )}
                            </div>
                          </RolesHeaderTitle>
                        )}
                      </th>
                      <th
                        className="text-center"
                        onClick={() => handleSorting("audience")}
                      >
                        {!orderSortInput[0]?.audience ? (
                          "Audience"
                        ) : (
                          <RolesHeaderTitle>
                            <div>Audience</div>
                            <div>
                              {orderSortInput[0]?.audience && (
                                <ChevronTableHeader
                                  ascending={orderSortInput[0]?.audience === "ASC"}
                                />
                              )}
                            </div>
                          </RolesHeaderTitle>
                        )}
                      </th>
                      <th
                        className="text-center"
                        onClick={() => handleSorting("matches")}
                      >
                        {!orderSortInput[0]?.matches ? (
                          "Matches"
                        ) : (
                          <RolesHeaderTitle>
                            <div>Matches</div>
                            <div>
                              {orderSortInput[0]?.matches && (
                                <ChevronTableHeader
                                  ascending={orderSortInput[0]?.matches === "ASC"}
                                />
                              )}
                            </div>
                          </RolesHeaderTitle>
                        )}
                      </th>
                      <th
                        className="text-center"
                        onClick={() => handleSorting("status")}
                      >
                        {!orderSortInput[0]?.status ? (
                          "Status"
                        ) : (
                          <RolesHeaderTitle>
                            <div>Status</div>
                            <div>
                              {orderSortInput[0]?.status && (
                                <ChevronTableHeader
                                  ascending={orderSortInput[0]?.status === "ASC"}
                                />
                              )}
                            </div>
                          </RolesHeaderTitle>
                        )}
                      </th>
                      <th className="text-center" style={{ minWidth: "85px" }}>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rolesData.map((job) => (
                      <TR key={job.id}>
                        <td onClick={() => handleRoleDetails(job)}>
                          {job.clientName}
                        </td>
                        <td onClick={() => handleRoleDetails(job)}>{job.title}</td>
                        <td onClick={() => handleRoleDetails(job)}>
                          {job.industry || "-"}
                        </td>
                        <td onClick={() => handleRoleDetails(job)}>
                          {locationName(job?.location?.displayName)}
                        </td>
                        <td align="center" onClick={() => handleRoleDetails(job)}>
                          <Badge bg="secondary">
                            {job.workType
                              ? job.workType === "TEMP"
                                ? "CONTRACT/TEMP"
                                : "PERMANENT"
                              : "-"}
                          </Badge>
                        </td>
                        <td align="center" onClick={() => handleRoleDetails(job)}>
                          {" "}
                          <AudiencePill audience={job.audience}>
                            {job.audience}
                          </AudiencePill>
                        </td>
                        <td align="center" onClick={() => handleRoleDetails(job)}>
                          <Badge bg={job.matches ? "primary" : "secondary"} pill>
                            {job.matches}
                          </Badge>
                        </td>
                        <td align="center" onClick={() => handleRoleDetails(job)}>
                          <StatusPill status={job.status}>
                            {statusLabels[job.status] as string}
                          </StatusPill>
                        </td>
                        <td align="center">
                          <Button
                            size="sm"
                            variant="secondary"
                            onClick={() => handleRoleDetails(job)}
                          >
                            <PencilSquare />
                          </Button>{" "}
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => handleShowModalDelete(job?.id)}
                          >
                            <TrashFill />
                          </Button>
                        </td>
                      </TR>
                    ))}
                  </tbody>
                </Table>
              </DesktopContainer>
              <MobileContainer>
                <RolesMobileView
                  onShowRoleModal={() => setShowRoleDetailModal(true)}
                  onSelectRole={(data) => setSelectedRole(data)}
                  onSortingRolesData={(data) => handleSorting(data)}
                  rolesData={rolesData}
                  locationName={locationName}
                  handleShowModalDelete={handleShowModalDelete}
                />
              </MobileContainer>
              {!loading && !rolesData.length && (
                <p>
                  You have no roles included at this time. Keep an eye on your inbox
                  as we will send you an email when we detect potential candidates
                  for your roles.
                </p>
              )}
              <PaginationContainer>
                <p>
                  <Button onClick={handleNewRole}>Add Role</Button>
                </p>
                {hasNextPage && (
                  <CustomPagination
                    ref={myRolesPaginationRef}
                    onPaginationChange={(args) =>
                      runPagination(args.skip, args.take)
                    }
                  />
                )}
              </PaginationContainer>
              <p>
                <a href={guide} target="_blank" rel="noreferrer">
                  Click here to view the User Guide.
                </a>
              </p>
            </Card.Body>
          </TabCardContainer>
        </Tab>
        {showImportedFlag && (
          <Tab eventKey="imported-roles" title="Imported Roles">
            <TabCardContainer>
              <Card.Body>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>Job Title</th>
                      <th>Industry</th>
                      <th>Location</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {importedJobsLoading && <tr><td>Loading...</td></tr>}
                    {importedJobsError && <tr><td>Error</td></tr>}
                    {importedJobsData && importedJobsData?.importedJobs?.items.map((job: Job) => (
                      <TR key={job?.id}>
                        <td>{job?.clientName}</td>
                        <td>{job?.title}</td>
                        <td>{job?.industry ?? "-"}</td>
                        <td>{locationName(job?.location?.displayName) ?? "-"}</td>
                        <td align="center">
                          <Button
                            size="sm"
                            variant="secondary"
                            onClick={() => handleImportRoleModal(job)}
                          >
                            <BoxArrowDown />
                          </Button>{" "}
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => handleShowModalDelete(job?.id)}
                          >
                            <TrashFill />
                          </Button>
                        </td>
                      </TR>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </TabCardContainer>
          </Tab>
        )}
      </Tabs>
      <Notification
        delay={3000}
        toastData={toastData}
        onClose={() => setToastData({ ...toastData, show: false })}
      />
    </Container>
  );
};

export default Roles;
