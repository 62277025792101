import React, { useEffect, useState, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import {
  Card,
  Container as BootstrapContainer,
  Table,
  Badge,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import {
  DesktopContainer,
  MobileContainer,
  PaginationContainer,
  TabCardContainer,
} from "../../sharedStyles";
import CandidateDetailModal from "./CandidateDetailModal";
import CandidateDeleteModal from "./CandidateDeleteModal";
import { useQuery, useApolloClient, useMutation } from "@apollo/client";
import { GET_CANDIDATES, GET_CANDIDATE_BY_ID, GET_IMPORTED_CANDIDATES } from "../../GraphQL/candidates/queries";
import { IMPORT_CANDIDATE } from "../../GraphQL/candidates/mutations";
import { GET_LOCALITIES } from "../../GraphQL/settings/queries";
import { Candidate, CandidateData, LocalityData } from "../../interfaces";
import { useParams, useNavigate } from "react-router-dom";
import { BoxArrowDown, PencilSquare, TrashFill } from "react-bootstrap-icons";
import ChevronTableHeader from "../Icons/ChevronTableHeader";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { ProfileContext } from "../../App";
import CandidatesMobileView from "./CandidatesMobileView";
import CustomPagination, {
  CustomPaginationType,
} from "../Global/CustomPagination";
import Notification from "../Notification";
import {
  EToastPosition,
  EToastColorStatus,
  IToastData,
} from "../../interfaces";
import config from "../../config";
import guide from "../../assets/role_relay_user_guide.pdf";

const TR = styled.tr`
  cursor: pointer;
  & td {
    vertical-align: middle;
  }
`;

const CandidateTitle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const Container = styled(BootstrapContainer)`
  max-width: 1920px;
`;

const StatusPill = styled(Badge)`
  background-color: #888888 !important;
  ${(props) =>
    props.status === "PLATFORM_INCLUDED" &&
    css`
      background-color: #f5a623 !important;
    `}
  ${(props) =>
    props.status === "SHARED" &&
    css`
      background-color: rgb(0, 176, 0) !important;
    `}
`;

const AudiencePill = styled(Badge)`
  background-color: #888888 !important;
  ${(props) =>
    props.audience === "PUBLIC" &&
    css`
      background-color: rgb(0, 176, 0) !important;
    `}
  ${(props) =>
    props.audience === "PARTNER" &&
    css`
      background-color: rgb(0, 112, 0) !important;
    `}
`;

const statusLabels = {
  PLATFORM_INCLUDED: "Pending Opt-in",
  SHARED: "Shared for Collaboration",
  STOPPED_SHARING: "Draft",
  REMOVED: "Removed",
  UNKNOWN: "Unknown",
};

interface Props {
  user: any;
}

const Candidates = ({ user }: Props) => {
  const navigate = useNavigate();
  let atsServer = "au1";
  if (
    user?.username?.includes("firefly") ||
    user?.username?.includes("worktrybe")
  )
    atsServer = "au3";

  if (user?.username?.includes("lloyd") || user?.username?.includes("candidex"))
    atsServer = "au5";

  const profile = useContext(ProfileContext);
  const showImportedFlag = useFeatureFlagEnabled(config?.postHog?.featureFlags?.showImported);
  const myCandidatesPaginationRef = useRef<CustomPaginationType>(null);
  const [nonATS, setNonATS] = useState(profile.connections?.totalCount === 0);

  useEffect(() => {
    setNonATS(profile.connections?.totalCount === 0);
  }, [profile]);

  const [showCandidateDetailModal, setShowCandidateModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate | null>(
    null
  );
  const [showCandidateDelete, setShowCandidateDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [orderType, setOrderType] = useState<string>("");
  const [activeTab, setActiveTab] = useState("candidates");
  const [selectMyCandidateId, setSelectMyCandidateId] = useState("");
  const [orderSortInput, setOrderSortInput] = useState<any[]>([
    { updatedAt: "DESC" },
  ]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [toastData, setToastData] = useState<IToastData>({
    show: false,
    color: EToastColorStatus.SUCCESS,
    position: EToastPosition.BOTTOM_END,
    message: "",
  });

  const { loading, error, data } = useQuery<CandidateData>(GET_CANDIDATES, {
    variables: { skip: 0, take: 25, order: orderSortInput },
    onCompleted: (data) => {
      setCandidatesData(data?.candidates?.items);
      setHasNextPage(data?.candidates?.pageInfo?.hasNextPage);
    },
  });

  const { data: dataLocalities } = useQuery<LocalityData>(GET_LOCALITIES);
  const localities = dataLocalities?.localities?.items ? [...dataLocalities?.localities?.items] : [];
  const locationName = (x: string): string[] => {
    return localities.map((locality) => x === locality?.name ? `${locality?.name} (${locality?.state})` : "")
  }

  const {
    loading : importedCandidatesLoading,
    error   : importedCandidatesError,
    data    : importedCandidatesData,
  } = useQuery(GET_IMPORTED_CANDIDATES);

  const { data: dataByCandidateId } = useQuery(GET_CANDIDATE_BY_ID, {
    variables: { id: selectMyCandidateId },
  });

  const [importCandidate] = useMutation(IMPORT_CANDIDATE, {
    onCompleted(dataImported){
      setActiveTab("candidates");
      navigate(`/my-candidates/${dataImported?.importCandidate?.id}`);
      setSelectMyCandidateId(dataImported?.importCandidate?.id);
      setSelectedCandidate(dataByCandidateId?.candidates?.items[0]);
      setShowCandidateModal(true);
    },
    onError(error: any){
      const errorMessage = error?.networkError?.result?.errors[0]?.message;
      setToastData({
        show: true,
        color: EToastColorStatus.FAILED,
        position: EToastPosition.BOTTOM_END,
        message: `Failed, ${errorMessage}`,
      });
    },
  });

  useEffect(() => {
    setSelectedCandidate(dataByCandidateId?.candidates?.items[0]);
  }, [dataByCandidateId]);

  const client = useApolloClient();

  const refreshData = () =>
    client.refetchQueries({
      include: [GET_CANDIDATES],
    });

  const [candidatesData, setCandidatesData] = useState<Candidate[]>(
    data?.candidates?.items || []
  );

  const runPagination = (skip = 0, take = 25) => {
    const getCandidatesData = async () => {
      const { data } = await client.query({
        query: GET_CANDIDATES,
        variables: { skip, take, order: orderSortInput },
      });
      setCandidatesData(data?.candidates?.items);
      myCandidatesPaginationRef.current?.setPagination({
        ...data?.candidates?.pageInfo,
        skip,
        take,
        totalCount: data?.candidates?.totalCount,
      });
    };
    getCandidatesData();
  };

  const handleAddCandidate = () => {
    setModalKey(modalKey + 1);
    setSelectedCandidate(null);
    setShowCandidateModal(true);
  };

  /**
   * Logic for deeplinked candidates
   */
  const [routeCandidateId, setRouteCandidateId] = useState<number | null>(null);
  const { candidateId, matchId } = useParams<{
    candidateId: string;
    matchId: string;
  }>();

  useEffect(() => {
    candidateId
      ? setRouteCandidateId(Number.parseInt(candidateId))
      : setRouteCandidateId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (routeCandidateId !== null && !loading && !showCandidateDetailModal) {
    const candidate = candidatesData.find(
      (candidate) => candidate.id === routeCandidateId
    );
    if (candidate) {
      setNonATS(false);
      setSelectedCandidate(candidate);
      setShowCandidateModal(true);
    }
  }

  useEffect(() => {
    runPagination();
  }, [data?.candidates?.items]);

  useEffect(() => {
    setCandidatesData(candidatesData);
  }, [candidatesData]);

  const handleSorting = (tableTitle: string) => {
    if (tableTitle === "") return;
    if (
      orderType === "DESC" &&
      tableTitle === Object.keys(orderSortInput[0])[0]
    ) {
      setOrderSortInput([
        tableTitle === "location"
          ? { location: { displayName: "DESC" } }
          : { [tableTitle]: "DESC" },
        { updatedAt: "DESC" },
      ]);
      setOrderType("ASC");
    } else {
      setOrderSortInput([
        tableTitle === "location"
          ? { location: { displayName: "ASC" } }
          : { [tableTitle]: "ASC" },
        { updatedAt: "DESC" },
      ]);
      setOrderType("DESC");
    }
  };

  const handleShowModalDelete = (id: number) => {
    setShowCandidateDelete(true);
    setDeleteId(id);
  };

  const handleCandidateDetails = (data: Candidate) => {
    navigate(`/my-candidates/${data?.id}`);
    setNonATS(false);
    setSelectedCandidate(data);
    setShowCandidateModal(true);
  };

  const handleImportCandidateModal = (data: Candidate) => {
    const externalId  = data?.externalId;
    const candidateId = externalId.replace(/\D/g, "");
    importCandidate({
      variables: {
        candidateId: Number(candidateId),
      },
    });
  }

  const onHandleActiveTab = (activeTab: string | null) => {
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }

  const handleShowToast = (
    color: EToastColorStatus,
    position: EToastPosition,
    message: string
  ) => {
    setToastData({ show: true, color, position, message });
  };

  return (
    <Container>
      <CandidateDetailModal
        key={modalKey}
        isNonATS={nonATS}
        show={showCandidateDetailModal}
        onHide={() => {
          window.history.replaceState(null, "", `/my-candidates/`);
          setRouteCandidateId(null);
          setShowCandidateModal(false);
        }}
        candidate={selectedCandidate}
        reloadCandidates={refreshData}
        atsServer={atsServer}
        matchId={matchId}
        onCreateToast={(
          color: EToastColorStatus,
          position: EToastPosition,
          message: string
        ) => handleShowToast(color, position, message)}
        onShowDelete={(data: boolean) => setShowCandidateDelete(data)}
        onSetDeleteId={(data: number) => setDeleteId(data)}
      />
      <CandidateDeleteModal
        id={deleteId}
        show={showCandidateDelete}
        onHideModal={() => setShowCandidateDelete(false)}
        reloadCandidates={refreshData}
        onCreateToast={(
          color: EToastColorStatus,
          position: EToastPosition,
          message: string
        ) => handleShowToast(color, position, message)}
      />
      <Tabs activeKey={activeTab} onSelect={onHandleActiveTab}>
        <Tab eventKey="candidates" title="Candidates">
          <TabCardContainer>
            <Card.Body>
              {loading && <div style={{ marginBottom: "8px" }}>Loading...</div>}
              {!loading && error && (
                <div style={{ marginBottom: "8px" }}>
                  Unable to load candidates at this time - please try again soon
                </div>
              )}
              <DesktopContainer>
                <Table striped bordered hover hidden={loading}>
                  <thead>
                    <tr>
                      <th onClick={() => handleSorting("firstName")}>
                        <CandidateTitle>
                          <div>Name</div>
                          <div>
                            {orderSortInput[0]?.firstName && (
                              <ChevronTableHeader
                                ascending={orderSortInput[0]?.firstName === "ASC"}
                              />
                            )}
                          </div>
                        </CandidateTitle>
                      </th>
                      <th onClick={() => handleSorting("idealJobTitles")}>
                        <CandidateTitle>
                          <div>Job Title</div>
                          <div>
                            {orderSortInput[0]?.idealJobTitles && (
                              <ChevronTableHeader
                                ascending={
                                  orderSortInput[0]?.idealJobTitles === "ASC"
                                }
                              />
                            )}
                          </div>
                        </CandidateTitle>
                      </th>
                      <th onClick={() => handleSorting("industry")}>
                        <CandidateTitle>
                          <div>Industry</div>
                          <div>
                            {orderSortInput[0]?.industry && (
                              <ChevronTableHeader
                                ascending={orderSortInput[0]?.industry === "ASC"}
                              />
                            )}
                          </div>
                        </CandidateTitle>
                      </th>
                      <th onClick={() => handleSorting("location")}>
                        <CandidateTitle>
                          <div>Location</div>
                          <div>
                            {orderSortInput[0]?.location && (
                              <ChevronTableHeader
                                ascending={orderSortInput[0]?.location === "ASC"}
                              />
                            )}
                          </div>
                        </CandidateTitle>
                      </th>
                      <th onClick={() => handleSorting("yearsOfExperience")}>
                        <CandidateTitle>
                          <div>Exp. (yrs)</div>
                          <div>
                            {orderSortInput[0]?.yearsOfExperience && (
                              <ChevronTableHeader
                                ascending={
                                  orderSortInput[0]?.yearsOfExperience === "ASC"
                                }
                              />
                            )}
                          </div>
                        </CandidateTitle>
                      </th>
                      <th
                        className="text-center"
                        onClick={() => handleSorting("workType")}
                      >
                        {!orderSortInput[0]?.workType ? (
                          "Type"
                        ) : (
                          <CandidateTitle>
                            <div>Type</div>
                            <div>
                              {orderSortInput[0]?.workType && (
                                <ChevronTableHeader
                                  ascending={
                                    orderSortInput[0]?.workType === "ASC"
                                  }
                                />
                              )}
                            </div>
                          </CandidateTitle>
                        )}
                      </th>
                      <th
                        className="text-center"
                        onClick={() => handleSorting("audience")}
                      >
                        {!orderSortInput[0]?.audience ? (
                          "Audience"
                        ) : (
                          <CandidateTitle>
                            <div>Audience</div>
                            <div>
                              {orderSortInput[0]?.audience && (
                                <ChevronTableHeader
                                  ascending={
                                    orderSortInput[0]?.audience === "ASC"
                                  }
                                />
                              )}
                            </div>
                          </CandidateTitle>
                        )}
                      </th>
                      <th
                        className="text-center"
                        onClick={() => handleSorting("matches")}
                      >
                        {!orderSortInput[0]?.matches ? (
                          "Matches"
                        ) : (
                          <CandidateTitle>
                            <div>Matches</div>
                            <div>
                              {orderSortInput[0]?.matches && (
                                <ChevronTableHeader
                                  ascending={orderSortInput[0]?.matches === "ASC"}
                                />
                              )}
                            </div>
                          </CandidateTitle>
                        )}
                      </th>
                      <th
                        className="text-center"
                        onClick={() => handleSorting("status")}
                      >
                        {!orderSortInput[0]?.status ? (
                          "Status"
                        ) : (
                          <CandidateTitle>
                            <div>Status</div>
                            <div>
                              {orderSortInput[0]?.status && (
                                <ChevronTableHeader
                                  ascending={orderSortInput[0]?.status === "ASC"}
                                />
                              )}
                            </div>
                          </CandidateTitle>
                        )}
                      </th>
                      <th className="text-center" style={{ minWidth: "85px" }}>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {candidatesData.map((candidate) => (
                      <TR key={candidate.id}>
                        <td onClick={() => handleCandidateDetails(candidate)}>
                          {candidate?.firstName}{" "}
                          {candidate?.lastName?.slice(0, 1)}.
                        </td>
                        <td onClick={() => handleCandidateDetails(candidate)}>
                          {candidate?.idealJobTitles || "-"}
                        </td>
                        <td onClick={() => handleCandidateDetails(candidate)}>
                          {candidate?.industry || "-"}
                        </td>
                        <td onClick={() => handleCandidateDetails(candidate)}>
                          {locationName(candidate?.location?.displayName as string)}
                        </td>
                        <td onClick={() => handleCandidateDetails(candidate)}>
                          {candidate?.yearsOfExperience || "-"}
                        </td>
                        <td
                          align="center"
                          onClick={() => handleCandidateDetails(candidate)}
                        >
                          <Badge bg="secondary">
                            {candidate.workType
                              ? candidate.workType === "TEMP"
                                ? "CONTRACT/TEMP"
                                : candidate.workType
                              : "-"}
                          </Badge>
                        </td>
                        <td
                          align="center"
                          onClick={() => handleCandidateDetails(candidate)}
                        >
                          <AudiencePill audience={candidate.audience}>
                            {candidate.audience}
                          </AudiencePill>
                        </td>
                        <td
                          align="center"
                          onClick={() => handleCandidateDetails(candidate)}
                        >
                          <Badge
                            bg={candidate.matches ? "primary" : "secondary"}
                            pill
                          >
                            {candidate.matches}
                          </Badge>{" "}
                        </td>
                        <td
                          align="center"
                          onClick={() => handleCandidateDetails(candidate)}
                        >
                          <StatusPill status={candidate.status}>
                            {statusLabels[candidate.status] || "-"}
                          </StatusPill>
                        </td>
                        <td align="center">
                          <Button
                            size="sm"
                            variant="secondary"
                            onClick={() => handleCandidateDetails(candidate)}
                          >
                            <PencilSquare />
                          </Button>{" "}
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => handleShowModalDelete(candidate?.id)}
                          >
                            <TrashFill />
                          </Button>
                        </td>
                      </TR>
                    ))}
                  </tbody>
                </Table>
              </DesktopContainer>
              <MobileContainer>
                <CandidatesMobileView
                  onShowCandidateModal={() => setShowCandidateModal(true)}
                  onSelectCandidate={(data) => setSelectedCandidate(data)}
                  onSortingCandidatesData={(data) => handleSorting(data)}
                  onSetNonATS={() => setNonATS(false)}
                  candidatesData={candidatesData}
                  locationName={locationName}
                  handleShowModalDelete={handleShowModalDelete}
                />
              </MobileContainer>
              {!loading && !candidatesData.length && (
                <p>
                  You have no candidates included at this time. Keep an eye on
                  your inbox as we will send you an email when we detect potential
                  roles for your candidates.
                </p>
              )}
              <PaginationContainer>
                <p>
                  <Button onClick={handleAddCandidate}>Add Candidate</Button>
                </p>
                {hasNextPage && (
                  <CustomPagination
                    ref={myCandidatesPaginationRef}
                    onPaginationChange={(args) =>
                      runPagination(args.skip, args.take)
                    }
                  />
                )}
              </PaginationContainer>
              <p>
                <a href={guide} target="_blank" rel="noreferrer">
                  Click here to view the User Guide.
                </a>
              </p>
            </Card.Body>
          </TabCardContainer>
        </Tab>
        {showImportedFlag && (
          <Tab eventKey="imported-candidates" title="Imported Candidates">
            <TabCardContainer>
              <Card.Body>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className="text-center">Job Title</th>
                      <th className="text-center">Industry</th>
                      <th className="text-center">Location</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {importedCandidatesLoading && <tr><td>Loading...</td></tr>}
                    {importedCandidatesError && <tr><td>Error</td></tr>}
                    {importedCandidatesData && importedCandidatesData?.importedCandidates?.items.map((candidate: Candidate) => (
                      <TR key={candidate?.id}>
                        <td>{candidate?.firstName} {candidate?.lastName?.slice(0, 1)}.</td>
                        <td className="text-center">{candidate?.idealJobTitles ?? "-"}</td>
                        <td className="text-center">{candidate?.industry ?? "-"}</td>
                        <td className="text-center">{locationName(candidate?.location?.displayName as string) ?? "-"}</td>
                        <td align="center">
                          <Button
                            size="sm"
                            variant="secondary"
                            onClick={() => handleImportCandidateModal(candidate)}
                          >
                            <BoxArrowDown />
                          </Button>{" "}
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => handleShowModalDelete(candidate?.id)}
                          >
                            <TrashFill />
                          </Button>
                        </td>
                      </TR>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </TabCardContainer>
          </Tab>
        )}
      </Tabs>
      <Notification
        delay={3000}
        toastData={toastData}
        onClose={() => setToastData({ ...toastData, show: false })}
      />
    </Container>
  );
};

export default Candidates;
