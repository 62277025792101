import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { Modal, Button, Form } from "react-bootstrap";
import { ErrorWrapper } from "../../../../sharedStyles";
import { UPDATE_PROJECT } from "../../../../GraphQL/settings/mutations";
import { EToastColorStatus, EToastPosition, IProject } from "../../../../interfaces";

const editProjectSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string(),
});

interface IEditProjectProps {
  showEditModal: boolean;
  onCloseModal: () => void;
  projectId: number | null;
  projectList: IProject[] | [];
  reloadProjects: () => void;
  onHandleCreateToast: (
    color    : EToastColorStatus,
    position : EToastPosition,
    message  : string,
  ) => void;
}

const EditProjectModal = ({
  showEditModal,
  onCloseModal,
  projectId,
  projectList,
  reloadProjects,
  onHandleCreateToast = () => {},
}: IEditProjectProps) => {
  const [singleProject, setSingleProject] = useState<IProject | undefined>(undefined);
  const [updateProject] = useMutation(UPDATE_PROJECT, {
    onCompleted() {
      reloadProjects();
      onHandleCreateToast(
        EToastColorStatus.SUCCESS,
        EToastPosition.BOTTOM_END,
        "Project successfully updated",
      );
    },
    onError() {
      onHandleCreateToast(
        EToastColorStatus.FAILED,
        EToastPosition.BOTTOM_END,
        "Error, something went wrong",
      );
    },
  });

  useEffect(() => {
    if (projectList) {
      setSingleProject(projectList.find((project: IProject) => project.id === projectId));
    }
  }, [projectList, projectId]);

  const formik = useFormik({
    initialValues: {
      title: singleProject?.title ?? "",
      description: singleProject?.description ?? "",
    },
    validationSchema: editProjectSchema,
    onSubmit: (values) => {
      updateProject({
        variables: {
          id: projectId,
          title: values?.title,
          description: values?.description,
        },
      });
      onCloseModal();
    },
    enableReinitialize: true,
  });

  const { errors, touched } = formik;

  return (
    <Modal centered show={showEditModal} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="title"
              type="text"
              placeholder="Your project title"
              onChange={formik.handleChange}
              value={formik?.values?.title}
            />
            <ErrorWrapper>
              {errors?.title && touched?.title ? errors?.title as string : ''}
            </ErrorWrapper>
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              name="description" as="textarea"
              rows={3}
              placeholder="Your project description"
              onChange={formik.handleChange}
              value={formik?.values?.description}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={formik.submitForm}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditProjectModal;
