const staging = {
  apiUrl: "https://staging.api.rolerelay.com/graphql",
  authConfig: {
    Auth: {
      mandatorySignIn: true,
      region: "ap-southeast-2",
      userPoolId: "ap-southeast-2_ni1uCMCNV",
      userPoolWebClientId: "6tacaega9lh4hppt8h4prb9oqu",
    },
  },
  jobAdder: {
    integrationUrl:
      "https://staging.api.rolerelay.com/api/integrations/jodadder/connecturl",
    callbackUrl:
      "https://staging.api.rolerelay.com/api/integrations/jobadder/callback",
  },
  vincere: {
    integrationUrl:
      "https://staging.api.rolerelay.com/api/integrations/vincere/connecturl",
    callbackUrl:
      "https://staging.api.rolerelay.com/api/integrations/vincere/callback",
    syncJobs:
      "https://staging.api.rolerelay.com/api/integrations/vincere/syncJobs",
  },
  postHog: {
    apiKey: "phc_OcoQEXMubaYU0mxWQk76bqQnxXzTBoFBvgVllRdfD0P",
    disableSessionRecordings: true,
    featureFlags: {
      showImported: "staging_show-imported",
      showAccordionSettings: "staging_show-accordion-settings",
      showMatchesPage: "staging_show-matches-page",
      showAdvancedSearch: "staging_show-advanced-search",
      showFeedbackSurvey: "staging_show-feedback-survey",
      isDownMaintenance: "staging_is-down-maintenance",
      showDemoProjects: "staging_show-demo-projects",
      showDashboard: "staging_show-dashboard",
    },
  },
  reCaptchaKey: "6LeXDfYpAAAAAPUP3sBOMfvgZXb84IdJFhY2i7-S",
};

type Config = typeof staging;

const production: Config = {
  apiUrl: "https://dev.api.rolerelay.com/graphql",
  authConfig: {
    Auth: {
      mandatorySignIn: true,
      region: "ap-southeast-2",
      userPoolId: "ap-southeast-2_VK0lAbaOr",
      userPoolWebClientId: "44qv7uibgt18qq8c9q7s7sar",
    },
  },
  jobAdder: {
    integrationUrl:
      "https://dev.api.rolerelay.com/api/integrations/jodadder/connecturl",
    callbackUrl:
      "https://dev.api.rolerelay.com/api/integrations/jobadder/callback",
  },
  vincere: {
    integrationUrl:
      "https://dev.api.rolerelay.com/api/integrations/vincere/connecturl",
    callbackUrl:
      "https://dev.api.rolerelay.com/api/integrations/vincere/callback",
    syncJobs: "https://dev.api.rolerelay.com/api/integrations/vincere/syncJobs",
  },
  postHog: {
    apiKey: "phc_EHYoOdXRmcmXCB20hOnLKXpQw1HG77OIuaF4drTFUe9",
    disableSessionRecordings: false,
    featureFlags: {
      showImported: "show-imported",
      showAccordionSettings: "show-accordion-settings",
      showMatchesPage: "show-matches-page",
      showAdvancedSearch: "show-advanced-search",
      showFeedbackSurvey: "show-feedback-survey",
      isDownMaintenance: "is-down-maintenance",
      showDemoProjects: "show-demo-projects",
      showDashboard: "show-dashboard",
    },
  },
  reCaptchaKey: "6LeXDfYpAAAAAPUP3sBOMfvgZXb84IdJFhY2i7-S",
};

const config: Config =
  process.env.REACT_APP_ENV === "staging" ? staging : production;
export default config;
