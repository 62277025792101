import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "styled-components";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Tabs,
  Tab,
  OverlayTrigger,
} from "react-bootstrap";
import { useFormik } from "formik";
import { LockFill, QuestionCircleFill } from "react-bootstrap-icons";
import Toggle from "react-toggle";

import Matches from "../../Matches";
import {
  SwitchForm,
  Popover,
  ModalFooterWrapper,
  ModalFooterLeft,
  ModalFooterRight,
} from "../../../sharedStyles";
import { Candidate, LocalityData } from "../../../interfaces";
import { GET_LOCALITIES } from "../../../GraphQL/settings/queries";
import {
  ADD_CANDIDATE,
  AMEND_CANDIDATE,
  IMPORT_CANDIDATE,
} from "../../../GraphQL/candidates/mutations";
import { GET_COMPANY_USERS } from "../../../GraphQL/settings/queries";
import { industries } from "../../../const";
import { useMutation, useQuery } from "@apollo/client";
import confirmWithModal from '../../Global/confirmWithModal';
import DraftEditor from "../../DraftEditor";
import ReactSelect from "react-select";
import { EToastPosition, EToastColorStatus, EModalFormType } from "../../../interfaces";
import { GET_CANDIDATE_BY_ID, GET_PROJECT_CANDIDATE_BOARDS } from "../../../GraphQL/candidates/queries";

interface Props {
  isNonATS: boolean;
  show: boolean;
  onHide: () => void;
  candidate: Candidate | null;
  reloadCandidates: () => any;
  atsServer: string;
  matchId?: string;
  onCreateToast?: (
    color: EToastColorStatus,
    position: EToastPosition,
    message: string,
  ) => void;
  onShowDelete: (data: boolean) => void;
  onSetDeleteId: (data: number) => void;
}

export const LabelNote = styled.span`
  color: #888;
  font-weight: normal;
  font-size: 16px;
`;

const SalaryInputs = styled.div`
  display: flex;
  justify-content: space-between;
  & input {
    width: 100%;
  }
  & select {
    width: 90px;
  }

  gap: 10px;
`;

const CheckWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const CandidateDetailFormSchema = Yup.object().shape({
  localityId: Yup.string().required("Required"),
  workType: Yup.string().required("Required"),
  summary: Yup.string()
    .test('value', "Description is Required", value => value !== "<p><br></p>")
    .required("Description is Required"),
  industry: Yup.string().required("Required"),
  yearsOfExperience: Yup.number()
    .min(0, "Minimum value should be 0")
    .required("Required and should be a number"),
  idealJobTitles: Yup.string().required("Required"),
  salaryMin: Yup.number()
    .min(40000, "Minimum salary is $40,000")
    .required("Required"),
  salaryMax: Yup.number()
    .required("Required")
    .moreThan(Yup.ref("salaryMin"), "Max salary must be more than min salary"),
  audience: Yup.string().required("Required"),
});

const newCandidateDetailFormSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
});

const ToggleContainer = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: left;

  & div {
    margin: 0 4px;
  }
`;

const Hyphen = styled.div`
  padding-top: 5px;
`;

const CandidateDetailModal = ({
  isNonATS,
  show,
  onHide,
  candidate,
  reloadCandidates,
  atsServer,
  matchId,
  onCreateToast = () => {},
  onShowDelete,
  onSetDeleteId,
}: Props) => {
  const [key, setKey] = useState<string>(
    matchId ? "candidateMatches" : "candidateDetails"
  );

  const {
    error   : errorCompany,
    loading : loadingCompany,
    data    : dataCompany,
  } = useQuery(GET_COMPANY_USERS, {
    onCompleted: (data) => setCandidateOwners(data?.companyUsers?.items),
  });

  const {
    error   : errorProjectBoards,
    loading : loadingProjectBoards,
    data    : dataProjectBoards,
  } = useQuery(GET_PROJECT_CANDIDATE_BOARDS);

  const { data: candidateByIdData } = useQuery(GET_CANDIDATE_BY_ID, {
    variables: {
      id: candidate?.id
    },
  });

  let initCandidateOwners = [];
  if (candidate?.candidateOwners){
    for (let i in candidate?.candidateOwners)
      initCandidateOwners.push({value: candidate?.candidateOwners[i].userId, label: candidate?.candidateOwners[i].email});
  }

  const [candidateOwnersValue, setCandidateOwnersValue] = useState(initCandidateOwners);
  const [candidateOwners, setCandidateOwners] = useState<any>([]);
  let ownerOptions = [];
  if (dataCompany) ownerOptions = candidateOwners.map((item: any) => ({value: item?.id, label: item?.email}));

  let initProjectBoards = [];
  if (candidateByIdData) {
    for (let i in candidateByIdData?.candidates?.items[0]?.projectBoards) {
      initProjectBoards.push({
        value: candidateByIdData?.candidates?.items[0]?.projectBoards[i]?.id,
        label: candidateByIdData?.candidates?.items[0]?.projectBoards[i]?.title,
      });
    }
  }

  const [projectBoardsValue, setProjectBoardsValue] = useState(initProjectBoards);
  const [projectBoards, setProjectBoards] = useState<any>([]);

  const [matchIndex, setMatchIndex] = useState(0);
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [addCandidate] = useMutation(ADD_CANDIDATE);
  const [amendCandidate] = useMutation(AMEND_CANDIDATE);
  const [importCandidate] = useMutation(IMPORT_CANDIDATE);
  const [sharing, setSharing] = useState(false);
  const [atsCandidateId, setAtsCandidateId] = useState<string>("");
  const [modalFormType, setModalFormType] = useState<EModalFormType>(EModalFormType.ID);
  const { data } = useQuery<LocalityData>(GET_LOCALITIES);

  const localities = data?.localities?.items ? [...data.localities.items] : [];
  localities.sort((a, b) => {  
    return a.state.localeCompare(b.state) || a.name.localeCompare(b.name)
   });

  useEffect(() => {
    let ownersValue = [];
    if (candidate?.candidateOwners){
      for (let i in candidate?.candidateOwners)
        ownersValue.push({value: candidate?.candidateOwners[i].userId, label: candidate?.candidateOwners[i].email});
    }
    setCandidateOwnersValue(ownersValue);

    let projectBoardValue = [];
    if (candidateByIdData) {
      for (let i in candidateByIdData?.candidates?.items[0]?.projectBoards) {
        projectBoardValue.push({
          value: candidateByIdData?.candidates?.items[0]?.projectBoards[i]?.id,
          label: candidateByIdData?.candidates?.items[0]?.projectBoards[i]?.title,
        });
      }
    }
    setProjectBoardsValue(projectBoardValue);

    setSharing(candidate?.status === "SHARED");
  }, [candidate, candidateByIdData]);

  useEffect(() => {
    let val = [];
    if (dataProjectBoards?.projectCandidateBoards?.items) {
      for (let i in dataProjectBoards?.projectCandidateBoards?.items) {
        val.push({
          value: dataProjectBoards?.projectCandidateBoards?.items[i]?.id,
          label: dataProjectBoards?.projectCandidateBoards?.items[i]?.title,
        });
      }
    }
    setProjectBoards(val);
  }, [dataProjectBoards]);

  const nextMatch = () => {
    setMatchIndex(matchIndex + 1);
  };

  const prevMatch = () => {
    setMatchIndex(matchIndex - 1);
  };

  const tabSelect = (tab: string | null) => {
    if (tab) setKey(tab);
  };

  const onDelete = () => {
    if (candidate?.id) onSetDeleteId(candidate?.id);
    onShowDelete(true);
    onHide();
  };

  const handleATSCandidate = () => {
    if (atsCandidateId) {
      onCreateToast(
        EToastColorStatus.WARNING,
        EToastPosition.BOTTOM_END,
        "Sync requested for candidate: " + atsCandidateId,
      );

      importCandidate({
        variables: {
          candidateId: parseInt(atsCandidateId),
        },
      })
        .then(() => {
          reloadCandidates();
          onCreateToast(
            EToastColorStatus.SUCCESS,
            EToastPosition.BOTTOM_END,
            "Candidate imported!",
          );
          onHide();
        })
        .catch(() => {
          onCreateToast(
            EToastColorStatus.FAILED,
            EToastPosition.BOTTOM_END,
            "Error importing candidate. Please check the candidate ID and try again.",
          );
          onHide();
        });
    } else {
      onCreateToast(
        EToastColorStatus.WARNING,
        EToastPosition.BOTTOM_END,
        "Please input a candidate ID to sync",
      )
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: candidate
      ? {
          ...candidate,
          localityId: "" + candidate?.location?.localityId,
          share: false,
          summary: candidate?.summary ?? "",
        }
      : {
          firstName: "",
          lastName: "",
          localityId: "",
          summary: "",
          industry: "",
          yearsOfExperience: "",
          idealJobTitles: "",
          salaryMin: "",
          salaryMax: "",
          share: false,
          audience: "PRIVATE",
          interviewed: false,
          location: "",
          workType: "",
          currency: "AUD",
          ownerUserIds: [],
          projectCandidateBoardIds: [],
        },
    validateOnChange: true,
    validationSchema: candidate
      ? CandidateDetailFormSchema
      : CandidateDetailFormSchema.concat(newCandidateDetailFormSchema),
    onSubmit: async (values) => {
      if (values.share) {
        const confirmed = await confirmWithModal(
          <>
            <p>Before sharing a candidate, you confirm that</p>
            <ul>
              <li>
                You have received permission from the Candidate to be posted on
                the platform;
              </li>
              <li>
                there is a genuine opportunity for the Candidate to accept a
                role with a Client; and
              </li>
              <li>
                your agency has interviewed or screened the Candidate within the
                last three months.
              </li>
            </ul>
          </>
        );
        if (!confirmed) return;
      }

      if (candidate) {
        amendCandidate({
          variables: {
            ...values,
            localityId: parseInt(values.localityId),
          },
        })
          .then(() => {
            reloadCandidates();
            onCreateToast(
              EToastColorStatus.SUCCESS,
              EToastPosition.BOTTOM_END,
              "Candidate updated!",
            );
            onHide();
          })
          .catch(() => {
            onCreateToast(
              EToastColorStatus.FAILED,
              EToastPosition.BOTTOM_END,
              "Error while updating candidate, please ensure all fields are filled",
            );
          });
      } else {
        addCandidate({
          variables: {
            ...values,
            middleName: "",
            jobTitle: values.idealJobTitles,
            localityId: parseInt(values.localityId),
          },
        })
          .then((response) => {
            onCreateToast(
              EToastColorStatus.SUCCESS,
              EToastPosition.BOTTOM_END,
              "Candidate added successfully!",
            );
            reloadCandidates();
            onHide();
          })
          .catch(() => {
            onCreateToast(
              EToastColorStatus.FAILED,
              EToastPosition.BOTTOM_END,
              "Error while updating candidate, please ensure all fields are filled",
            );
          });
      }
    },
  });

  const fv = formik.values;

  const { errors, touched } = formik;

  const ErrorWrapper = ({ field }: { field: keyof typeof errors }) => {
    return (
      <>
        {errors && touched[field] ? (
          <div style={{ color: "red" }}>{errors[field]}</div>
        ) : null}
      </>
    );
  };

  if (!isNonATS && !candidate && modalFormType === EModalFormType.ID) {
    return (
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header>
          <Modal.Title>New Candidate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Enter the ID of a candidate you would like to add to Role Relay.{" "}
            {/* TODO */}
            {/* <Button variant="link">Where do I find my role ID?</Button> */}
          </p>
          <Form.Group controlId="formClient">
            <Form.Label>Candidate ID*</Form.Label>
            <Form.Control
              type="text"
              name="roleId"
              onChange={(e) => setAtsCandidateId(e.target.value)}
              required
            />
          </Form.Group>
          <br />
          <SwitchForm onClick={() => setModalFormType(EModalFormType.MANUAL)}>
            Add candidate manually instead
          </SwitchForm>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} variant="secondary">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleATSCandidate}>
            Add Candidate
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Modal show={show && !showQuery} onHide={onHide} size="lg">
        <Modal.Header>
          {candidate?.firstName ? (
            <Modal.Title>
              {candidate?.firstName} {candidate?.lastName?.slice(0, 1)}.
              {candidate?.jobTitle ? ` - ${candidate.jobTitle}` : ""}
              {candidate?.location?.displayName
                ? ` - ${candidate.location.displayName}`
                : ""}
            </Modal.Title>
          ) : (
            <Modal.Title>New Candidate</Modal.Title>
          )}

          {candidate && (
            <a
              href={`https://${atsServer}.jobadder.com/candidates/${candidate?.externalId
                ?.split(":")
                .slice(-1)}`}
              target="_blank"
            >
              View this candidate in JobAdder
            </a>
          )}
        </Modal.Header>
        <Modal.Body>
          {!isNonATS && !candidate &&
            <SwitchForm onClick={() => setModalFormType(EModalFormType.ID)}>
              Import candidate from your ATS instead
            </SwitchForm>
          }
          <Tabs activeKey={key} onSelect={tabSelect}>
            <Tab eventKey="candidateDetails" title="Candidate Details">
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <br />
                    <p>
                      <strong>Note:</strong> These details will be visible to
                      other agencies on the platform when sharing is activated.
                      Please ensure you check all fields (particularly the
                      Description/Profile) for any identifiable information.
                    </p>
                  </Col>
                </Row>
                {!candidate && (
                  <>
                    <Row>
                      <Col>
                        {" "}
                        <Form.Group controlId="formFamilyName">
                          <Form.Label>Family name*</Form.Label>{" "}
                          <LabelNote>
                            <LockFill />
                          </LabelNote>{" "}
                          <Form.Control
                            type="text"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                          />
                          <ErrorWrapper field="lastName" />
                        </Form.Group>
                      </Col>
                      <Col>
                        {" "}
                        <Form.Group controlId="firstName">
                          <Form.Label>First name*</Form.Label>
                          <LabelNote>
                            <LockFill />
                          </LabelNote>{" "}
                          <Form.Control
                            type="text"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                          />
                          <ErrorWrapper field="firstName" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br />
                  </>
                )}
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group controlId="formLocation">
                          <Form.Label>Location*</Form.Label>
                          <Form.Control
                            as="select"
                            name="localityId"
                            placeholder="Select location"
                            value={fv.localityId}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          >
                            <option></option>
                            {localities.map((locality) => (
                              <option key={locality.id} value={locality.id}>
                                {locality.name} ({locality.state})
                              </option>
                            ))}
                          </Form.Control>
                          <ErrorWrapper field="localityId" />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="formType">
                          <Form.Label>Type</Form.Label>
                          <Form.Control
                            as="select"
                            name="workType"
                            placeholder="Select type"
                            value={fv.workType}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value=""></option>
                            <option value="PERMANENT">PERMANENT</option>
                            <option value="TEMP">CONTRACT/TEMP</option>
                            <option value="ANY">ANY</option>
                          </Form.Control>
                          <ErrorWrapper field="workType" />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Group controlId="formRoleSalary">
                      <Form.Label>Salary range (min. $40,000)*</Form.Label>
                      <SalaryInputs>
                        <div>
                          <Form.Control
                            type="number"
                            name="salaryMin"
                            placeholder="Min."
                            required
                            value={fv.salaryMin}
                            onChange={formik.handleChange}
                            onBlur={(...args) => {
                              formik.handleBlur(...args);
                              formik.validateField("salaryMax");
                            }}
                          />{" "}
                          <ErrorWrapper field="salaryMin" />
                        </div>
                        <Hyphen> - </Hyphen>
                        <div>
                          <Form.Control
                            type="number"
                            name="salaryMax"
                            placeholder="Max."
                            value={fv.salaryMax}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                          />{" "}
                          <ErrorWrapper field="salaryMax" />
                        </div>
                        <div>
                        <Form.Control
                            as="select"
                            name="currency"
                            value={fv.currency}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="AUD">AUD</option>
                            <option value="NZD">NZD</option>
                          </Form.Control>
                          </div>
                      </SalaryInputs>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Group controlId="formRole">
                      <Form.Label>Description/Profile*</Form.Label>
                      <DraftEditor formik={formik} />
                    </Form.Group>
                    <ErrorWrapper field="summary" />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Group controlId="formIndustry">
                      <Form.Label>Industry*</Form.Label>
                      <Form.Control
                        as="select"
                        name="industry"
                        placeholder="Select industry"
                        value={fv.industry}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      >
                        <option></option>
                        {industries.map((industry) => (
                          <option key={industry} value={industry}>
                            {industry}
                          </option>
                        ))}
                      </Form.Control>
                      <ErrorWrapper field="industry" />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formRoleExperience">
                      <Form.Label>Years experience*</Form.Label>
                      <Form.Control
                        type="number"
                        name="yearsOfExperience"
                        required
                        value={fv.yearsOfExperience}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Group>
                    <ErrorWrapper field="yearsOfExperience" />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Group controlId="formRoleIdealJobTitle">
                      <Form.Label>Ideal job titles*</Form.Label>
                      <Form.Control
                        type="text"
                        name="idealJobTitles"
                        required
                        value={fv.idealJobTitles}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Group>
                    <ErrorWrapper field="idealJobTitles" />
                  </Col>
                  {!loadingCompany && !errorCompany && dataCompany &&
                    <Col>
                      <Form.Group controlId="formRoleIdealJobTitle">
                        <Form.Label>Owners</Form.Label>
                        <ReactSelect
                          onChange={(data: any) => {
                            let ownerIds = [];
                            for (let i in data) ownerIds.push(Number(data[i]?.value));
                            formik.setFieldValue("ownerUserIds", ownerIds);
                            setCandidateOwnersValue(data);
                          }}
                          name="candidateOwners"
                          options={ownerOptions}
                          isMulti={true}
                          value={candidateOwnersValue}
                        />
                      </Form.Group>
                      <ErrorWrapper field="idealJobTitles" />
                    </Col>
                  }
                </Row>
                <br />
                <Row>
                  <Col>
                    <CheckWrap>
                      <Form.Check
                        type="checkbox"
                        label="I have received permission from the candidate to be represented on Role Relay in the last 3 months"
                        checked={fv.interviewed}
                        onChange={() =>
                          formik.setFieldValue("interviewed", !fv.interviewed)
                        }
                        name="interviewed"
                      />{" "}
                      &nbsp;{" "}
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Popover>
                            <Popover.Body>
                              <p>Role Relay's terms dictate that:</p>
                              <ul>
                                <li>
                                  Only candidates that you have engaged with in
                                  the last 3 months can be represented on the
                                  platform.
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  You must have written permission from the
                                  candidate to represent them on the platform.
                                </li>
                              </ul>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <QuestionCircleFill />
                      </OverlayTrigger>
                    </CheckWrap>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <Form.Label>Sharing</Form.Label>{" "}
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Popover>
                          <Popover.Body>
                            <strong>Shared for Collaboration</strong> will push
                            this candidate to the Role Relay Network based on
                            your <strong>Audience</strong> selection. Candidates
                            you do not wish to share with the network can be set
                            to <strong>Draft</strong>.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <QuestionCircleFill />
                    </OverlayTrigger>
                    <ToggleContainer>
                      <span>Draft</span>
                      <Toggle
                        name="shared"
                        icons={false}
                        onChange={(evt) => {
                          setSharing(evt.target.checked);
                        }}
                        checked={sharing}
                      />{" "}
                      <span>Shared for collaboration</span>
                    </ToggleContainer>
                  </Col>
                  <Col>
                    <Form.Group controlId="formAudience">
                      <Form.Label>Audience</Form.Label>{" "}
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Popover>
                            <Popover.Body>
                              <p>
                                Select which audience you would like to share
                                the Candidate with.
                              </p>
                              <ul>
                                <li>
                                  <strong>Private</strong> - only flagged for
                                  Role Relay automatic matching. You will
                                  receive notifications of opportunities Role
                                  Relay detects for this candidate, but their
                                  anonymous details will not be searchable for
                                  other agencies on the Public or Partner
                                  Candidate Boards.
                                </li>
                                <li>
                                  <strong>Partners Only</strong> - flagged for
                                  Role Relay automatic matching, and listed on
                                  the Partner Candidate Board. The candidate's
                                  anonymous details will not be searchable on
                                  the Public Candidate Board.
                                </li>
                                <li>
                                  <strong>Public</strong> - flagged for
                                  matching, and listed on the Partner and Public
                                  Candidate Board.
                                </li>
                              </ul>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <QuestionCircleFill />
                      </OverlayTrigger>
                      <Form.Control
                        as="select"
                        name="audience"
                        placeholder="Select type"
                        value={fv.audience}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value=""></option>
                        <option value="PRIVATE">Private</option>
                        <option value="PARTNER">Partners only</option>
                        <option value="PUBLIC">Public (incl. Partners)</option>
                      </Form.Control>
                      <ErrorWrapper field="audience" />
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                  </Col>
                  <Col>
                    {!loadingProjectBoards && !errorProjectBoards && dataProjectBoards && (
                      <Form.Group>
                        <Form.Label>Projects</Form.Label>
                        <ReactSelect
                          onChange={(data: any) => {
                            let candidateBoardIds = [];
                            for (let i in data)
                              candidateBoardIds.push(data[i]?.value);
                            formik.setFieldValue("projectCandidateBoardIds", candidateBoardIds);
                            setProjectBoardsValue(data);
                          }}
                          name="projectBoards"
                          isMulti={true}
                          options={projectBoards}
                          value={projectBoardsValue}
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>
              </Form>
              <hr />
              <p>
                <strong>What happens next?</strong>
              </p>
              <p>
                Your Candidate will be shared with the Role Relay network based
                on the Audience you have selected. Only the anonymous details
                you have provided will be shared with other agencies.
              </p>
            </Tab>
            {candidate && (
              <Tab eventKey="candidateMatches" title="Matches">
                <Matches candidateId={candidate?.id} matchId={matchId} />
              </Tab>
            )}
          </Tabs>
        </Modal.Body>
        {key === "candidateDetails" && (
          <Modal.Footer>
            <ModalFooterWrapper>
              <ModalFooterLeft>
                {candidate && (
                  <Row>
                    <Col>
                      <Button
                        variant="link"
                        onClick={(e) => {
                          e.preventDefault();
                          onDelete();
                        }}
                      >
                        Remove this candidate from Role Relay
                      </Button>
                    </Col>
                  </Row>
                )}
              </ModalFooterLeft>
              <ModalFooterRight>
                <Button
                  className="mx-1"
                  variant="secondary"
                  onClick={onHide}
                >
                  Cancel
                </Button>
                <Button
                  className="mx-1"
                  variant="primary"
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    formik.setFieldValue("share", sharing);
                    formik.submitForm();
                  }}
                >
                  Save
                </Button>
              </ModalFooterRight>
            </ModalFooterWrapper>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default CandidateDetailModal;
