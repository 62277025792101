import { useEffect, useState } from "react";
import { Tabs, Tab, Card, Table } from "react-bootstrap";
import { TabCardContainer, PageContainer } from "../../sharedStyles";
import { useQuery } from "@apollo/client";
import { GET_JOB_MATCHES, GET_CANDIDATE_MATCHES } from "../../GraphQL/matches/queries";
import { JobMatches, JobMatchesData, CandidateMatches, CandidateMatchesData } from "../../interfaces";
import { useNavigate, useParams } from "react-router-dom";
import MatchesDetailModal from "./MatchesDetailModal";
import RenderStatus from "./RenderStatus";

const PublicMatches = ({ user }: any) => {
  const navigate = useNavigate();
  let atsServer = "au1";
  if (user?.username?.includes("firefly") || user?.username?.includes("worktrybe")) atsServer = "au3";
  if (user?.username?.includes("lloyd") || user?.username?.includes("candidex")) atsServer = "au5";

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [jobMatchesList, setJobMatchesList] = useState([] as JobMatches[]);
  const [candidateMatchesList, setCandidateMatchesList] = useState([] as CandidateMatches[]);
  const [activeMatches, setActiveMatches] = useState<any>("");
  const [tabKey, setTabKey] = useState("roles-matches");

  const {
    error: jobMatchesError,
    loading: jobMatchesLoading,
    data: jobMatchesData,
  } = useQuery<JobMatchesData>(GET_JOB_MATCHES, {
    variables: { order: { updatedAt: "DESC" }},
  });

  const {
    error: candidateMatchesError,
    loading: candidateMatchesLoading,
    data: candidateMatchesData,
  } = useQuery<CandidateMatchesData>(GET_CANDIDATE_MATCHES, {
    variables: { order: { updatedAt: "DESC"}},
  });

  useEffect(() => {
    setJobMatchesList(jobMatchesData?.jobMatches?.items || []);
  }, [jobMatchesData]);

  useEffect(() => {
    setCandidateMatchesList(candidateMatchesData?.candidateMatches?.items || []);
  }, [candidateMatchesData]);

  /* Deeplinked matches routes */
  const [routeMatchId, setRouteMatchId] = useState<number | null>(null);
  const { matchId } = useParams<{matchId: string}>();

  useEffect(() => {
    matchId ? setRouteMatchId(Number.parseInt(matchId)) : setRouteMatchId(null);
  }, []);

  if (routeMatchId !== null && !isOpenModal) {
    if (tabKey === 'roles-matches') {
      const role = jobMatchesList.find((role) => Number.parseInt(role.id) === routeMatchId);
      if (role) {
        setActiveMatches(role);
        setIsOpenModal(true);
      }
    } else {
      const candidate = candidateMatchesList.find((candidate) =>  Number.parseInt(candidate.id) === routeMatchId);
      if (candidate) {
        setActiveMatches(candidate);
        setIsOpenModal(true);
      }
    }
  }

  const setActiveTab = (tab: string | null) => {
    if (tab) setTabKey(tab);
  };

  const handleOpenMatchDetail = (item: JobMatches|CandidateMatches) => {
    setIsOpenModal(true);
    setActiveMatches(item);
    navigate(`/matches/${item?.id}`);
  }

  return (
    <PageContainer>
      <MatchesDetailModal
        show={isOpenModal}
        onHide={() => {
          setIsOpenModal(false);
          navigate("/matches");
        }}
        matchContent={activeMatches}
        tabType={tabKey}
        atsServer={atsServer}
      />
      <Tabs activeKey={tabKey} onSelect={setActiveTab}>
        <Tab eventKey="roles-matches" title="Role Matches" style={{position: 'relative'}}>
          <TabCardContainer>
            <Card.Body>
              {jobMatchesLoading && <p>Loading...</p>}
              {jobMatchesError && <p>Error: {jobMatchesError?.message}</p>}
              {jobMatchesList === undefined && <p>No data matches</p>}
              {!jobMatchesLoading && !jobMatchesError && jobMatchesData && (
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>Role</th>
                      <th>Role Location</th>
                      <th>Candidate Title</th>
                      <th>Candidate Location</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Agency</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobMatchesList !== undefined && jobMatchesList.map((item: JobMatches) => (
                      <tr key={item?.id} onClick={() => handleOpenMatchDetail(item)}>
                        <td>{item?.clientName ?? "-"}</td>
                        <td>{item?.jobTitle ?? "-"}</td>
                        <td>{item?.jobLocation?.displayName ?? "-"}</td>
                        <td>{item?.candidateJobTitle ?? "-"}</td>
                        <td>{item?.candidateLocation?.displayName ?? "-"}</td>
                        <td className="text-center">
                          <RenderStatus
                            position="tables"
                            status={item?.jobAgentStatus}
                            otherStatus={item?.candidateAgentStatus}
                          />
                        </td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </TabCardContainer>
        </Tab>
        <Tab eventKey="candidates-matches" title="Candidate Matches">
          <TabCardContainer>
            <Card.Body>
              {candidateMatchesLoading && <p>Loading...</p>}
              {candidateMatchesError && <p>Error: {candidateMatchesError?.message}</p>}
              {candidateMatchesList === undefined && <p>No candidate matches</p>}
              {!candidateMatchesLoading && !candidateMatchesError && candidateMatchesData && (
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Candidate Name</th>
                      <th>Candidate Job Title</th>
                      <th>Candidate Location</th>
                      <th>Job Title</th>
                      <th className="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {candidateMatchesList !== undefined && candidateMatchesList.map((item: CandidateMatches) => (
                      <tr key={item?.id} onClick={() => handleOpenMatchDetail(item)}>
                        <td>{item?.candidateName ?? "-"}</td>
                        <td>{item?.candidateJobTitle ?? "-"} </td>
                        <td>{item?.candidateLocation?.displayName ?? "-"}</td>
                        <td>{item?.jobTitle ?? "-"}</td>
                        <td className="text-center">
                          <RenderStatus
                            position="tables"
                            status={item?.candidateAgentStatus}
                            otherStatus={item?.jobAgentStatus}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </TabCardContainer>
        </Tab>
      </Tabs>
    </PageContainer>
  )
}

export default PublicMatches;
