import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/custom.scss';
import App from './App';
import config from './config';
import reportWebVitals from './reportWebVitals';
import { PostHogProvider } from 'posthog-js/react';

const posthogOptions = {
  api_host: 'https://app.posthog.com',
  disable_session_recording: config?.postHog?.disableSessionRecordings,
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={config?.postHog?.apiKey}
      options={posthogOptions}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
