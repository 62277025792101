export function numberWithCommas(x: number) {
  return x.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function currencyFormat(x: number) {
  return "$" + numberWithCommas(x);
}

export function formatStringToDate(x: string) {
  const parseDate = Date.parse(x);
  const listDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayName = listDays[new Date(parseDate).getDay()];
  const date = new Date(parseDate).getDate() + "/" + (new Date(parseDate).getMonth() + 1) + "/" + new Date(parseDate).getFullYear();
  const hour = new Date(parseDate).toLocaleTimeString('en-US');
  return dayName + " " + date +", " + hour;
}

export function compareSortingData(a: any, b: any, tableTitle: string) {
  const x = a[tableTitle];
  const y = b[tableTitle];
  if (x === null || x === "") return 1;
  if (y === null || y === "") return -1;
  if (x === y) return 0;
  return x < y ? -1 : x > y ? 1 : 0;
}

export function capitalizeWord(x: string) {
  return x.charAt(0).toUpperCase() + x.slice(1);
}

export const getTabType = (pathname: string, searchType: string | undefined): string => {
  if (pathname === "/public-roles") {
    if (searchType === "public") return "public roles";
    return "partner roles";
  } else if (pathname === "/public-candidates"){
    if (searchType === "public") return "public candidates";
    return "partner candidates";
  }
  return "";
}
