import { gql } from "@apollo/client";

export const CREATE_JOB_TOPIC = gql`
  mutation createJobTopic($jobId: Int!, $message: String!) {
    createJobTopic(input: { jobId: $jobId, message: $message }) {
      id
      message
      createdAt
    }
  }
`;

export const CREATE_JOB_TOPIC_COMMENT = gql`
  mutation commentJobTopic($topicId: String!, $message: String!) {
    commentJobTopic(input: { jobTopicId: $topicId, message: $message }) {
      id
      message
      createdAt
    }
  }
`;

export const CREATE_CANDIDATE_TOPIC = gql`
  mutation createCandidateTopic($candidateId: Int!, $message: String!) {
    createCandidateTopic(input: { candidateId: $candidateId, message: $message }) {
      id
      message
      createdAt
    }
  }
`;

export const CREATE_CANDIDATE_TOPIC_COMMENT = gql`
  mutation commentCandidateTopic($topicId: String!, $message: String!) {
    commentCandidateTopic(input: { candidateTopicId: $topicId, message: $message }) {
      id
      message
      createdAt
    }
  }
`;

export const CREATE_ROLE_RESOLVING_MESSAGE = gql`
  mutation resolve($jobTopicId: String!) {
    resolveJobTopic(input: { jobTopicId: $jobTopicId }){
      jobTopicId
    }
  }
`;

export const CREATE_CANDIDATE_RESOLVING_MESSAGE = gql`
  mutation resolve($candidateTopicId: String!) {
    resolveCandidateTopic(input: { candidateTopicId: $candidateTopicId }){
      candidateTopicId
    }
  }
`;
