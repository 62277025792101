import { useEffect, useState } from "react";

function useCheckMobile(){
  const [size, setSize] = useState<{width: number; height: number}>({
    width: 0,
    height: 0,
  });
  
  useEffect(() => {
    function handleResize() {
      setSize({ width: window.innerWidth, height: window.innerHeight});
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return size;
}

export default useCheckMobile;
