import { Button, Modal, Table } from "react-bootstrap";

interface IMemberProjectProps {
  showMemberModal: boolean;
  onCloseModal: () => void;
  onHandleInviteMember: () => void;
}

const MemberProjectModal = ({
  showMemberModal,
  onCloseModal,
  onHandleInviteMember,
}: IMemberProjectProps) => {

  // Only for dummy, please remove if unused again!
  const users = [
    { id: 1, email: "user1@rolerelay.com", status: "ACTIVE" },
    { id: 2, email: "user2@rolerelay.com", status: "ACTIVE" },
    { id: 3, email: "user3@rolerelay.com", status: "DEACTIVATE"},
  ];

  const handleInviteMember = () => {
    onCloseModal();
    onHandleInviteMember();
  }

  return (
    <Modal
      show={showMemberModal}
      onHide={onCloseModal}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Project Members</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Table bordered hover>
          <thead>
            <tr>
              <th className="w-50">Email</th>
              <th className="w-25 text-center">Status</th>
              <th className="w-25 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map(({id, email, status}) => (
              <tr key={id}>
                <td>{email}</td>
                <td className="text-center">{status}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleInviteMember}
        >
          Invite Member
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MemberProjectModal;
