import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Button } from "react-bootstrap";

import { CardFooter } from "../../sharedStyles";
import QueryModal from "../QueryModal";

import {
  GET_MATCHES_FOR_CANDIDATE,
  GET_MATCHES_FOR_ROLE,
} from "../../GraphQL/matches/queries";
import { MatchData } from "../../interfaces";
import CandidateMatch from "./CandidateMatch";
import RoleMatch from "./RoleMatch";
import { match } from 'assert';
interface Props {
  roleId?: number;
  candidateId?: number;
  matchId?: string;
}

const Matches = ({ roleId, candidateId, matchId }: Props) => {
  const activeQuery = roleId ? GET_MATCHES_FOR_ROLE : GET_MATCHES_FOR_CANDIDATE;
  const activeId = roleId ? roleId : candidateId;
  const isCandidatesForRole = roleId ? true : false;

  const [matchIndex, setMatchIndex] = useState(0);
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [question] = useState<string | null>(null);

  const { loading, error, data } = useQuery<MatchData>(activeQuery, {
    variables: { id: activeId },
  });

  useEffect(() => {
    const matches = data?.matches?.items;
    if (matchId && matches) {
      const match = matches.find((match) => match.id === matchId);
      if (match) {
        setMatchIndex(matches.indexOf(match));
      }
    }else{
      setMatchIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchId, data]);

  if (loading) return <p>Loading matches...</p>;
  if (error) return <p>Error loading matches</p>;

  const matches = data?.matches?.items;

  if (!matches || matches.length === 0) return <p>No matches found</p>;

  const nextMatch = () => {
    setMatchIndex(matchIndex + 1);
  };

  const prevMatch = () => {
    setMatchIndex(matchIndex - 1);
  };

  return (
    <>
      <QueryModal
        show={showQuery}
        hide={() => setShowQuery(false)}
        jobId={roleId ? undefined : matches[matchIndex].jobId}
        candidateId={roleId ? matches[matchIndex].candidateId : undefined}
      />
      <br />
      <p>
        <strong>
          {matchIndex + 1} of {matches.length}{" "}
          {isCandidatesForRole
            ? "candidate matches for this role"
            : "role matches for this candidate"}
        </strong>
      </p>
      <hr />
      {isCandidatesForRole ? (
        <CandidateMatch
          match={matches[matchIndex]}
          setShowQuery={setShowQuery}
        />
      ) : (
        <RoleMatch match={matches[matchIndex]} setShowQuery={setShowQuery} />
      )}
      <CardFooter>
        <div>
          {matchIndex > 0 && (
            <Button variant="link" onClick={prevMatch}>
              &lt; Prev
            </Button>
          )}
        </div>
        <div>
          {matchIndex < matches.length - 1 && (
            <Button variant="link" onClick={nextMatch}>
              Next &gt;
            </Button>
          )}
        </div>
      </CardFooter>
    </>
  );
};

export default Matches;
