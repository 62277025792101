import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { EnvelopeFill } from 'react-bootstrap-icons';
import { useMutation, useQuery } from "@apollo/client";
import { GET_CANDIDATES } from "../../../GraphQL/candidates/queries";
import { ADD_JOB_MATCH } from "../../../GraphQL/matches/mutations";
import { CREATE_JOB_TOPIC } from "../../../GraphQL/messages/mutations";
import { Candidate, PublicJob } from "../../../interfaces";
import { currencyFormat } from "../../../utils";
import confirmWithModal from '../../Global/confirmWithModal';
import parse from "html-react-parser";
import ReactSelect from "react-select";
import { ButtonRightCorner } from "../../../sharedStyles";

interface Props {
  show: boolean;
  onHide: () => void;
  role: PublicJob;
}

const PublicRoleDetailModal = ({ show, onHide, role }: Props) => {
  const { loading, error, data } = useQuery(GET_CANDIDATES);
  const [addCandidateMatch] = useMutation(ADD_JOB_MATCH);
  const [createTopic] = useMutation(CREATE_JOB_TOPIC);

  const [query, setQuery] = useState<string>("");
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [multipleCandidates, setMultipleCandidates] = useState<any[]>([]);
  const [candidateIds, setCandidateIds] = useState<number[]>([]);

  useEffect(() => {
    let data: number[] = [];
    for (let i in multipleCandidates) data.push(multipleCandidates[i].value as number);
    setCandidateIds(data);
  }, [multipleCandidates]);

  if (!role || loading || error) return null;

  const candidateOptions = data.candidates?.items?.filter(
    (candidate: Candidate) => candidate.status === "SHARED"
  );
  // TODO: Filter roles so only opted in are included

  let proposedCandidateValue = [];
  if (candidateOptions){
    for (let i in candidateOptions) {
      const value = candidateOptions[i]?.id;
      const label = `${candidateOptions[i]?.firstName} ${candidateOptions[i]?.lastName?.slice(0, 1)}. - ${candidateOptions[i].idealJobTitles}`;
      proposedCandidateValue.push({ value, label });
    }
  }

  const handleClick = async () => {
    if (!candidateIds)
      return alert("Please select a role to put forward for this candidate");

    const confirmed = await confirmWithModal(
      <>
        <p>Before sharing a candidate, you confirm that</p>
        <ul>
          <li>
            You have received permission from the Candidate to be posted on the
            platform;
          </li>
          <li>
            there is a genuine opportunity for the Candidate to accept a role
            with a Client; and
          </li>
          <li>
            your agency has interviewed or screened the Candidate within the
            last three months.
          </li>
        </ul>
      </>
    );
    if (!confirmed) return;

    try {
      await addCandidateMatch({
        variables: { jobId: role.id, candidateIds: candidateIds },
      });
    } catch (err) {
      return alert(
        "Something went wrong, have you already put forward this candidate for this role? If not please try again soon"
      );
    }
    alert("Candidate has been put forward for this role!");
    onHide();
  };

  const handleSendQuery = async () => {
    try {
      await createTopic({variables: {
        jobId: role?.id,
        message: query,
      }});
      alert("Your query has been sent to the client agent");
      setShowQuery(false);
    } catch (error) {
      alert("Something went wrong, please try again soon");
      setShowQuery(false);
    }
  }

  return (
    <Modal show={show} size="lg" onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          {role.title} - {role.location.displayName}
        </Modal.Title>
        <div>
          Fee Estimate:{" "}
          <strong>
            {currencyFormat(role.feeMin)} - {currencyFormat(role.feeMax)}
            {" "}{role?.currency}
          </strong>
          {role.workType === "TEMP" && " per hr"}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <strong>Work type:</strong>{" "}
            {role.workType === "TEMP" ? "CONTRACT/TEMP" : "PERMANENT"}
            <br />
            <strong>Industry:</strong> {role.industry}
            <br />
            {role.workType === "PERMANENT" && (
              <>
                <strong>Salary:</strong> {currencyFormat(role.salaryMin)} -{" "}
                {currencyFormat(role.salaryMax)}
                {" "}{role?.currency}
              </>
            )}
            {role.workType === "TEMP" && (
              <>
                <strong>Rate:</strong> {currencyFormat(role.salaryMin)} -{" "}
                {currencyFormat(role.salaryMax)} per hr
              </>
            )}
            <br />
            {role.workType === "PERMANENT" && (
              <>
                <strong>Start date:</strong>{" "}
                {new Date(role.startDate).toLocaleDateString()}
                <br />
              </>
            )}
            {role.workType === "TEMP" && (
              <>
                <strong>Placement start date:</strong>{" "}
                {new Date(role.placementStartDate).toLocaleDateString()}
                <br />
              </>
            )}
            {role.workType === "TEMP" && (
              <>
                <strong>Placement end date:</strong>{" "}
                {new Date(role.placementEndDate).toLocaleDateString()}
                <br />
              </>
            )}
          </Col>
          <Col>
            {role.workType === "TEMP" && (
              <>
                <strong>Working hours:</strong> {role.workingHours}
                <br />
              </>
            )}
            <strong>Engagement type:</strong> {role.engagementType}
            <br />
            <strong>Terms signed:</strong> {role.termsSigned ? "Yes" : "No"}
            <br />
            <strong>Replacement guarantee:</strong>{" "}
            {role.replacementGuarantee ? "Yes" : "No"}
            <br />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>{parse(role?.summary)}</Col>
        </Row>
        {showQuery && (
          <>
            <hr />
            <Form.Control
              as="textarea"
              name="description"
              rows={5}
              placeholder="Enter query ..."
              onChange={(e) => setQuery(e.target.value)}
              required
            />
            <p className="my-3">
              Queries will be anonymous. No information
              to identify you or your agency will be exchanged
              when you submit a query.
            </p>
            <ButtonRightCorner>
              <Button
                className="mx-1"
                variant="secondary"
                onClick={() => setShowQuery(false)}
              >
                  Cancel
              </Button>
              <Button
                className="mx-1"
                variant="primary"
                onClick={handleSendQuery}
              >
                Send
              </Button>
            </ButtonRightCorner>
          </>
        )}

        <hr />
        <h5>Propose a matching candidates</h5>
        <Form.Group controlId="formWorkType">
          <Form.Label>
            Select a candidates you would like to put forward for this role:
          </Form.Label>
          <ReactSelect
            onChange={(data: any) => setMultipleCandidates(data)}
            name="proposedCandidate"
            isMulti={true}
            options={proposedCandidateValue}
          />
        </Form.Group>
        <br />
        {!showQuery &&
          <Button
            onClick={() => setShowQuery(true)}
            size="sm"
          >
            <EnvelopeFill /> Agent Query
          </Button>
        }
        <hr />
        <p>
          <strong>What happens next?</strong>
        </p>
        <p>
          The Client Agency that has listed this role will receive the anonymous
          details of your candidate along with your agency name. If they choose
          to proceed, you'll receive details of their agency to confirm whether
          you'd like to proceed. If they decline, we'll let you know.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>{" "}
        <Button onClick={handleClick}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublicRoleDetailModal;
