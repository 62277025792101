import { gql } from "@apollo/client";

export const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $industryPreferences: IndustryPreferencesInput
  ) {
    putProfile(
      input: {
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        industryPreferences: $industryPreferences
      }
    ) {
      firstName
      lastName
      fullName
      phoneNumber
      role
    }
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation (
    $token: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $password: String!
  ) {
    acceptInvitation(input: {
      token: $token
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      password: $password
    }) {
      ok
      reason
    }
  }
`;

export const DISCONNECT_ATS = gql`
  mutation disconnectAts($connectionType: String!) {
    disconnect(type: $connectionType)
  }
`;

export const ADD_PARTNER = gql`
  mutation addPartner($companyId: Int!) {
    addPartner(companyId: $companyId) {
      companyId
      status
    }
  }
`;

export const APPROVE_PARTNER = gql`
  mutation approvePartner($companyId: Int!) {
    approvePartner(companyId: $companyId) {
      companyId
      status
    }
  }
`;

export const DELETE_PARTNER = gql`
  mutation removePartnership($companyId: Int!) {
    removePartnership(input :{companyId: $companyId}) {
      id
    }
  }
`;

export const INVITE_PARTNER = gql`
  mutation invitePartner($email: String!) {
    invitePartner(input: {email: $email}) {
      id
    }
  }
`;

export const ADD_PROJECT = gql`
  mutation AddProject($title: String!, $description: String) {
    addProject(input: { title: $title, description: $description }) {
      id
      title
      description
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: String!, $title: String!, $description: String) {
    updateProject(input: { id: $id, title: $title, description: $description }) {
      id
      title
      description
    }
  }
`;

export const ARCHIVE_PROJECT = gql`
  mutation ArchiveProject($projectId: String!) {
    archiveProject(projectId: $projectId) {
      id
      status
    }
  }
`;

export const SEND_INVITATION = gql`
  mutation SendInvitation($email: String!) {
    sendInvitation(input: { email: $email }) {
      ok
      reason
    }
  }
`;

export const DEACTIVATE_COMPANY_USER = gql`
  mutation DeactivateCompanyUser($userId: String!) {
    deactivateCompanyUser(userId: $userId) {
      id
      status
    }
  }
`;

export const INVITE_PROJECT_MEMBER = gql`
  mutation InviteProjectMember($id: String!, $email: String!) {
    inviteProjectMember(input: { id: $id, email: $email } ) {
      projectId
      email
    }
  }
`;
