import { Toast, ToastContainer, Button } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";
import { IToastData } from "../../interfaces";

interface INotificationProps {
  delay?: number;
  toastData: IToastData,
  onClose: () => void;
}

const Notification = ({ delay, toastData, onClose }: INotificationProps) => {
  return (
    <ToastContainer position={toastData?.position} className="p-3 z-index-1">
      <Toast bg={toastData?.color} show={toastData?.show} onClose={onClose} delay={delay ?? 3000} autohide>
        <div className="d-flex justify-content-between">
          <Toast.Body className="text-left text-white">{toastData?.message} </Toast.Body>
          <Button variant={toastData?.color} size="lg" onClick={onClose}>
            <XCircleFill />
          </Button>
        </div>
      </Toast>
    </ToastContainer>
  )
}

export default Notification;
