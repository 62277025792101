import { useMutation } from "@apollo/client";
import { Modal, Button } from "react-bootstrap";
import { ARCHIVE_PROJECT } from "../../../../GraphQL/settings/mutations";
import { EToastPosition, EToastColorStatus } from "../../../../interfaces";

interface IArchiveModalProps {
  showArchiveModal: boolean;
  onCloseModal: () => void;
  projectId: number|null;
  reloadProjects: () => void;
  onHandleCreateToast: (
    color    : EToastColorStatus,
    position : EToastPosition,
    message  : string,
  ) => void;
}

const ArchiveProjectModal = ({
  showArchiveModal,
  onCloseModal,
  projectId,
  reloadProjects,
  onHandleCreateToast = () => {},
}: IArchiveModalProps) => {
  const [archiveProject] = useMutation(ARCHIVE_PROJECT, {
    onCompleted() {
      onCloseModal();
      onHandleCreateToast(
        EToastColorStatus.SUCCESS,
        EToastPosition.BOTTOM_END,
        "Archived project successfully",
      );
      reloadProjects();
    },
    onError() {
      onHandleCreateToast(
        EToastColorStatus.FAILED,
        EToastPosition.BOTTOM_END,
        "Error, something went wrong",
      );
    },
  });

  const handleArchiveProject = () => {
    if (projectId) {
      archiveProject({variables: { projectId }});
    }
  }

  return (
    <Modal show={showArchiveModal} onHide={onCloseModal} centered>
      <Modal.Header>
        <Modal.Title>Archive Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Do you want to archive this project?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCloseModal}>
          Close
        </Button>
        <Button variant="danger" onClick={handleArchiveProject}>
          Archive
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ArchiveProjectModal;
