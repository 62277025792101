export type CandidateWorkType = "PERMANENT" | "TEMP" | "UNKNOWN";
export type CandidateStatus =
  | "PLATFORM_INCLUDED"
  | "REMOVED"
  | "SHARED"
  | "UNKNOWN";
export type JobStatus = "PLATFORM_INCLUDED" | "REMOVED" | "SHARED" | "UNKNOWN";
export type JobFeeType = "FIXED" | "PERCENTAGE" | "UNKNOWN";
export type MatchAgentStatus =
  | "SETTLED"
  | "AGENT_CONFIRMED"
  | "AGENT_REJECTED"
  | "DISPUTE"
  | "INTEREST_CONFIRMED"
  | "INTEREST_REJECTED"
  | "PENDING"
  | "SUCCESSFUL_PLACEMENT"
  | "UNKNOWN"
  | "UNSUCCESSFUL";
export type ProjectCandidateStatus = "ACCEPTED" | "REJECTED" | "OPEN";;

export enum EToastColorStatus {
  SUCCESS = "primary",
  WARNING = "warning",
  FAILED  = "danger",
}

export enum EToastPosition {
  TOP_START     = "top-start",
  MIDDLE_CENTER = "middle-center",
  BOTTOM_START  = "bottom-start",
  BOTTOM_END    = "bottom-end",
}

export enum EModalFormType {
  ID = "id",
  MANUAL = "manual",
}

export interface IToastData {
  show: boolean;
  color: EToastColorStatus;
  position: EToastPosition;
  message: string;
}

export interface Location {
  displayName: string;
  localityId: number;
}
export interface CandidateLocation {
  displayName: string;
  localityId: number;
}

export interface ICandidateOwners {
  userId: string;
  email: string;
}

export interface ProjectCandidateBoards {
  id: string;
  projectId: string;
  title: string;
  description: string;
}

export interface Attachment {
  id: string;
  externalId: string;
  projectId: string;
  candidateId: string;
}

export interface Candidate {
  id: number;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  industry?: string;
  workType?: CandidateWorkType;
  location?: CandidateLocation;
  summary?: string;
  salaryMin?: number;
  salaryMax?: number;
  yearsOfExperience?: string;
  idealJobTitles?: string;
  interviewed: boolean;
  status: CandidateStatus;
  audience?: string;
  externalId: string;
  companyName?: string;
  matches: number;
  candidateOwners: ICandidateOwners[];
  projectBoards: ProjectCandidateBoards[];
}

export interface PublicCandidate {
  id: number;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  industry?: string;
  workType: CandidateWorkType;
  location: CandidateLocation;
  summary: string;
  salaryMin: number;
  salaryMax: number;
  yearsOfExperience: string;
  idealJobTitles: string;
  interviewed: boolean;
  status: CandidateStatus;
  companyName?: string;
  externalId: string;
  currency: string;
  attachments?: Attachment[];
}

export interface ProjectCandidate {
  id: number;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  industry?: string;
  workType: CandidateWorkType;
  location: CandidateLocation;
  summary: string;
  salaryMin: number;
  salaryMax: number;
  yearsOfExperience: string;
  idealJobTitles: string;
  interviewed: boolean;
  status: CandidateStatus;
  companyName?: string;
  externalId: string;
  currency: string;
  boardStatus: ProjectCandidateStatus;
  attachments?: Attachment[];
}

export interface CandidateData {
  candidates: {
    totalCount: number;
    items: Candidate[];
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    }
  }
}

export interface PublicCandidatesData {
  publicCandidates: {
    totalCount: number;
    items: PublicCandidate[];
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    }
  }
}

export interface PartnerCandidatesData {
  partnerCandidates: {
    totalCount: number;
    items: PublicCandidate[];
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    }
  }
}

export interface ProjectCandidatesData {
  projectCandidates: {
    totalCount: number;
    items: ProjectCandidate[];
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    }
  }
}

export interface IJobOwners {
  userId: string;
  email: string;
}

export interface ProjectJobBoards {
  id: string;
  projectId: string;
  title: string;
  description: string;
}

export interface Job {
  id: number;
  title: string;
  workType: string;
  location: Location;
  locationDisplayName?: string;
  summary: string;
  clientName: string;
  industry: string;
  salaryMin: number;
  salaryMax: number;
  startDate?: string;
  placementStartDate?: string;
  placementEndDate?: string;
  workingHours?: string;
  feeType?: JobFeeType;
  split?: number;
  feeValue?: number;
  status: JobStatus;
  engagementType?: string;
  termsSigned?: boolean;
  replacementGuarantee?: boolean;
  audience: string;
  externalId: string;
  companyName?: string;
  matches: number;
  jobOwners: IJobOwners[];
  projectBoards: ProjectJobBoards[];
}

export interface PublicJob {
  id: number;
  externalId: string;
  title: string;
  workType: string;
  summary: string;
  industry: string;
  salaryMin: number;
  salaryMax: number;
  startDate: string;
  placementStartDate: string;
  placementEndDate: string;
  workingHours: string;
  status: JobStatus;
  engagementType: string;
  termsSigned: boolean;
  replacementGuarantee: boolean;
  feeMin: number;
  feeMax: number;
  location: Location;
  companyName?: string;
  currency: string;
}

export interface PublicRolesData {
  publicJobs: {
    totalCount: number;
    items: PublicJob[];
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    }
  }
}

export interface PartnerRolesData {
  partnerJobs: {
    totalCount: number;
    items: PublicJob[];
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    }
  }
}

export interface JobData {
  jobs: {
    totalCount: number;
    items: Job[]
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    }
  }
}

export interface Locality {
  id: number;
  name: string;
  state: string;
}

export interface LocalityData {
  localities: {
    totalCount: number;
    items: Locality[];
  }
}
export interface MatchType {
  id: string;
  jobId: number;
  candidateId: number;
  jobAgentStatus: MatchAgentStatus;
  candidateAgentStatus: MatchAgentStatus;
  feedback: string;
  jobAgentExpectedFee: string;
  jobAgentName: string;
  jobAgentEmail: string;
  jobAgentPhone: string;
  jobAgentExpectedStartDate: string;
  jobAgentExpectedSchedule: string;
  candidateAgentExpectedFee: string;
  candidateAgentName: string;
  candidateAgentEmail: string;
  candidateAgentPhone: string;
  candidateAgentExpectedStartDate: string;
  candidateAgentExpectedSchedule: string;
}

export interface MatchData {
  matches: {
    totalCount: number;
    items: MatchType[];
  };
}

export interface JobMatches {
  id: string;
  jobId: number;
  clientName: string;
  jobTitle: string;
  jobLocation: {
    countryCode: string;
    suburb: string;
    state: string;
    postCode: string;
    displayName: string;
    localityId: number;
  }
  candidateJobTitle: string;
  candidateIdealJobTitles: string;
  candidateLocation: {
    localityId: number;
    displayName: string;
  }
  jobAgentStatus: MatchAgentStatus;
  candidateAgentStatus: MatchAgentStatus;
  currency: string;
  createdAt: string;
  updatedAt: string;
}

export interface JobMatchesData {
  jobMatches: {
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPrevious: boolean;
    }
    items: JobMatches[];
  }
}

export interface CandidateMatches {
  id: string;
  candidateId: number;
  candidateName: string;
  jobTitle: string;
  candidateJobTitle: string;
  candidateIdealJobTitles: string;
  jobAgentStatus: MatchAgentStatus;
  candidateAgentStatus: MatchAgentStatus;
  currency: string;
  createdAt: string;
  updatedAt: string;
  jobLocation: {
    countryCode: string;
    suburb: string;
    state: string;
    postCode: string;
    displayName: string;
    localityId: number;
  }
  candidateLocation: {
    localityId: number;
    displayName: string;
  }
}

export interface CandidateMatchesData {
  candidateMatches: {
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    }
    items: CandidateMatches[];
  }
}

export interface JobTopic {
  id: number;
  message: string;
  jobId: number;
  userId: number;
  createdAt: string;
  companyId: number;
  jobTitle: string;
  locationDisplayName: string;
  lastCommentCreatedAt: string;
  isResolvedByOwner: boolean;
  isResolvedByAuthor: boolean;
}

export interface JobTopicData {
  jobTopics: {
    totalCount: number;
    items: JobTopic[];
  };
}

export interface JobTopicComment {
  id: number;
  message: string;
  userId: number;
  createdAt: string;
  isOwner: boolean;
  companyId: number;
}

export interface JobTopicCommentData {
  jobTopicComments: {
    totalCount: number;
    items: JobTopicComment[];
  };
}

export interface CandidateTopic {
  id: number;
  message: string;
  jobId: number;
  userId: number;
  createdAt: string;
  companyId: number;
  jobTitle: string;
  idealJobTitles: string;
  locationDisplayName: string;
  lastCommentCreatedAt: string;
  isResolvedByOwner: boolean;
  isResolvedByAuthor: boolean;
}

export interface CandidateTopicData {
  candidateTopics: {
    totalCount: number;
    items: CandidateTopic[];
  };
}

export interface CandidateTopicComment {
  id: number;
  message: string;
  userId: number;
  createdAt: string;
  isOwner: boolean;
  companyId: number;
}

export interface JobTopicCommentData {
  jobTopicComments: {
    totalCount: number;
    items: JobTopicComment[];
  };
}

export interface IProfile {
  profile: {
    email: string;
    firstName: string;
    fullName: string;
    lastName: string;
    phoneNumber: string;
    role: string;
    userId: number;
    companyId: number;
    industryPreferences: {
      regions: string[];
      industries: string[];
      localityIds: number[];
    }
  }
}

export interface IProject {
  id: number;
  title: string;
  description: string;
}

export interface ICompanyUser {
  id: string;
  email: string;
  status: string;
}
