import React, { useState } from "react";
import styled from 'styled-components';
import { Badge as BSBadge, Button, Card, Row, Col } from "react-bootstrap";
import { EnvelopeFill } from "react-bootstrap-icons";
import { Well } from "../../../sharedStyles";
import { MatchAgentStatus, MatchType } from "../../../interfaces";
import { GET_MATCHED_CANDIDATE } from "../../../GraphQL/candidates/queries";
import { useQuery } from "@apollo/client";
import { currencyFormat } from "../../../utils";
import MatchFooter from "../MatchFooter";
import parse from "html-react-parser";

const Badge = styled(BSBadge)`
  font-size: 1rem;

  ::before {
    content: "Status: ";
  }
  margin-bottom: 8px;
`;
interface Props {
  match: MatchType;
  setShowQuery: (show: boolean) => void;
}

const renderStatus = (status: any, otherStatus: any) => {
  if (status === "PENDING" || status === "INTEREST_CONFIRMED") {
    return <Badge bg="warning">Pending your response</Badge>;
  } else if (
    status === "AGENT_CONFIRMED" &&
    (otherStatus === "INTEREST_CONFIRMED" || otherStatus === "PENDING")
  ) {
    return (
      <Badge bg="warning">Accepted - pending response from other agent</Badge>
    );
  } else if (
    status === "AGENT_CONFIRMED" &&
    otherStatus === "AGENT_CONFIRMED"
  ) {
    return (
      <>
        <Badge>Connected</Badge>
        <Well>
          You and the Candidate Agency have been connected - please find their
          details below. You can now contact the Candidate Agency to confirm how
          the recruitment process will be managed. Please ensure you have
          confirmation of how the process will be managed in writing before any
          party contacts a referred candidate or client.
        </Well>
      </>
    );
  } else if (status === "AGENT_REJECTED" || status === "INTEREST_REJECTED") {
    return <Badge bg="danger">Rejected</Badge>;
  } else if (
    otherStatus === "AGENT_REJECTED" ||
    otherStatus === "INTEREST_REJECTED"
  ) {
    return <Badge bg="danger">Rejected by other agent</Badge>;
  } else if (
    status === "SUCCESSFUL_PLACEMENT" &&
    otherStatus === "AGENT_CONFIRMED"
  ) {
    return (
      <Badge bg="warning">
        Placement claimed - pending confirmation from other agent
      </Badge>
    );
  } else if (
    status === "AGENT_CONFIRMED" &&
    otherStatus === "SUCCESSFUL_PLACEMENT"
  ) {
    return (
      <Badge bg="warning">Placement claimed - pending your confirmation</Badge>
    );
  } else if (
    status === "AGENT_CONFIRMED" &&
    otherStatus === "SUCCESSFUL_PLACEMENT"
  ) {
    return (
      <Badge bg="warning">Placement claimed - pending your confirmation</Badge>
    );
  } else if (
    status === "SUCCESSFUL_PLACEMENT" &&
    otherStatus === "SUCCESSFUL_PLACEMENT"
  ) {
    return <Badge>Placement confirmed</Badge>;
  } else if (status === "UNSUCCESSFUL" || otherStatus === "UNSUCCESSFUL") {
    return <Badge bg="danger">Unsuccessful</Badge>;
  } else if (status === "SETTLED" && otherStatus !== "SETTLED") {
    return (
      <Badge bg="warning">
        Settlement claimed - pending confirmation from other agent
      </Badge>
    );
  } else if (status !== "SETTLED" && otherStatus === "SETTLED") {
    return (
      <Badge bg="warning">Settlement claimed - pending your confirmation</Badge>
    );
  } else if (status === "SETTLED" && otherStatus === "SETTLED") {
    return <Badge>Placement settled</Badge>;
  }

  return <Badge>Unknown status</Badge>;
};

const CandidateMatch = ({ match, setShowQuery }: Props) => {
  const [status, setStatus] = useState(match.jobAgentStatus);

  const { loading, error, data } = useQuery(GET_MATCHED_CANDIDATE, {
    variables: { matchId: `${match.id}` },
  });

  if (loading) return <p>Loading candidate...</p>;
  if (error) return <p>Error loading candidate</p>;

  const candidate = data?.matchedCandidate;

  return (
    <>
      <Card.Body>
        <div>{renderStatus(status, match.candidateAgentStatus)}</div>
        <Row>
          <Col>
            <strong>Job title:</strong> {candidate?.jobTitle}
            <br />
            <strong>Ideal job titles:</strong> {candidate?.idealJobTitles}
            <br />
            <strong>Location:</strong> {candidate?.location?.displayName}
            <br />
            <strong>Work type:</strong>{" "}
            {candidate.workType === "TEMP" ? "CONTRACT/TEMP" : "PERMANENT"}
            <br />
            <strong>Industry:</strong> {candidate.industry}
            <br />
            <strong>Salary:</strong> {currencyFormat(candidate.salaryMin)} -{" "}
            {currencyFormat(candidate.salaryMax)}
            <br />
            <strong>Years Experience: </strong>
            {candidate.yearsOfExperience}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>{parse(candidate.summary)}</Col>
        </Row>

        <hr />
        {(status === "AGENT_CONFIRMED" || status === "SUCCESSFUL_PLACEMENT") &&
          (match.candidateAgentStatus === "AGENT_CONFIRMED" ||
            match.candidateAgentStatus === "SUCCESSFUL_PLACEMENT") && (
            <p>
              <br />
              {match.candidateAgentName}
              <br />
              {match.candidateAgentEmail}
              <br />
              {match.candidateAgentPhone}
            </p>
          )}
        {match.candidateAgentStatus === "SUCCESSFUL_PLACEMENT" && (
          <p>
            <strong>Claimed expected fee:</strong>{" "}
            {match.candidateAgentExpectedFee}<br/>
            <strong>Claimed expected start date:</strong>{" "}
            {match.candidateAgentExpectedStartDate && new Date(match.candidateAgentExpectedStartDate).toLocaleDateString()}<br/>
          <strong>Claimed expected schedule:</strong>{" "}
            {match.candidateAgentExpectedSchedule}
          </p>
        )}
      </Card.Body>

      <br />
       <Button
        onClick={() => {
          setShowQuery(true);
        }}
        size="sm"
      >
        <EnvelopeFill /> Agent Query
      </Button>

      <hr />
      <MatchFooter
        type="candidate"
        match={match}
        setParentStatus={setStatus}
        agency={candidate?.companyName}
      />
      <hr />
    </>
  );
};

export default CandidateMatch;
