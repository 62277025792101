import { gql } from '@apollo/client';

export const AMEND_ROLE = gql`
  mutation amendJob(
    $id: Int!
    $engagementType: EngagementType!
    $feeType: JobFeeType!
    $feeValue: Decimal!
    $industry: String!
    $localityId: Int!
    $placementStartDate: Date!
    $placementEndDate: Date!
    $replacementGuarantee: Boolean!
    $salaryMax: Decimal!
    $salaryMin: Decimal!
    $split: Decimal!
    $startDate: Date!
    $summary: String!
    $termsSigned: Boolean!
    $title: String!
    $workType: WorkType!
    $workingHours: String!
    $share: Boolean!
    $audience: Audience!
    $guaranteeDetails: String
    $currency: Currency!
    $ownerUserIds: [Int!]
    $projectJobBoardIds: [String!]
  ) {
    amendJob(
      input: {
        id: $id
        engagementType: $engagementType
        feeType: $feeType
        feeValue: $feeValue
        industry: $industry
        localityId: $localityId
        placementStartDate: $placementStartDate
        placementEndDate: $placementEndDate
        replacementGuarantee: $replacementGuarantee
        salaryMax: $salaryMax
        salaryMin: $salaryMin
        split: $split
        startDate: $startDate
        summary: $summary
        termsSigned: $termsSigned
        title: $title
        workType: $workType
        workingHours: $workingHours
        share: $share
        audience: $audience
        guaranteeDetails: $guaranteeDetails
        currency: $currency
        ownerUserIds: $ownerUserIds
        projectJobBoardIds: $projectJobBoardIds
      }
    ) {
      id
      title
      salaryMin
      salaryMax
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation deleteJob($jobId: Int!) {
    deleteJob(jobId: $jobId) {
      id
    }
  }
`;

export const ADD_ROLE = gql`
  mutation AddRole(
    $clientName: String!
    $title: String!
    $summary: String!
    $workType: WorkType!
    $salaryMin: Decimal!
    $salaryMax: Decimal!
    $startDate: Date!
    $termsSigned: Boolean!
    $replacementGuarantee: Boolean!
    $engagementType: EngagementType!
    $feeType: JobFeeType!
    $feeValue: Decimal!
    $split: Decimal!
    $industry: String!
    $placementStartDate: Date!
    $placementEndDate: Date!
    $workingHours: String!
    $localityId: Int!
    $state: String!
    $suburb: String!
    $share: Boolean!
    $audience: Audience!
    $currency: Currency!
    $ownerUserIds: [Int!]
    $projectJobBoardIds: [String!]
  ) {
  addJob(input: {
    clientName: $clientName
    title: $title
    summary: $summary
    workType: $workType
    salaryMin: $salaryMin
    salaryMax: $salaryMax
    startDate: $startDate
    termsSigned: $termsSigned
    replacementGuarantee: $replacementGuarantee
    engagementType: $engagementType
    feeType: $feeType
    feeValue: $feeValue
    split: $split
    industry: $industry
    placementStartDate: $placementStartDate
    placementEndDate: $placementEndDate
    workingHours: $workingHours
    localityId: $localityId
    currency: $currency
    address: {
      state: $state
      suburb: $suburb
    }
    share: $share
    audience: $audience
    ownerUserIds: $ownerUserIds
    projectJobBoardIds: $projectJobBoardIds
  }){
    id,
    externalId
  }
}
`;

export const IMPORT_ROLE = gql`
  mutation ImportJob($jobId: Int!) {
    importJob(jobId: $jobId) {
        id,
        externalId
    }
  }
`;

export const ARCHIVE_JOB = gql`
  mutation ArchiveJob($jobId: String!) {
    archiveJob(jobId: $jobId) {
      id
      status
    }
  }
`;

export const ACCEPT_PROJECT_JOB = gql`
  mutation AcceptProjectJob(
    $projectJobBoardId: String!
    $jobId: String!
  ) {
    acceptProjectJob(
      projectJobBoardId: $projectJobBoardId
      jobId: jobId
    ) {
      projectJobBoardId
      jobId
      status
    }
  }
`;

export const REJECT_PROJECT_JOB = gql`
  mutation RejectProjectJob(
    $projectJobBoardId: String!
    $jobId: String!
  ) {
    rejectProjectJob(
      projectJobBoardId: $projectJobBoardId
      jobId: jobId
    ) {
      projectJobBoardId
      jobId
      status
    }
  }
`;


