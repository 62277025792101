
import { Modal, Button } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_CANDIDATE, ARCHIVE_CANDIDATE } from "../../../GraphQL/candidates/mutations";
import { GET_CANDIDATE_BY_ID } from "../../../GraphQL/candidates/queries";
import { EToastColorStatus, EToastPosition } from "../../../interfaces";

interface IProps {
  id: number | null;
  show: boolean;
  onHideModal: () => void;
  reloadCandidates: () => void;
  onCreateToast?: (
    color: EToastColorStatus,
    position: EToastPosition,
    message: string,
  ) => void;
}

const CandidateDeleteModal = ({id, show, onHideModal, reloadCandidates, onCreateToast = () => {}}: IProps) => {
  const { loading, data } = useQuery(GET_CANDIDATE_BY_ID, { variables: { id: Number(id) }});

  const [deleteCandidate] = useMutation(DELETE_CANDIDATE, {
    onCompleted(){
      reloadCandidates();
      onHideModal();
      onCreateToast(
        EToastColorStatus.SUCCESS,
        EToastPosition.BOTTOM_END,
        "Candidate deleted!",
      );
    },
    onError(error: any){
      const errorMessage = error?.networkError?.result?.errors[0]?.message;
      onHideModal();
      onCreateToast(
        EToastColorStatus.FAILED,
        EToastPosition.BOTTOM_END,
        errorMessage,
      );
    },
  });

  const [archiveCandidate] = useMutation(ARCHIVE_CANDIDATE, {
    onCompleted(){
      reloadCandidates();
      onHideModal();
      onCreateToast(
        EToastColorStatus.SUCCESS,
        EToastPosition.BOTTOM_END,
        "Candidate archived!",
      );
    },
  });

  const handleDeleteCandidate = () => {
    if (id === null) return
    deleteCandidate({
      variables: {
        candidateId: Number(id),
      },
    });
  }

  const handleArchiveCandidate = () => {
    if (id === null) return
    archiveCandidate({
      variables: {
        candidateId: `${id}`,
      },
    });
  }

  if (loading) {
    return (
      <Modal show={show} onHide={onHideModal} centered>
        <Modal.Header>
          <Modal.Title>Loading...</Modal.Title>
        </Modal.Header>
        <Modal.Body>Loading...</Modal.Body>
      </Modal>
    )
  };

  if (data?.candidates?.items[0]?.matches > 0) {
    return (
      <Modal show={show} onHide={onHideModal} centered>
        <Modal.Header>
          <Modal.Title>Archive Candidate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This candidate can not be deleted due to associated matches.
          Do you wish to archive this candidate instead?
          Archived candidates are hidden from your list of candidates.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleArchiveCandidate}>
            Archive
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <Modal show={show} onHide={onHideModal} centered>
      <Modal.Header>
        <Modal.Title>Delete Candidate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this candidate? This process cannot be undone.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHideModal}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDeleteCandidate}>Delete</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CandidateDeleteModal;
