import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_COMPANY_USERS } from "../../../GraphQL/settings/queries";
import { Button, Card, Table, Badge } from "react-bootstrap";
import { IToastData, EToastColorStatus, EToastPosition, IProfile, ICompanyUser } from "../../../interfaces";
import { ButtonRightCorner } from "../../../sharedStyles";
import DeactivateModal from "./DeactivateModal";
import InviteUserModal from "./InviteUserModal";
import Notification from "../../Notification";

const Subscriptions = ({ profile }: IProfile) => {
  const [companyUsers, setCompanyUsers] = useState([] as ICompanyUser[]);
  const [userId, setUserId] = useState<string|null>(null);
  const [showModalDeactivate, setShowModalDeactivate] = useState(false);
  const [showModalInvite, setShowModalInvite] = useState(false);
  const [toastData, setToastData] = useState<IToastData>({
    show     : false,
    color    : EToastColorStatus.SUCCESS,
    position : EToastPosition.BOTTOM_END,
    message  : "",
  });

  const { loading, error, data, refetch } = useQuery(GET_COMPANY_USERS, {
    variables: { skip: 0, take: 20 },
  });

  useEffect(() => {
    setCompanyUsers(data?.companyUsers?.items);
  }, [data]);

  const userBadge = (status: string) => {
    if (status === "ACTIVE") return <Badge bg="primary">Active</Badge>;
    return <Badge bg="secondary">Deactivated</Badge>;
  }

  const handleShowToast = (
    color    : EToastColorStatus,
    position : EToastPosition,
    message  : string,
  ) => {
    setToastData({ show: true, color, position, message });
  };

  const onHandleDeactivate = (id: string) => {
    setUserId(id);
    setShowModalDeactivate(true);
  }

  return (
    <Card className="border-0">
      <Card.Body className="p-0">
        <Table bordered hover>
          <thead>
            <tr>
              <th className="w-50">Email</th>
              <th className="w-25 text-center">Status</th>
              <th className="w-25 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading && <tr><td>Loading...</td></tr>}
            {error && <tr><td>Error: {error?.message}</td></tr>}
            {!loading && !error && companyUsers && companyUsers.map((item: ICompanyUser) => {
              if (profile?.email === item?.email) return null;
              return (
                <tr key={item?.id}>
                  <td>{item?.email ?? "-"}</td>
                  <td className="text-center">{userBadge(item?.status)}</td>
                  <td className="text-center">
                    <Button
                      disabled={item?.status !== "ACTIVE"}
                      size="sm"
                      className="mx-1"
                      variant="secondary"
                      onClick={() => item?.status === "ACTIVE" ? onHandleDeactivate(item?.id) : null}
                    >
                      Deactivate
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        <ButtonRightCorner>
          <Button
            size="sm"
            onClick={() => setShowModalInvite(true)}
          >
            Invite User
          </Button>
        </ButtonRightCorner>
      </Card.Body>

      <InviteUserModal
        showModalInvite={showModalInvite}
        onCloseModalInvite={() => setShowModalInvite(false)}
        onHandleCreateToast={(
          color    : EToastColorStatus,
          position : EToastPosition,
          message  : string,
        ) => handleShowToast(color, position, message)}
      />

      <DeactivateModal
        userId={userId}
        showModalDeactivate={showModalDeactivate}
        onCloseModal={() => setShowModalDeactivate(false)}
        reloadCompanyUsers={refetch}
        onHandleCreateToast={(
          color    : EToastColorStatus,
          position : EToastPosition,
          message  : string,
        ) => handleShowToast(color, position, message)}
      />

      <Notification
        delay={3000}
        toastData={toastData}
        onClose={() => setToastData({ ...toastData, show: false })}
      />
    </Card>
  )
}

export default Subscriptions;
