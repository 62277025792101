import * as Yup from "yup";
import styled from "styled-components";
import { useFormik } from "formik";
import { Button, Card, Form } from "react-bootstrap";
import { ButtonRightCorner, ErrorWrapper, PageContainer } from "../../sharedStyles";

const newCompanyUserSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  password: Yup.string()
  .required("Password is required")
  .min(8, 'Password must have a minimum of 8 characters')
  .matches(/\d+/, "Password must contain at least one number")
  .matches(/[a-z]+/, "Password must contain lowercase character (a-z)")
  .matches(/[A-Z]+/, "Password must contain uppercase character (A-Z)")
  .matches(/[@$/!%*<>+#?&~()^:{}]+/, "Password must contain a special character like : @$/!%*<>+#?&~()^:{}"),
});

const CustomCard = styled(Card)`
  width: 36rem;
  min-height: 20rem;
`;

const NewCompanyUser = ({user}: any) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      password: '',
    },
    validationSchema: newCompanyUserSchema,
    onSubmit: (values) => {
      console.log(values)
    },
    enableReinitialize: true,
  });

  const { errors, touched } = formik;

  return (
    <PageContainer className="d-flex align-items-center justify-content-center vh-100">
      <CustomCard>
        <Card.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                placeholder="Your name"
                onChange={formik.handleChange}
                value={formik?.values?.name}
              />
              <ErrorWrapper>
                {errors?.name && touched?.name ? errors?.name : ''}
              </ErrorWrapper>
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder="Type your password"
                onChange={formik.handleChange}
                value={formik?.values?.password}
              />
              <ErrorWrapper>
                {errors?.password && touched?.password ? errors?.password : ''}
              </ErrorWrapper>
            </Form.Group>
          </Form>
        </Card.Body>
        <Card.Footer>
          <ButtonRightCorner>
            <Button
              variant="primary"
              onClick={formik.submitForm}
            >
              Save
            </Button>
          </ButtonRightCorner>
        </Card.Footer>
      </CustomCard>
    </PageContainer>
  )
}

export default NewCompanyUser;
