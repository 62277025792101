import { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup, Toast, ToastContainer } from "react-bootstrap";
import MessageThread from "./MessageThread";
import styled from "styled-components";
import { SendFill } from "react-bootstrap-icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_CANDIDATE_TOPIC_COMMENT,
  CREATE_JOB_TOPIC_COMMENT,
} from "../../GraphQL/messages/mutations";
import {
  GET_ROLE_TOPIC_COMMENTS,
  GET_CANDIDATE_TOPIC_COMMENTS,
} from "../../GraphQL/messages/queries";

const TAKE_VALUE: number = 50;

const JobTitle = styled.div`

  font-size: 16px;
  strong {
    font-weight: 700;
  }
`;

const SendButton = styled.span`
  margin-left: 10px;
`;

interface IFloatingProps {
  isOpenChat: boolean;
  onHideModal: () => void;
  topic: any;
  userProfile: any;
  client: any;
  isRole: boolean;
}

const FloatingChatMessage = ({isOpenChat, onHideModal = () => {}, topic, userProfile, client, isRole}: IFloatingProps) => {
  const [messageQuery, setMessageQuery] = useState<string>("");
  const [showToast, setShowToast] = useState(false);
  const [comments, setComments] = useState([]);
  const [skipVal, setSkipVal] = useState<number>(0);
  const [hasNext, setHasNext] = useState(false);
  const [takeMessage, setTakeMessage] = useState(TAKE_VALUE);
  const [isSent, setIsSent] = useState(false);

  const { loading, refetch, fetchMore } = useQuery(isRole ? GET_ROLE_TOPIC_COMMENTS : GET_CANDIDATE_TOPIC_COMMENTS, {
    variables: {
      topicId: topic?.id,
      skip: skipVal,
      take: TAKE_VALUE,
    },
    onCompleted: data => {
      const commentsLength = isRole ? data.jobTopicComments.items.length : data.candidateTopicComments.items.length;
      if (commentsLength > 0){
        setComments(isRole ? data.jobTopicComments.items : data.candidateTopicComments.items);
      }
      setHasNext(isRole ?
        data?.jobTopicComments?.pageInfo?.hasNextPage :
        data?.candidateTopicComments?.pageInfo?.hasNextPage
      );
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [createChatMessage, { loading: loadingCreateChat }] = useMutation(
    isRole? CREATE_JOB_TOPIC_COMMENT : CREATE_CANDIDATE_TOPIC_COMMENT,
    {
      onCompleted(){
        let takeValue = takeMessage;
        if (takeMessage >= 60) takeValue = 50;
        refetch({topicId: topic?.id, skip: 0, take: takeValue});
        setMessageQuery("");
        setIsSent(true);
      },
    },
  );

  useEffect(() => {
    setSkipVal(0);
    setTakeMessage(0);
  }, [topic?.id]);

  const handleSendMessage = () => {
    if (messageQuery === "") {
      setShowToast(true);
      return;
    } else {
      createChatMessage({ variables: {topicId: `${topic?.id}`, message: messageQuery }});
      setTakeMessage(takeMessage + TAKE_VALUE);
    }
  }

  const handleLoadMore = () => {
    setSkipVal(val => val + TAKE_VALUE);
    const updateData = async () => {
      const { data } = await fetchMore({
        variables: {
          topicId: `${topic?.id}`,
          skip: skipVal + TAKE_VALUE,
          take: TAKE_VALUE,
        },
      });
      const newEl: [] = isRole ? data?.jobTopicComments?.items : data?.candidateTopicComments?.items;
      setComments([...comments, ...newEl]);
      setTakeMessage(takeMessage + TAKE_VALUE);
    }
    updateData();
  }

  return (
    <Modal size="lg" show={isOpenChat} onHide={onHideModal}>
      <Modal.Header closeButton>
        <JobTitle>Role: <strong>{topic?.jobTitle} {topic?.locationDisplayName}</strong></JobTitle>
      </Modal.Header>
      <Modal.Body>
        <MessageThread
          topic={topic}
          userProfile={userProfile}
          comments={comments}
          onLoadMore={handleLoadMore}
          hasNext={hasNext}
          onLoading={loading}
          isSent={isSent}
        />
      </Modal.Body>
      <Modal.Footer>
        <InputGroup>
          <Form.Control
            as="textarea"
            placeholder="Type your new message here..."
            onChange={e => setMessageQuery(e.target.value)}
            value={messageQuery}
          />
        </InputGroup>
        <Button
          size="sm"
          variant={loadingCreateChat ? "secondary" : "primary"}
          disabled={loadingCreateChat}
        >
          <SendFill />
          <SendButton onClick={handleSendMessage}>Send Message</SendButton>
        </Button>
      </Modal.Footer>
      <ToastContainer position="bottom-end" className="position-fixed p-3" style={{ zIndex: 1 }}>
        <Toast bg="danger" onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Body className="text-center text-white">Message is empty</Toast.Body>
        </Toast>
      </ToastContainer>
    </Modal>
  )
}

export default FloatingChatMessage;
