import { useContext } from "react";
import { useQuery } from "@apollo/client";
import { ProfileContext } from "../../App";
import { Card, Col, Row, Alert, Badge } from "react-bootstrap";
import styled from "styled-components";
import { ArrowRightCircleFill, QuestionCircle } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { GET_LOCALITIES } from "../../GraphQL/settings/queries";
import { LocalityData } from "../../interfaces";

import background from "../../assets/bg.jpg";

const DashboardContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    width: 1024px;
  }
`;

const DashCard = styled(Card)`
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: brightness(400%) blur(10px);
  text-align: center;
  min-height: 100%;
`;

const DashCardLeft = styled(DashCard)`
  text-align: left;
`;

const DashHead = styled.h1`
  color: white !important;
  margin-bottom: 20px;
`;

const DashCta = styled.div`
  text-align: right;
  align-self: flex-end;
  a {
    color: #003f34;
    padding: 8px 12px;
    background-color: #f5cd77;
    box-shadow: 0px 1px 3px #003f34;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
  }
`;

const EventItem = styled.div`
  border-bottom: 1px solid #6a9c93;
  padding-top: 5px;
  padding-bottom: 5px;

  a {
    color: #00715c;
  }
`;

const Tag = styled.span`
  display: inline-block;
  background-color: #419285;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
`;

const NumberCircle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #003f34;
  line-height: 36px;
  display: inline-block;
  width: 80px;
  height: 40px;
  background-color: #daf7fd;
  text-align: center;
  border-radius: 40px;
  border: 1px solid #6a9c93;
  margin-right: 8px;
`;

const StatRow = styled.div`
  display: flex;
  font-weight: bold;
  align-items: center;
  margin-bottom: 10px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  justify-content: space-between;
`;

const Dashboard = () => {
  const profile = useContext(ProfileContext);
  const { data: dataLocalities } = useQuery<LocalityData>(GET_LOCALITIES);
  const localities = dataLocalities?.localities?.items
    ? [...dataLocalities?.localities?.items]
    : [];

  const getLocality = (lId: number) => {
    const l = localities.filter((obj) => {
      return obj.id === lId;
    });

    if (l.length) {
      return l[0].name;
    }
    return "";
  };

  return (
    <DashboardContainer>
      <Helmet>
        <style>{`body { background-image: url(${background}) !important; background-size: cover }`}</style>
      </Helmet>
      <Row>
        <Col>
          <DashHead>
            Welcome {profile?.userProfile?.firstName ?? "User"}!
          </DashHead>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert variant="info" dismissible={true}>
            <strong>Using JobAdder?</strong>{" "}
            <a href="#">Click here to connect your ATS!</a>{" "}
            <ArrowRightCircleFill />
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <DashCardLeft>
            <Card.Header>
              <strong>Your Roles</strong>
            </Card.Header>
            <Card.Body>
              <BodyContainer>
                <div>
                  <StatRow>
                    <NumberCircle>27</NumberCircle> shared publicly
                  </StatRow>
                  <StatRow>
                    <NumberCircle>17</NumberCircle> shared with partners
                  </StatRow>
                  <StatRow>
                    <NumberCircle>197</NumberCircle> included in network
                  </StatRow>
                </div>

                {/* <Active><ActiveCount>27</ActiveCount> active</Active>
              <Imported><ImportedCount>257</ImportedCount> imported into network</Imported> */}
                <DashCta>
                  <a href="#">
                    Manage your roles <ArrowRightCircleFill />
                  </a>{" "}
                </DashCta>
              </BodyContainer>
            </Card.Body>
          </DashCardLeft>
        </Col>
        <Col>
          <DashCardLeft>
            <Card.Header>
              <strong>Your Candidates</strong>
            </Card.Header>
            <Card.Body>
              <BodyContainer>
                <div>
                  <StatRow>
                    <NumberCircle>42</NumberCircle> shared publicly
                  </StatRow>
                  <StatRow>
                    <NumberCircle>61</NumberCircle> shared with partners
                  </StatRow>
                  <StatRow>
                    <NumberCircle>3,577</NumberCircle> included in network
                  </StatRow>
                </div>
                <DashCta>
                  <a href="#">
                    Manage your candidates <ArrowRightCircleFill />
                  </a>{" "}
                </DashCta>
              </BodyContainer>
            </Card.Body>
          </DashCardLeft>
        </Col>
        <Col>
          <DashCardLeft>
            <Card.Header>
              <strong>Your Partnerships</strong>
            </Card.Header>
            <Card.Body>
              <BodyContainer>
                <div>
                  <StatRow>
                    <NumberCircle>3</NumberCircle> active
                  </StatRow>
                  <StatRow>
                    <NumberCircle>2</NumberCircle> open invitation
                  </StatRow>
                </div>
                <DashCta>
                  <a href="#">
                    Manage your partnerships <ArrowRightCircleFill />
                  </a>{" "}
                </DashCta>
              </BodyContainer>
            </Card.Body>
          </DashCardLeft>{" "}
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <DashCardLeft>
            <Card.Header>
              <strong>Latest events</strong>
            </Card.Header>
            <Card.Body>
              <EventItem>
                You have a new match suggestion on role:{" "}
                <strong>Vela APX - Developer</strong>{" "}
                <a href="#">
                  <ArrowRightCircleFill />
                </a>
              </EventItem>
              <EventItem>
                You have a new match suggestion on candidate:{" "}
                <strong>Mary T. - Accountant</strong>{" "}
                <a href="#">
                  <ArrowRightCircleFill />
                </a>
              </EventItem>
              <EventItem>
                You have a new partner match suggestion:{" "}
                <strong>Aquent consulting</strong>{" "}
                <a href="#">
                  <ArrowRightCircleFill />
                </a>
              </EventItem>
              <EventItem>
                You have a <strong>12 unread messages</strong>{" "}
                <a href="#">
                  <ArrowRightCircleFill />
                </a>
              </EventItem>
            </Card.Body>
          </DashCardLeft>
        </Col>
      </Row>

      <br />
      <Row>
        <Col>
          <DashCardLeft>
            <Card.Header>
              <strong>Industry preferences</strong>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <strong>Regions</strong>
                  <br />{" "}
                  {profile?.userProfile?.industryPreferences?.regions?.map(
                    (ind: string) => (
                      <>
                        <Tag>{ind}</Tag>{" "}
                      </>
                    )
                  )}
                  <br />
                  <strong>Locations</strong>
                  <br />{" "}
                  {profile?.userProfile?.industryPreferences?.localityIds?.map(
                    (ind: string) => (
                      <>
                        <Tag>{getLocality(parseInt(ind))}</Tag>{" "}
                      </>
                    )
                  )}
                  <br />
                  <strong>Industries</strong>
                  <br />{" "}
                  {profile?.userProfile?.industryPreferences?.industries?.map(
                    (ind: string) => (
                      <>
                        <Tag>{ind}</Tag>{" "}
                      </>
                    )
                  )}
                </Col>
                <Col>
                  <p>
                    Target:{" "}
                    <strong>
                      Looking for international partnerships <QuestionCircle />
                    </strong>
                  </p>
                  <p>
                    Role Relay is currently scouring the network for role,
                    candidate and partnership matches based on your data and
                    target preferences!
                  </p>
                  <br />
                  <DashCta>
                    <a href="#">
                      Update your preferences <ArrowRightCircleFill />
                    </a>
                  </DashCta>
                </Col>
              </Row>
            </Card.Body>
          </DashCardLeft>
        </Col>
      </Row>
      <br />

      <br />
      <br />
      <br />
    </DashboardContainer>
  );
};

export default Dashboard;
