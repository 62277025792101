import { useMutation } from "@apollo/client";
import { Modal, Button } from "react-bootstrap";
import { EToastColorStatus, EToastPosition } from "../../../../interfaces";
import { DEACTIVATE_COMPANY_USER } from "../../../../GraphQL/settings/mutations";

interface IDeactivateProps {
  showModalDeactivate: boolean;
  onCloseModal: () => void;
  userId: string | null;
  reloadCompanyUsers: () => void;
  onHandleCreateToast: (
    color    : EToastColorStatus,
    position : EToastPosition,
    message  : string,
  ) => void;
}

const DeactivateModal = ({
  showModalDeactivate,
  onCloseModal,
  userId,
  reloadCompanyUsers,
  onHandleCreateToast = () => {},
}: IDeactivateProps) => {
  const [deactivateUser] = useMutation(DEACTIVATE_COMPANY_USER, {
    onCompleted() {
      reloadCompanyUsers();
      onHandleCreateToast(
        EToastColorStatus.SUCCESS,
        EToastPosition.BOTTOM_END,
        "User successfully deactivated",
      );
    },
    onError() {
      onHandleCreateToast(
        EToastColorStatus.FAILED,
        EToastPosition.BOTTOM_END,
        "Error, something went wrong",
      );
    },
  });

  const handleDeactivate = () => {
    if (userId) {
      deactivateUser({ variables: { userId }});
    }
    onCloseModal();
  }

  return (
    <Modal show={showModalDeactivate} onHide={onCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Deactivate User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to deactivate this user? This process cannot be undone.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCloseModal}>
          Close
        </Button>
        <Button variant="danger" onClick={handleDeactivate}>
          Deactivate
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeactivateModal;
