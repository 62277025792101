import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Pagination } from "react-bootstrap";

export type CustomPaginationType = {
  setPagination: (args: {
    skip?: number;
    take?: number;
    totalCount?: number;
    hasNextPage?: boolean;
  }) => void;
};

interface Props {
  onPaginationChange: (args: { skip: number; take: number }) => void;
}

const CustomPagination = forwardRef<CustomPaginationType, Props>(
  (props, ref) => {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    useImperativeHandle(ref, () => ({
      setPagination({
        skip,
        take,
        totalCount,
        hasNextPage,
      }: {
        skip?: number;
        take?: number;
        totalCount?: number;
        hasNextPage?: boolean;
      }) {
        if (skip !== undefined) setSkip(skip);
        if (take !== undefined) setTake(take);
        if (totalCount !== undefined) setTotalCount(totalCount);
        if (hasNextPage !== undefined) setHasNextPage(hasNextPage);
      },
    }));

    const updatePagination = async (args: { skip: number; take: number }) => {
      setSkip(args.skip);
      setTake(args.take);
      await props.onPaginationChange(args);
    };

    let lastpage = 0;
    if (totalCount) {
      lastpage = Math.ceil(totalCount / take);
    }

    let currentPage = 1;
    if (skip !== 0) currentPage = skip / take + 1;

    const pagesToDisplay = [];
    pagesToDisplay.push(currentPage);
    for (let i = 1; i < 5; i++) {
      if (lastpage && currentPage + i < lastpage)
        pagesToDisplay.push(currentPage + i);
      if (currentPage - i > 0) pagesToDisplay.push(currentPage - i);
    }
    pagesToDisplay.sort((a, b) => a - b);

    // if pagesToDisplay is longer than 5, remove the element furthest from the current page
    while (pagesToDisplay.length > 5) {
      if (
        currentPage - pagesToDisplay[0] <
        pagesToDisplay[pagesToDisplay.length - 1] - currentPage
      ) {
        pagesToDisplay.pop();
      } else {
        pagesToDisplay.shift();
      }
    }

    if (!take) return null;

    return (
      <>
        <Pagination size="sm">
          {pagesToDisplay.indexOf(1) === -1 ? (
            <Pagination.Item
              key={1}
              onClick={() => {
                updatePagination({
                  skip: 0,
                  take,
                });
              }}
            >
              1
            </Pagination.Item>
          ) : null}

          {currentPage > 2 && pagesToDisplay.indexOf(2) === -1 ? (
            <Pagination.Ellipsis />
          ) : null}

          {pagesToDisplay.map((page) => (
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => {
                updatePagination({
                  skip: (page - 1) * take,
                  take,
                });
              }}
            >
              {page}
            </Pagination.Item>
          ))}

          {totalCount &&
          pagesToDisplay.indexOf(lastpage - 1) === -1 &&
          currentPage < lastpage - 1 ? (
            <Pagination.Ellipsis />
          ) : null}
          {totalCount && pagesToDisplay.indexOf(lastpage) === -1 ? (
            <Pagination.Item
              key={lastpage}
              onClick={() => {
                updatePagination({
                  skip: (lastpage - 1) * take,
                  take,
                });
              }}
            >
              {lastpage}
            </Pagination.Item>
          ) : null}
          {!totalCount && hasNextPage ? (
            <Pagination.Next
              key={"next"}
              onClick={() => {
                updatePagination({
                  skip: currentPage * take,
                  take,
                });
              }}
            />
          ) : null}
        </Pagination>
      </>
    );
  }
);

export default CustomPagination;
