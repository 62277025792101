import React, { useState, useEffect, useContext, useRef } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Container, Card, Tabs, Tab, Table, Button } from "react-bootstrap";
import { SendCheck, EyeFill, Check2Square } from "react-bootstrap-icons";
import { ProfileContext } from "../../App";
import {
  GET_ROLE_TOPICS,
  GET_CANDIDATE_TOPICS,
} from "../../GraphQL/messages/queries";
import { CREATE_CANDIDATE_RESOLVING_MESSAGE, CREATE_ROLE_RESOLVING_MESSAGE } from '../../GraphQL/messages/mutations';
import { JobTopic, CandidateTopic, EToastPosition, EToastColorStatus } from "../../interfaces";
import { useNavigate, useParams } from "react-router-dom";
import FloatingChatMessage from "./FloatingChatMessage";
import { formatStringToDate } from '../../utils';
import ChevronTableHeader from "../Icons/ChevronTableHeader";
import styled from "styled-components";
import { compareSortingData } from "../../utils";
import Toggle from "react-toggle";
import useCheckMobile from "../../utils/hooks/useCheckMobile";
import CustomPagination, { CustomPaginationType } from "../Global/CustomPagination";
import { TabCardContainer } from "../../sharedStyles";
import Notification from "../Notification";

import guide from "../../assets/role_relay_user_guide.pdf"

const MessageTableTitle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const TD = styled.td`
  vertical-align: middle;
`;

const TDC = styled(TD)`
  cursor: pointer;
`;

const ToggleContainer = styled.div`
  display: flex;
  position: absolute;
  right: 15px;
  top: -36px;
`;

const ToggleMobileContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TextResolved = styled.p`
  margin-left: 20px;
  vertical-align: middle;
  font-size: small;
  color: #777;
`;

interface Props {
  user: any;
  activeTab?: string
}

const Messages = ({ user, activeTab }: Props) => {
  const { topicType } = useParams();
  const client = useApolloClient();
  const navigate = useNavigate();
  const { width } = useCheckMobile();
  const isMobile = width < 960;
  const [jobTopics, setJobTopics] = useState<JobTopic[]>([]);
  const [candidateTopics, setCandidateTopics] = useState<CandidateTopic[]>([]);

  const [tabKey, setTabKey] = useState(activeTab);
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [topicItem, setTopicItem] = useState("");
  const [hideRoleResolved, setHideRoleResolved] = useState(true);
  const [hideCandidateResolved, setHideCandidateResolved] = useState(true);
  const [roleHasNext, setRoleHasNext] = useState(false);
  const [candidateHasNext, setCandidateHasNext] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const initSortingRoles = { jobTitle: false, lastCommentCreatedAt: false };
  const [activeSortRoles, setActiveSortRoles] = useState(initSortingRoles);
  const [isRoleASC, setIsRoleASC] = useState(true);
  const resetRoleSort = () => setActiveSortRoles(initSortingRoles);

  const initSortingCandidates = { idealJobTitles: false, lastCommentCreatedAt: false };
  const [activeSortCandidates, setActiveSortCandidates] = useState(initSortingCandidates);
  const [isCandidateASC, setIsCandidateASC] = useState(true);
  const resetCandidateSort = () => setActiveSortCandidates(initSortingCandidates);

  const { userProfile } = useContext(ProfileContext);
  //  const isDemo = user?.username?.includes("@rolerelay.com");

  const roleQueriesPaginationRef = useRef<CustomPaginationType>(null);
  const candidateQueriesPaginationRef = useRef<CustomPaginationType>(null);

  const { refetch: refetchRoles, data: roleTopicsData } = useQuery(GET_ROLE_TOPICS, {
    variables: {skip: 0, take: 25, hideResolved: hideRoleResolved, order: {updatedAt: "DESC"}},
    onCompleted: (data) => {
      setJobTopics(data?.jobTopics?.items);
      setRoleHasNext(data?.jobTopics?.pageInfo?.hasNextPage || data?.jobTopics?.pageInfo?.hasPreviousPage);
    }
  });

  const { refetch: refetchCandidates, data: candidateTopicsData } = useQuery(GET_CANDIDATE_TOPICS, {
    variables: {skip: 0, take: 25, hideResolved: hideCandidateResolved, order: {updatedAt: "DESC"}},
    onCompleted: (data) => {
      setCandidateTopics(data?.candidateTopics?.items);
      setCandidateHasNext(data?.candidateTopics?.pageInfo?.hasNextPage || data?.candidateTopics?.pageInfo?.hasPreviousPage);
    }
  });

  const [resolveRole] = useMutation(
    CREATE_ROLE_RESOLVING_MESSAGE,
    {
      onCompleted() {
        refetchRoles();
        setShowToast(true);
        setJobTopics(roleTopicsData?.jobTopics?.items);
      },
    }
  );

  const [resolveCandidate] = useMutation(
    CREATE_CANDIDATE_RESOLVING_MESSAGE,
    {
      onCompleted(){
        refetchCandidates();
        setShowToast(true);
        setCandidateTopics(candidateTopicsData?.candidateTopics?.items);
      },
    }
  );

  const runPagination = (skip = 0, take = 25) => {
    const getRolesList = async () => {
      const { data: refreshRoles } = await refetchRoles({skip, take, hideResolved: hideRoleResolved, order: {updatedAt: "DESC"}});
      setJobTopics(refreshRoles?.jobTopics?.items);
      setRoleHasNext(refreshRoles?.jobTopics?.pageInfo?.hasNextPage || refreshRoles?.jobTopics?.pageInfo?.hasPreviousPage);
      roleQueriesPaginationRef.current?.setPagination({
        skip,
        take,
        totalCount: refreshRoles?.jobTopics?.totalCount,
      });
    }

    const getCandidatesList = async () => {
      const { data: refreshCandidates } = await refetchCandidates({skip, take, hideResolved: hideCandidateResolved, order: {updatedAt: "DESC"}});
      setCandidateTopics(refreshCandidates?.candidateTopics?.items);
      setCandidateHasNext(refreshCandidates?.candidateTopics?.pageInfo?.hasNextPage || refreshCandidates?.candidateTopics?.pageInfo?.hasPreviousPage);
      candidateQueriesPaginationRef.current?.setPagination({
        skip,
        take,
        totalCount: refreshCandidates?.candidateTopics?.totalCount,
      });
    }

    if (tabKey === "roles") getRolesList();
    if (tabKey === "candidates") getCandidatesList();
  }

  useEffect(() => {
    runPagination();
  }, [hideRoleResolved, hideCandidateResolved, tabKey, roleHasNext, candidateHasNext]);

  useEffect(() => {
    if (tabKey === "roles" || topicType === "j" || topicType === "jc") {
      setActiveTab("roles");
      if (topicType === undefined) navigate("/messages/roles");
    } else if (tabKey === "candidates" || topicType === "c" || topicType === "cc") {
      setActiveTab("candidates");
      if (topicType === undefined) navigate("/messages/candidates");
    }
  }, [tabKey, topicType]);

  const setActiveTab = (tab: string | null) => {
    if (tab) {
      setTabKey(tab);
    }
  };

  const handleFloatingChat = (item: any) => {
    setIsOpenChat(val => !val);
    setTopicItem(item);
  }

  const handleSortingRoles = (tableTitle: string) => {
    let sortingDataRoles = jobTopics.slice(0);
    sortingDataRoles.sort((a, b) => compareSortingData(a, b, tableTitle));
    setIsRoleASC(value => !value);
    isRoleASC ?
      setJobTopics(sortingDataRoles) :
      setJobTopics(sortingDataRoles.reverse());
    resetRoleSort();
    setActiveSortRoles((prev) => { return { ...prev, [tableTitle]: true }});
  }

  const handleSortingCandidates = (tableTitle: string) => {
    let sortingDataCandidates = candidateTopics.slice(0);
    sortingDataCandidates.sort((a, b) => compareSortingData(a, b, tableTitle));
    setIsCandidateASC(value => !value);
    isCandidateASC ?
      setCandidateTopics(sortingDataCandidates) :
      setCandidateTopics(sortingDataCandidates.reverse());
    resetCandidateSort();
    setActiveSortCandidates((prev) => { return { ...prev, [tableTitle]: true }});
  }

  const handleRoleResolvingMessage = (id: number) => {
    resolveRole({
      variables: {
        jobTopicId: id
      },
    });
  }

  const handleCandidateResolvingMessage = (id: number) => {
    resolveCandidate({
      variables: {
        candidateTopicId: id,
      },
    });
  }

  return (
    <Container>
      <Tabs activeKey={tabKey} onSelect={setActiveTab}>
        <Tab eventKey="roles" title="Role Queries" style={{position: 'relative'}}>
          {!isMobile && (
            <ToggleContainer>
              <Toggle
                checked={!hideRoleResolved}
                icons={false}
                onChange={() => setHideRoleResolved(val => !val)}
              />
              <TextResolved>show resolved queries</TextResolved>
            </ToggleContainer>
          )}
          <TabCardContainer>
            <Card.Body>
              {isMobile && (
                <ToggleMobileContainer>
                  <Toggle
                    checked={!hideRoleResolved}
                    icons={false}
                    onChange={() => setHideRoleResolved(val => !val)}
                  />
                  <TextResolved>show resolved queries</TextResolved>
                </ToggleMobileContainer>
              )}
              <Table bordered>
                <thead>
                  <tr>
                    <th className="w-50" onClick={() => handleSortingRoles('jobTitle')}>
                      <MessageTableTitle>
                        <div>Role Title</div>
                        <div>{activeSortRoles?.jobTitle && <ChevronTableHeader ascending={isRoleASC} />}</div>
                      </MessageTableTitle>
                    </th>
                    <th className="w-25" onClick={() => handleSortingRoles('lastCommentCreatedAt')}>
                      <MessageTableTitle>
                        <div>Latest Update</div>
                        <div>{activeSortRoles?.lastCommentCreatedAt && <ChevronTableHeader ascending={isRoleASC} />}</div>
                      </MessageTableTitle>
                    </th>
                    <th className="w-25 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {jobTopics !== undefined && jobTopics.map((item) => (
                    <tr key={item.id}>
                      <TDC onClick={() => handleFloatingChat(item)}>{item?.jobTitle}</TDC>
                      <TD>{formatStringToDate(item?.lastCommentCreatedAt ?? item?.createdAt)}</TD>
                      <td align="center">
                        <Button size="sm" onClick={() => handleFloatingChat(item)} style={{marginRight: '.8rem', marginBottom: isMobile ? '.75rem' : ''}}>
                          <EyeFill /> View
                        </Button>
                        {(!item?.isResolvedByOwner && !item?.isResolvedByAuthor) ? (
                          <Button size="sm" onClick={() => handleRoleResolvingMessage(item?.id)}>
                            <SendCheck /> Resolve
                          </Button>
                        ) : (
                          <Button size="sm" variant="secondary" disabled>
                            <Check2Square /> Resolved
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {jobTopics.length === 0 && <p>You have no role queries at this time</p>}
              {roleHasNext && (
                <CustomPagination
                  ref={roleQueriesPaginationRef}
                  onPaginationChange={(args) => runPagination(args.skip, args.take)}
                />
              )}
            </Card.Body>
          </TabCardContainer>
        </Tab>
        <Tab eventKey="candidates" title="Candidate Queries" style={{position: 'relative'}}>
          {!isMobile &&
            <ToggleContainer>
              <Toggle
                checked={!hideCandidateResolved}
                icons={false}
                onChange={() => setHideCandidateResolved(val => !val)}
              />
              <TextResolved>show resolved queries</TextResolved>
            </ToggleContainer>
          }
          <TabCardContainer>
            <Card.Body>
              {isMobile && (
                <ToggleMobileContainer>
                  <Toggle
                    checked={!hideCandidateResolved}
                    icons={false}
                    onChange={() => setHideCandidateResolved(val => !val)}
                  />
                  <TextResolved>show resolved queries</TextResolved>
                </ToggleMobileContainer>
              )}
              <Table bordered>
                <thead>
                  <tr>
                    <th className="w-50" onClick={() => handleSortingCandidates('idealJobTitles')}>
                      <MessageTableTitle>
                        <div>Candidate Title</div>
                        <div>{activeSortCandidates?.idealJobTitles && <ChevronTableHeader ascending={isCandidateASC} />}</div>
                      </MessageTableTitle>
                    </th>
                    <th className="w-25" onClick={() => handleSortingCandidates('lastCommentCreatedAt')}>
                      <MessageTableTitle>
                        <div>Latest Update</div>
                        <div>{activeSortCandidates?.lastCommentCreatedAt && <ChevronTableHeader ascending={isCandidateASC} />}</div>
                      </MessageTableTitle>
                    </th>
                    <th className="w-25 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {candidateTopics !== undefined && candidateTopics.map((item) => (
                    <tr key={item?.id}>
                      <TDC onClick={() => handleFloatingChat(item)}>{item?.idealJobTitles}</TDC>
                      <TD>{formatStringToDate(item?.lastCommentCreatedAt ?? item?.createdAt)}</TD>
                      <td align="center">
                        <Button size="sm" onClick={() => handleFloatingChat(item)} style={{marginRight: '.8rem', marginBottom: isMobile ? '.75rem' : ''}}>
                          <EyeFill /> View
                        </Button>
                        {(!item?.isResolvedByOwner && !item?.isResolvedByAuthor) ? (
                          <Button size="sm" onClick={() => handleCandidateResolvingMessage(item?.id)}>
                            <SendCheck /> Resolve
                          </Button>
                        ) : (
                          <Button size="sm" variant="secondary" disabled>
                            <Check2Square /> Resolved
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {candidateTopics.length === 0 && <p>You have no candidate queries at this time</p>}
              {candidateHasNext && (
                <CustomPagination
                  ref={candidateQueriesPaginationRef}
                  onPaginationChange={(args) => runPagination(args.skip, args.take)}
                />
              )}
            </Card.Body>
          </TabCardContainer>
        </Tab>
      </Tabs>
      <p>
        <a href={guide} target="_blank" rel="noreferrer">
          Click here to view the User Guide.
        </a>
      </p>
      <FloatingChatMessage
        isOpenChat={isOpenChat}
        onHideModal={() => setIsOpenChat(false)}
        topic={topicItem}
        userProfile={userProfile}
        client={client}
        isRole={tabKey === "roles"}
      />
      <Notification
        delay={3000}
        toastData={{show: showToast, color: EToastColorStatus.SUCCESS, position: EToastPosition.BOTTOM_END, message: "Success Resolved Message"}}
        onClose={() => setShowToast(false)}
      />
    </Container>
  );
};

export default Messages;
