import { Auth } from 'aws-amplify';
import config from '../../config';
import { useEffect, useState } from 'react';

const callApi = async (code: string, error: string | null) => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const headers = new Headers();
  headers.set("Authorization", `Bearer ${token}`);
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');

  await fetch(config.vincere.callbackUrl, {
    headers,
    method: 'POST',
    body: JSON.stringify({ code, error })
  });
}

export const VincereCallback = () => {
  const [result, setResult] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const urlParts = window.location.href.split('?');
    if (urlParts.length > 1) {
      let queryString = new URLSearchParams(urlParts[1]);
      const code = queryString.get('code');
      const error = queryString.get('error');
      if (code) {
        window.history.replaceState({}, document.title, "/connections/vincere/callback");
        callApi(code, error)
          .then(() => {
            setResult(true);
          })
          .catch(e => {
            setResult(false);
          });
      }

    }
  }, []);

  if (result === true) {
    return <>Connected</>;
  }
  if (result === false) {
    return <>Error</>;
  }

  return <>Connecting...</>;
};


