import { useState } from "react";
import styled from "styled-components";
import { ArrowDownUp } from "react-bootstrap-icons";

const SelectContainer = styled.div`
  margin: 1rem 0;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span`
  margin-right: 1rem;
`;

const SortIconWrapper = styled.div`
  border: 1px solid #227b7e; 
  padding: 5px 8px;
  cursor: pointer;
  &:hover {
    background-color: #ededed;
  }
`;

const SelectCandidatesMobile = ({onSortingCandidatesData = (data: any) => {}}) => {
  const options = [
    { value: '', text: '-- Select --' },
    { value: 'firstName', text: 'Name' },
    { value: 'idealJobTitles', text: 'Job Title' },
    { value: 'industry', text: 'Industry' },
    { value: 'location', text: 'Location' },
    { value: 'yearsOfExperience', text: 'Exp (years)' },
    { value: 'workType', text: 'Type' },
    { value: 'audience', text: 'Audience' },
    { value: 'matches', text: 'Matches' },
    { value: 'status', text: 'Status' },
  ];

  const [toggle, setToggle] = useState(options[0]?.value);

  const handleChange = (e: any) => {
    onSortingCandidatesData((e?.target as HTMLButtonElement).value);
    setToggle((e?.target as HTMLButtonElement).value);
  }

  return (
    <SelectContainer>
      <div>
        <Title>Sort by</Title>
        <select onChange={handleChange}>
          {options.map((item, i) => (
            <option key={i} value={item?.value}>{item?.text}</option>
          ))}
        </select>
      </div>
      <SortIconWrapper onClick={() => onSortingCandidatesData(toggle)}>
        <ArrowDownUp size={15} color="#227b7e" />
      </SortIconWrapper>
    </SelectContainer>
  )
}

export default SelectCandidatesMobile;
