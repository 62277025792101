import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { Modal, Button, Form } from "react-bootstrap";
import { ErrorWrapper } from "../../../../sharedStyles";
import { EToastColorStatus, EToastPosition } from "../../../../interfaces";
import { INVITE_PROJECT_MEMBER } from "../../../../GraphQL/settings/mutations";

const InviteEmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

interface IInviteMemberProps {
  showInviteModal: boolean;
  onCloseModalInvite: () => void;
  projectId: number;
  onHandleCreateToast: (
    color    : EToastColorStatus,
    position : EToastPosition,
    message  : string,
  ) => void;
}

const InviteMemberModal = ({
  showInviteModal,
  onCloseModalInvite,
  projectId,
  onHandleCreateToast = () => {},
} : IInviteMemberProps ) => {
  const [inviteProjectMember] = useMutation(INVITE_PROJECT_MEMBER, {
    onCompleted() {
      onHandleCreateToast(
        EToastColorStatus.SUCCESS,
        EToastPosition.BOTTOM_END,
        "Successfully Invite Project Member",
      );
    },
    onError() {
      // note: only temporary for success invite
      onHandleCreateToast(
        EToastColorStatus.SUCCESS,
        EToastPosition.BOTTOM_END,
        "Successfully Invite Project Member",
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: InviteEmailSchema,
    onSubmit: (values) => {
      inviteProjectMember({ variables: {
        id: projectId,
        email: values?.email,
      }});
      onCloseModalInvite();
    },
  });

  const { errors, touched } = formik;

  return (
    <Modal centered show={showInviteModal} onHide={onCloseModalInvite}>
      <Modal.Header closeButton>
        <Modal.Title>Invite a user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <p>Enter email address of someone you would like to invite to join your company on Role Relay</p>
          <Form.Group>
            <Form.Label>Email*</Form.Label>
            <Form.Control name="email" type="email" placeholder="e.g. your@mail.com" onChange={formik.handleChange} />
            <ErrorWrapper>{errors.email && touched.email ? errors?.email : ''}</ErrorWrapper>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => formik.submitForm()}>
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default InviteMemberModal;
