import styled, { css } from "styled-components";
import { XCircle } from "react-bootstrap-icons";
import { Container, Card, Popover as BSPopover, InputGroup } from "react-bootstrap";

const CardHeader = styled(Card.Header)`
  font-size: 1rem !important;
  font-weight: bold;
  color: #4d4d4d !important;
`;

const CardFooter = styled(Card.Footer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Well = styled.div`
  padding: 1rem;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
`;

const Popover = styled(BSPopover)`
  color: white;
  background-color: #333;

  & > div:last-of-type {
    color: white;
    background-color: #333;
  }
  & > div:first-of-type {
    display: none;
  }
`;

const DesktopContainer = styled.div`
  display: none;
  @media (min-width: 960px) {
    display: block;
  }
`;

const MobileContainer = styled.div`
  display: block;
  @media (min-width: 960px) {
    display: none;
  }
`;

const MobileCard = styled.div`
  display: flex;

  & div:last-of-type {
    text-align: right;
    flex-grow: 1;
    margin-left: 1rem;
  }
  border-bottom: 1px solid #ccc;
  margin-bottom: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const ToggleIndustryWrapper = styled.div<{screen: boolean}>`
  display: flex;
  min-width: 300px;
  width: 100%;
  justify-content: end;
  align-items: right;
  margin-right: 8px;
  margin-bottom: 1rem;
  ${(props => !props.screen && css`
    margin-bottom: 1rem;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  `)};
`;

const TextIndustryWrapper = styled.div`
  color: #212529;
  margin-left: 8px;
`;

const SearchContainer = styled.div<{screen: boolean}>`
  ${(props => props.screen && css`
    position: absolute;
    top: -50px;
    right: 0px;
    max-width: 860px;
    display: flex;
  `)}
`;

const AdvancedSearchContainer = styled.div`
  position: relative;
  background-color: #888;
`;

const AdvancedSearchButton = styled.div<{screen: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props => props.screen && css`
    width: 130px;
    margin-left: 10px;
    font-size: 16px;
  `)}

  ${props => !props.screen && css`
    margin-top: .5rem;
    font-size: 18px;
  `}
`;

const ClearSummarySearch = styled.span`
  cursor: pointer;
  color: #0D6EFD;
  text-decoration: underline;
  margin-left: 5px;
`;

const PageContainer = styled(Container)`
  max-width: 1920px;
`;

const ErrorWrapper = styled.div`
  color: #ff4154;
  height: 20px;
  margin: 5px 0;
  font-size: 14px;
`;

const ButtonRightCorner = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const InputSearchGroup = styled(InputGroup)<{screen: boolean}>`
  position: relative;
  ${(props => props.screen && css`
    width: 400px;
  `)};
`;

const ClearSearchButton = styled(XCircle)<{screen: boolean}>`
  position: absolute;
  right: 100px;
  z-index: 5;
  cursor: pointer;
  color: #555;
  top: ${props => props.screen ? '6px': '10px'}
`;

const TabCardContainer = styled(Card)`
  border-top: none !important;
  border-radius: 0 0 0.375rem 0.375rem;
  border: 1px solid #dee2e6;
`;

const SwitchForm = styled.p`
  margin-bottom: 1rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #0a58ca;
  }
`;

const ModalFooterWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ModalFooterLeft = styled.div`
  width: 70%;
`;

const ModalFooterRight = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
`;

export {
  CardHeader,
  CardFooter,
  Well,
  Popover,
  DesktopContainer,
  MobileContainer,
  MobileCard,
  SearchContainer,
  PageContainer,
  ErrorWrapper,
  ButtonRightCorner,
  AdvancedSearchButton,
  AdvancedSearchContainer,
  ClearSummarySearch,
  PaginationContainer,
  InputSearchGroup,
  ClearSearchButton,
  TabCardContainer,
  ToggleIndustryWrapper,
  TextIndustryWrapper,
  SwitchForm,
  ModalFooterWrapper,
  ModalFooterLeft,
  ModalFooterRight,
};
