import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  Card,
  Modal,
  Button,
  Form,
  Badge,
  Table,
  Row,
  Col,
} from "react-bootstrap";
import { ButtonRightCorner } from "../../sharedStyles";
import { TrashFill } from "react-bootstrap-icons";
import { GET_PARTNERS } from "../../GraphQL/settings/queries";
import InvitePartnerModal from "./InvitePartnerModal";
import {
  DELETE_PARTNER,
  APPROVE_PARTNER,
} from "../../GraphQL/settings/mutations";
import confirmWithModal from "../Global/confirmWithModal";

interface Props {
  companyId: number;
}

const Partnerships = ({ companyId }: Props) => {
  const { loading, error, data, refetch } = useQuery(GET_PARTNERS);
  const [deletePartner] = useMutation(DELETE_PARTNER);
  const [approvePartner] = useMutation(APPROVE_PARTNER);

  const [showInvitePartnerModal, setShowInvitePartnerModal] = useState(false);

  const handleInvitePartner = () => {
    setShowInvitePartnerModal(true);
  };

  const handleDelete = async (id: string) => {
    const confirmation = await confirmWithModal(
      "Are you sure you want to delete this partnership?"
    );
    if (confirmation) {
      try {
        await deletePartner({ variables: { companyId: id } });
        alert("Partnership deleted");
        refetch();
      } catch (err) {
        alert("Partnership unable to be deleted. Please try again soon");
        console.log(err);
      }
    }
  };

  const handleAccept = async (id: string) => {
    try {
      await approvePartner({ variables: { companyId: id } });
      alert("Partnership approved");
      refetch();
    } catch (err) {
      alert("Something went wrong. Please try again soon");
      console.log(err);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading partnerships</p>;

  return (
    <Card className="border-0">
      <InvitePartnerModal
        show={showInvitePartnerModal}
        onHide={() => setShowInvitePartnerModal(false)}
      />
      <Card.Body className="p-0">
        <Row>
          <Col>
            <strong>Partner Agencies:</strong>
            {data?.partners?.items?.length === 0 && (
              <p>You are not currently partnered with any agencies</p>
            )}
            <Table>
              <tbody>
                {data?.partners?.items?.map((partner: any, index: number) => (
                  <tr key={index}>
                    <td>{companyId === partner.companyAId
                                ? partner.companyBName
                                : partner.companyAName}</td>
                    <td>
                      <Badge
                        bg={
                          partner.status === "PENDING" ? "warning" : "secondary"
                        }
                      >
                        {partner.status}
                      </Badge>
                    </td>
                    <td>
                      {" "}
                      {partner.status === "PENDING" &&
                        partner.companyAId !== companyId && (
                          <Button
                            onClick={() => handleAccept(partner.companyAId)}
                            size="sm"
                            variant="primary"
                          >
                            Accept
                          </Button>
                        )}
                    </td>
                    <td>
                      {partner.status !== "REMOVED" && (
                        <Button
                          onClick={() =>
                            handleDelete(
                              companyId === partner.companyAId
                                ? partner.companyBId
                                : partner.companyAId
                            )
                          }
                          variant="secondary"
                          size="sm"
                        >
                          <TrashFill />
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col></Col>
        </Row>
        <ButtonRightCorner>
          <Button size="sm" variant="primary" onClick={handleInvitePartner}>
            Invite partner
          </Button>
        </ButtonRightCorner>
      </Card.Body>
    </Card>
  );
};

export default Partnerships;
