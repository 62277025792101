import { gql } from '@apollo/client';

export const GET_ROLES = gql`
  query GetActiveRoles($skip: Int, $take: Int, $order: [JobSortInput!]) {
    jobs(skip: $skip, take: $take, order: $order) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        externalId
        createdAt
        registrationDate
        salaryMin
        salaryMax
        summary
        title
        workType
        startDate
        split
        feeValue
        feeType
        engagementType
        replacementGuarantee
        termsSigned
        industry
        clientName
        placementStartDate
        placementEndDate
        workingHours
        status
        matches
        audience
        guaranteeDetails
        currency
        location {
          countryCode
          postCode
          state
          suburb
          displayName
          localityId
        }
        jobOwners {
          userId
          email
        }
        projectBoards {
          id
          projectId
          title
          description
        }
      }
    }
  }
`;

export const GET_ROLE_BY_ID = gql`
  query GetRoleById($id: Int!) {
    jobs(id: $id) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        externalId
        createdAt
        registrationDate
        salaryMin
        salaryMax
        summary
        title
        workType
        startDate
        split
        feeValue
        feeType
        engagementType
        replacementGuarantee
        termsSigned
        industry
        clientName
        placementStartDate
        placementEndDate
        workingHours
        status
        matches
        audience
        guaranteeDetails
        currency
        location {
          countryCode
          postCode
          state
          suburb
          displayName
          localityId
        }
        jobOwners {
          userId
          email
        }
        projectBoards {
          id
          projectId
          title
          description
        }
      }
    }
  }
`;

export const GET_PUBLIC_ROLES = gql`
  query GetPublicRoles($skip: Int!, $take: Int!, $search: String!, $where: PublicJobFilterInput, $filterByPreferences: Boolean) {
    publicJobs(skip: $skip, take: $take, search: $search, where: $where, filterByPreferences: $filterByPreferences) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        externalId
        title
        workType
        summary
        industry
        salaryMin
        salaryMax
        startDate
        placementStartDate
        placementEndDate
        workingHours
        status
        engagementType
        termsSigned
        replacementGuarantee
        feeMin
        feeMax
        guaranteeDetails
        currency
        location {
          countryCode
          postCode
          state
          suburb
          displayName
          localityId
        }
      }
    }
  }
`;

export const GET_PARTNER_ROLES = gql`
  query GetPartnerRoles($skip: Int!, $take: Int!, $search: String!, $where: PartnerJobFilterInput) {
    partnerJobs(skip: $skip, take: $take, search: $search, where: $where) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        externalId
        title
        workType
        summary
        industry
        salaryMin
        salaryMax
        startDate
        placementStartDate
        placementEndDate
        workingHours
        status
        engagementType
        termsSigned
        replacementGuarantee
        feeMin
        feeMax
        companyName
        companyId
        guaranteeDetails
        currency
        location {
          countryCode
          postCode
          state
          suburb
          displayName
          localityId
        }
      }
    }
  }
`;

export const GET_PROJECT_ROLES = gql`
  query ProjectJobs($skip: Int!, $take: Int!, $projectJobBoardId: String!, $where: ProjectJobFilterInput) {
    projectJobs (skip: $skip, take: $take, projectJobBoardId: $projectJobBoardId, where: $where) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        externalId
        title
        workType
        summary
        industry
        currency
        salaryMin
        salaryMax
        startDate
        placementStartDate
        placementEndDate
        workingHours
        status
        engagementType
        termsSigned
        replacementGuarantee
        feeMin
        feeMax
        companyName
        companyId
        guaranteeDetails
        isOwner
        location {
          countryCode
          suburb
          state
          postCode
          displayName
          localityId
        }
      }
    }
  }
`;

export const GET_MATCHED_ROLE = gql`
  query GetMatchedCandidate($matchId: String!) {
    matchedJob(matchId: $matchId) {
      id,
      externalId
      title
      workType
      summary
      industry
      salaryMin
      salaryMax
      startDate
      placementStartDate
      placementEndDate
      workingHours
      status
      engagementType
      termsSigned
      replacementGuarantee
      feeMin
      feeMax
      companyName
      companyId
      summary
      guaranteeDetails
      location {
          countryCode
          postCode
          state
          suburb
          displayName
          localityId
      }
   }
}`;

export const GET_IMPORTED_JOBS = gql`
  query GetImportedJobs {
    importedJobs {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        externalId
        title
        summary
        workType
        currency
        salaryMin
        salaryMax
        status
        registrationDate
        startDate
        placementStartDate
        placementEndDate
        termsSigned
        replacementGuarantee
        engagementType
        feeType
        feeValue
        split
        industry
        clientName
        workingHours
        matches
        audience
        guaranteeDetails
        createdAt
        updatedAt
        location {
          countryCode
          suburb
          state
          postCode
          displayName
          localityId
        }
        jobOwners {
          userId
          email
        }
      }
    }
  }
`;

export const GET_PROJECT_JOB_BOARDS = gql`
  query ProjectJobBoards {
    projectJobBoards {
      totalCount
      items {
        id
        projectId
        title
        description
      }
    }
  }
`;
