import styled from 'styled-components';
import { Badge as BSBadge } from "react-bootstrap";
import { Well } from "../../../sharedStyles";

const Badge = styled(BSBadge)<{position: string}>`
  ${({position}) => position === "modal" ? `
    font-size: 1rem;
    ::before {content: "Status: ";}
    margin-bottom: 8px;
  ` : `
    white-space: break-spaces;
  `}
`;

interface IRenderStatusProps {
  position: string;
  status: string;
  otherStatus: string;
}

const RenderStatus = ({position, status, otherStatus}: IRenderStatusProps) => {
  if (status === "PENDING" || otherStatus === "INTEREST_CONFIRMED") {
    return <Badge bg="warning" position={position}>Pending your response</Badge>;
  } else if (
    status === "AGENT_CONFIRMED" &&
    (otherStatus === "INTEREST_CONFIRMED" || otherStatus === "PENDING")
  ) {
    return (
      <Badge bg="warning" position={position}>Accepted - pending response from other agent</Badge>
    );
  } else if (
    status === "AGENT_CONFIRMED" &&
    otherStatus === "AGENT_CONFIRMED"
  ) {
    return (
      <>
        <Badge position={position}>Connected</Badge>
        {position === "modal" && (
          <Well>
            You and the Candidate Agency have been connected - please find their
            details below. You can now contact the Candidate Agency to confirm how
            the recruitment process will be managed. Please ensure you have
            confirmation of how the process will be managed in writing before any
            party contacts a referred candidate or client.
          </Well>
        )}
      </>
    );
  } else if (status === "AGENT_REJECTED" || status === "INTEREST_REJECTED") {
    return <Badge bg="danger" position={position}>Rejected</Badge>;
  } else if (
    otherStatus === "AGENT_REJECTED" ||
    otherStatus === "INTEREST_REJECTED"
  ) {
    return <Badge bg="danger" position={position}>Rejected by other agent</Badge>;
  } else if (
    status === "SUCCESSFUL_PLACEMENT" &&
    otherStatus === "AGENT_CONFIRMED"
  ) {
    return (
      <Badge bg="warning" position={position}>
        Placement claimed - pending confirmation from other agent
      </Badge>
    );
  } else if (
    status === "AGENT_CONFIRMED" &&
    otherStatus === "SUCCESSFUL_PLACEMENT"
  ) {
    return (
      <Badge bg="warning" position={position}>Placement claimed - pending your confirmation</Badge>
    );
  } else if (
    status === "AGENT_CONFIRMED" &&
    otherStatus === "SUCCESSFUL_PLACEMENT"
  ) {
    return (
      <Badge bg="warning" position={position}>Placement claimed - pending your confirmation</Badge>
    );
  } else if (
    status === "SUCCESSFUL_PLACEMENT" &&
    otherStatus === "SUCCESSFUL_PLACEMENT"
  ) {
    return <Badge position={position}>Placement confirmed</Badge>;
  } else if (status === "UNSUCCESSFUL" || otherStatus === "UNSUCCESSFUL") {
    return <Badge bg="danger" position={position}>Unsuccessful</Badge>;
  } else if (status === "SETTLED" && otherStatus !== "SETTLED") {
    return (
      <Badge bg="warning" position={position}>
        Settlement claimed - pending confirmation from other agent
      </Badge>
    );
  } else if (status !== "SETTLED" && otherStatus === "SETTLED") {
    return (
      <Badge bg="warning" position={position}>Settlement claimed - pending your confirmation</Badge>
    );
  } else if (status === "SETTLED" && otherStatus === "SETTLED") {
    return <Badge position={position}>Placement settled</Badge>;
  }

  return <Badge position={position}>Unknown status</Badge>;
};

export default RenderStatus;
