import { gql } from "@apollo/client";

export const GET_ROLE_TOPICS = gql`
  query GetJobTopics($skip: Int, $take: Int, $hideResolved: Boolean, $order: [JobTopicSortInput!]) {
    jobTopics(skip: $skip, take: $take, hideResolved: $hideResolved, order: $order) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        message
        jobId
        userId
        createdAt
        companyId
        jobTitle
        locationDisplayName
        lastCommentCreatedAt
        isResolvedByOwner
        isResolvedByAuthor
      }
    }
  }
`;

export const GET_ROLE_TOPIC_BY_ID = gql`
  query GetJobTopics($jobId: Int!) {
    jobTopics(jobId: $jobId) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        message
        jobId
        userId
        createdAt
        companyId
        jobTitle
        locationDisplayName
      }
    }
  }
`;

export const GET_ROLE_TOPIC_COMMENTS = gql`
  query GetJobTopicComments($topicId: String!, $skip: Int, $take: Int) {
    jobTopicComments(jobTopicId: $topicId, skip: $skip, take: $take) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        message
        userId
        createdAt
        isOwner
        companyId
      }
    }
  }
`;

export const GET_CANDIDATE_TOPICS = gql`
  query GetCandidateTopics ($skip: Int, $take: Int, $hideResolved: Boolean, $order: [CandidateTopicSortInput!]) {
    candidateTopics(skip: $skip, take: $take, hideResolved: $hideResolved, order: $order) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        message
        candidateId
        userId
        createdAt
        companyId
        jobTitle
        idealJobTitles
        locationDisplayName
        lastCommentCreatedAt
        isResolvedByOwner
        isResolvedByAuthor
      }
    }
  }
`;

export const GET_CANDIDATE_TOPIC_BY_ID = gql`
  query GetCandidateTopics($candidateId: Int!) {
    jobTopics(candidateId: $candidateId) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        message
        candidateId
        userId
        createdAt
        companyId
        jobTitle
        idealJobTitles
        locationDisplayName
      }
    }
  }
`;

export const GET_CANDIDATE_TOPIC_COMMENTS = gql`
  query GetCandidateTopicComments($topicId: String!, $skip: Int, $take: Int) {
    candidateTopicComments(candidateTopicId: $topicId, skip: $skip, take: $take) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        message
        userId
        createdAt
        isOwner
        companyId
      }
    }
  }
`;
