import React from "react";
import { Card, Container } from "react-bootstrap";

const Privacy = () => (
  <>
    <Container>
      <br />
      <br />
      <h4>ROLE RELAY PTY LTD – Privacy policy</h4>
      <br />
      <Card>
        <Card.Body>
          <p>
            This Privacy Policy sets out our commitment to protecting the
            privacy of personal information provided to us, or otherwise
            collected by us, offline or online, including through our platform
            and website. In this Privacy Policy we, us or our means Role Relay
            Pty Ltd ABN: 48 653 320 882.
          </p>
          <h4>Personal information</h4>
          <p>
            The types of personal information we may collect about you include:
          </p>
          <ul>
            <li>your name;</li>
            <li>
              your contact details, including email address, mailing address,
              street address and/or telephone number;
            </li>
            <li>your age and/or date of birth;</li>
            <li>your credit card details;</li>
            <li>your demographic information, such as postcode;</li>
            <li>your preferences and/or opinions;</li>
            <li>information you provide to us through customer surveys;</li>
            <li>
              details of products and services we have provided to you and/or
              that you have enquired about, and our response to you;
            </li>
            <li>
              your browser session and geo-location data, device and network
              information, statistics on page views and sessions, acquisition
              sources, search queries and/or browsing behaviour;
            </li>
            <li>
              information about your access and use of our Site, including
              through the use of Internet cookies, your communications with our
              Site, the type of browser you are using, the type of operating
              system you are using and the domain name of your Internet service
              provider;
            </li>
            <li>
              additional personal information that you provide to us, directly
              or indirectly, through your use of our Site, associated
              applications, associated social media platforms and/or accounts
              from which you permit us to collect information; and
            </li>
            <li>
              any other personal information requested by us and/or provided by
              you or a third party.
            </li>
          </ul>
          <p>
            We may collect these types of personal information directly from you
            or from third parties.
          </p>
          <h4>Collection and use of personal information</h4>
          <p>
            We may collect, hold, use and disclose personal information for the
            following purposes:
          </p>
          <ul>
            <li>
              to enable you to access and use our Site, associated applications
              and associated social media platforms;
            </li>
            <li>to contact and communicate with you;</li>
            <li>for internal record keeping and administrative purposes;</li>
            <li>
              for analytics, market research and business development, including
              to operate and improve our Site, associated applications and
              associated social media platforms;
            </li>
            <li>
              to run competitions and/or offer additional benefits to you;{" "}
            </li>
            <li>
              for advertising and marketing, including to send you promotional
              information about our products and services and information about
              third parties that we consider may be of interest to you;
            </li>
            <li>
              to comply with our legal obligations and resolve any disputes that
              we may have; and
            </li>
            <li>Disclosure of personal information to third parties</li>
          </ul>
          <p>We may disclose personal information to:</p>
          <ul>
            <li>
              third party service providers for the purpose of enabling them to
              provide their services, including (without limitation) IT service
              providers, data storage, web- hosting and server providers, debt
              collectors, maintenance or problem-solving providers, marketing or
              advertising providers, professional advisors and payment systems
              operators;
            </li>
            <li>our employees, contractors and/or related entities;</li>
            <li>our existing or potential agents or business partners;</li>
            <li>sponsors or promoters of any competition we run;</li>
            <li>
              anyone to whom our business or assets (or any part of them) are,
              or may (in good faith) be, transferred;
            </li>
            <li>
              credit reporting agencies, courts, tribunals and regulatory
              authorities, in the event you fail to pay for goods or services we
              have provided to you;
            </li>
            <li>
              courts, tribunals, regulatory authorities and law enforcement
              officers, as required by law, in connection with any actual or
              prospective legal proceedings, or in order to establish, exercise
              or defend our legal rights;
            </li>
            <li>
              third parties, including agents or sub-contractors, who assist us
              in providing information, products, services or direct marketing
              to you. This may include parties located, or that store data,
              outside of Australia;
            </li>
            <li>
              third parties to collect and process data, such as [Google
              Analytics or other relevant businesses]. This may include parties
              that store data outside of Australia.
            </li>
          </ul>
          <p>
            By providing us with personal information, you consent to the
            disclosure of your personal information to third parties who reside
            outside Australia and acknowledge that we are not required to ensure
            that those third parties comply with Australian privacy laws.{" "}
          </p>
          <h5>
            How we treat personal information that is also sensitive information
          </h5>
          <p>
            Sensitive information is a subset of personal information that is
            given a higher level of protection under the Australian Privacy
            Principles. Sensitive information means information relating to your
            racial or ethnic origin, political opinions, religion, trade union
            or other professional associations or memberships, philosophical
            beliefs, sexual orientation or practices, criminal records, health
            information or biometric information. Sensitive information
            includes:
          </p>
          <ul>
            <li>an individual’s racial or ethnic origin,</li>
            <li>health information,</li>
            <li>political opinions,</li>
            <li>membership of a political association,</li>
            <li>professional or trade association or trade union,</li>
            <li>religious beliefs or affiliations,</li>
            <li>philosophical beliefs,</li>
            <li>sexual orientation or practices,</li>
            <li>criminal record,</li>
            <li>genetic information,</li>
            <li>
              biometric information that is to be used for certain purposes, and
            </li>
            <li>biometric templates.</li>
          </ul>

          <h5>Your rights and controlling your personal information</h5>
          <p>
            Choice and consent: Please read this Privacy Policy carefully. By
            providing personal information to us, you consent to us collecting,
            holding, using and disclosing your personal information in
            accordance with this Privacy Policy. You do not have to provide
            personal information to us, however, if you do not, it may affect
            your use of this Site or the products and/or services offered on or
            through it.
          </p>
          <p>
            <strong>Information from third parties:</strong> If we receive
            personal information about you from a third party, we will protect
            it as set out in this Privacy Policy. If you are a third party
            providing personal information about somebody else, you represent
            and warrant that you have such person’s consent to provide the
            personal information to us.
          </p>
          <p>
            <strong>Restrict:</strong> You may choose to restrict the collection
            or use of your personal information. If you have previously agreed
            to us using your personal information for direct marketing purposes,
            you may change your mind at any time by contacting us using the
            details below.
          </p>
          <p>
            <strong>Access:</strong> You may request details of the personal
            information that we hold about you. An administrative fee may be
            payable for the provision of such information. In certain
            circumstances, as set out in the Privacy Act 1988 (Cth), we may
            refuse to provide you with personal information that we hold about
            you.{" "}
          </p>
          <p>
            <strong>Correction:</strong> If you believe that any information we
            hold about you is inaccurate, out of date, incomplete, irrelevant or
            misleading, please contact us using the details below. We will take
            reasonable steps to correct any information found to be inaccurate,
            incomplete, misleading or out of date.
          </p>
          <p>
            <strong>Complaints:</strong> If you believe that we have breached
            the Australian Privacy Principles and wish to make a complaint,
            please contact us using the details below and provide us with full
            details of the alleged breach. We will promptly investigate your
            complaint and respond to you, in writing, setting out the outcome of
            our investigation and the steps we will take to deal with your
            complaint.
          </p>
          <p>
            <strong>Unsubscribe:</strong> To unsubscribe from our e-mail
            database or opt-out of communications (including marketing
            communications), please contact us using the details below or
            opt-out using the opt-out facilities provided in the communication.
          </p>

          <h5>Storage and security</h5>
          <p>
            We are committed to ensuring that the personal information we
            collect is secure. In order to prevent unauthorised access or
            disclosure, we have put in place suitable physical, electronic and
            managerial procedures to safeguard and secure the personal
            information and protect it from misuse, interference, loss and
            unauthorised access, modification and disclosure.
          </p>
          <p>
            We cannot guarantee the security of any information that is
            transmitted to or by us over the Internet. The transmission and
            exchange of information is carried out at your own risk. Although we
            take measures to safeguard against unauthorised disclosures of
            information, we cannot assure you that the personal information we
            collect will not be disclosed in a manner that is inconsistent with
            this Privacy Policy.
          </p>
          <h5>Cookies and web beacons</h5>
          <p>
            We may use cookies on our Site from time to time. Cookies are text
            files placed in your computer's browser to store your preferences.
            Cookies, by themselves, do not tell us your email address or other
            personally identifiable information. However, they do allow third
            parties, such as Google and Facebook, to cause our advertisements to
            appear on your social media and online media feeds as part of our
            retargeting campaigns. If and when you choose to provide our Site
            with personal information, this information may be linked to the
            data stored in the cookie.
          </p>
          <p>
            We may use web beacons on our Site from time to time. Web beacons
            (also known as Clear GIFs) are small pieces of code placed on a web
            page to monitor the visitor’s behaviour and collect data about the
            visitor’s viewing of a web page. For example, web beacons can be
            used to count the users who visit a web page or to deliver a cookie
            to the browser of a visitor viewing that page.
          </p>

          <h5>Links to other websites</h5>
          <p>
            Our Site may contain links to other websites. We do not have any
            control over those websites and we are not responsible for the
            protection and privacy of any personal information which you provide
            whilst visiting those websites. Those websites are not governed by
            this Privacy Policy.
          </p>
          <h5>Amendments</h5>
          <p>
            We may, at any time and at our discretion, vary this Privacy Policy
            by publishing the amended Privacy Policy on our Site. We recommend
            you check our Site regularly to ensure you are aware of our current
            Privacy Policy.
          </p>
          <p>
            For any questions or notices, please contact our Privacy Officer at:
          </p>
          <p>
            Role Relay Pty Ltd ABN 48 653 320 882
            <br />
            Email:{" "}
            <a href="mailto:support@rolerelay.com">
              support@rolerelay.com
            </a>{" "}
            <br />
          </p>
          <p>Last update: 22 September 2021</p>
        </Card.Body>
      </Card>
    </Container>
  </>
);

export default Privacy;
