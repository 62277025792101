import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { Card } from "react-bootstrap";
import { formatStringToDate } from "../../utils";

const Bubble = styled.div<{ isMe: boolean; isMyAgency: boolean }>`
  background-color: lightgray;
  border-radius: 12px 12px 12px 0px;
  padding: 8px;
  width: 95%;
  max-width: 350px;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 24px;
  position: relative;

  ${({ isMyAgency }) =>
    isMyAgency &&
    css`
      background-color: #cffbcf;
      border-radius: 12px 12px 0px 12px;
      margin-right: 0;
      margin-left: auto;
      ::before {
        content: "Someone at your agency: ";
        font-size: small;
        display: block;
      }
    `}
  ${({ isMe }) =>
    isMe &&
    css`
      background-color: #a7dfa7;
      border-radius: 12px 12px 0px 12px;
      margin-right: 0;
      margin-left: auto;
      ::before {
        content: "You:";
        font-size: small;
        display: block;
      }
    `}

  p {
    margin-top: 6px;
    margin-right: 0;
    font-size: small;
    position: absolute;
    color: #888;
    margin-left: -8px;

    ${({ isMyAgency, isMe }) => ( isMyAgency || isMe ) && css`
      margin-right: 0;
      right: 0;
    `}
  }
`;

const ChatScrollContainer = styled(Card.Body)`
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
`;

interface Props {
  userProfile: any;
  topic: any;
  comments?: any;
  onLoadMore: () => void,
  hasNext: boolean,
  onLoading: boolean,
  isSent: boolean,
}

const MessageThread = ({ userProfile, topic, comments, onLoadMore, hasNext, onLoading, isSent }: Props) => {
  const latestMessage = useRef<any>(null);

  const loadScrolling = (e: any) => {
    const elHeight: any = document.getElementById('chat-wrapper')?.offsetHeight;
    if (parseInt(`${-e.target.scrollTop}`) + elHeight + 5 >= e.target.scrollHeight) {
      if (!hasNext) return;
      setTimeout(() => {
        onLoadMore();
      }, 500);
      return;
    }
  }

  useEffect(() => {
    latestMessage?.current?.scrollIntoView({block: 'end'});
  }, [isSent]);

  return (
    <ChatScrollContainer onScroll={loadScrolling} id="chat-wrapper">
      <div ref={latestMessage} />
      {comments !== undefined && comments.map((comment: any) => (
        <Bubble
          key={comment.id}
          isMe={comment.userId === userProfile?.userId}
          isMyAgency={comment.companyId === userProfile?.companyId}
        >
          {comment.message}
          <p>{formatStringToDate(comment?.createdAt)}</p>
        </Bubble>
      ))}
      {onLoading && <p>Loading messages...</p>}
      {!hasNext && (
        <Bubble
          key={topic?.id}
          isMe={topic.userId === userProfile?.userId}
          isMyAgency={topic.companyId === userProfile?.companyId}
        >
          {topic.message}
          <p>{formatStringToDate(topic?.createdAt)}</p>
        </Bubble>
      )}
    </ChatScrollContainer>
  );
};

export default MessageThread;
