import { Modal, Button } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_ROLE, ARCHIVE_JOB } from "../../../GraphQL/roles/mutations";
import { GET_ROLE_BY_ID } from "../../../GraphQL/roles/queries";
import { EToastColorStatus, EToastPosition } from "../../../interfaces";

interface IProps {
  id: number | null;
  show: boolean;
  onHideModal: () => void;
  reloadRoles: () => void;
  onCreateToast?: (
    color: EToastColorStatus,
    position: EToastPosition,
    message: string,
  ) => void;
}

const RoleDeleteModal = ({id, show, onHideModal, reloadRoles, onCreateToast = () => {}}: IProps) => {
  const { loading, data } = useQuery(GET_ROLE_BY_ID, { variables: { id: Number(id) } });

  const [deleteRole] = useMutation(DELETE_ROLE, {
    onCompleted(){
      reloadRoles();
      onHideModal();
      onCreateToast(
        EToastColorStatus.SUCCESS,
        EToastPosition.BOTTOM_END,
        "Role deleted!",
      );
    },
    onError(error: any) {
      const errorMessage = error?.networkError?.result?.errors[0]?.message;
      onHideModal();
      onCreateToast(
        EToastColorStatus.FAILED,
        EToastPosition.BOTTOM_END,
        errorMessage,
      );
    },
  });

  const [archiveJob] = useMutation(ARCHIVE_JOB, {
    onCompleted(){
      reloadRoles();
      onHideModal();
      onCreateToast(
        EToastColorStatus.SUCCESS,
        EToastPosition.BOTTOM_END,
        "Role archived!",
      );
    },
  });

  const handleDeleteRole = () => {
    if (id === null) return
    deleteRole({
      variables: {
        jobId: Number(id),
      },
    });
  }

  const handleArchiveRole = () => {
    if (id === null) return
    archiveJob({
      variables: {
        jobId: `${id}`,
      },
    });
  }

  if (loading) {
    return (
      <Modal show={show} onHide={onHideModal} centered>
        <Modal.Header>
          <Modal.Title>Loading...</Modal.Title>
        </Modal.Header>
        <Modal.Body>Loading...</Modal.Body>
      </Modal>
    )
  };

  if (data?.jobs?.items[0]?.matches > 0) {
    return (
      <Modal show={show} onHide={onHideModal} centered>
        <Modal.Header>
          <Modal.Title>Archive Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This role can not be deleted due to associated matches.
          Do you wish to archive this role instead?
          Archived roles are hidden from your list of roles.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleArchiveRole}>
            Archive
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <Modal show={show} onHide={onHideModal} centered>
      <Modal.Header>
        <Modal.Title>Delete Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you want to delete this role? This process cannot be undone.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHideModal}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDeleteRole}>Delete</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RoleDeleteModal;
