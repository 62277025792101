import { capitalizeWord } from "../../utils";

const fieldParser = (x: string): string => {
  if (x === "idealJobTitles") return "Job Titles";
  if (x === "title") return "Job Title";
  if (x === "workType") return "Work Type";
  return x;
}

const criteriaParser = (x: string): string => {
  if (x === "contains") return "contains";
  if (x === "eq") return "matches";
  if (x === "ncontains") return "does not contain";
  if (x === "neq") return "does not match";
  return "";
}

const advancedSearchSummary = (inputSearch: any, pageType: string) => {
  let summary = "";

  if (inputSearch.length === 1) {
    const F1 = capitalizeWord(fieldParser(inputSearch[0].fieldName));
    const V1 = inputSearch[0].searchValues;
    const C1 = criteriaParser(inputSearch[0].criteria);
    summary = `Showing ${pageType} where ${F1} ${C1} '${V1}'`;
  }

  if (inputSearch.length === 2) {
    const F1 = capitalizeWord(fieldParser(inputSearch[0].fieldName));
    const F2 = capitalizeWord(fieldParser(inputSearch[1].fieldName));
    const C1 = criteriaParser(inputSearch[0].criteria);
    const C2 = criteriaParser(inputSearch[1].criteria);
    const V1 = inputSearch[0].searchValues;
    const V2 = inputSearch[1].searchValues;
    const R1 = inputSearch[1].relation.toUpperCase();
    summary = `Showing ${pageType} where ${F1} ${C1} '${V1}' ${R1} ${F2} ${C2} '${V2}'`;
  }

  if (inputSearch.length === 3) {
    const F1 = capitalizeWord(fieldParser(inputSearch[0].fieldName));
    const F2 = capitalizeWord(fieldParser(inputSearch[1].fieldName));
    const F3 = capitalizeWord(fieldParser(inputSearch[2].fieldName));
    const C1 = criteriaParser(inputSearch[0].criteria);
    const C2 = criteriaParser(inputSearch[1].criteria);
    const C3 = criteriaParser(inputSearch[2].criteria);
    const V1 = inputSearch[0].searchValues;
    const V2 = inputSearch[1].searchValues;
    const V3 = inputSearch[2].searchValues;
    const R1 = inputSearch[1].relation.toUpperCase();
    const R2 = inputSearch[2].relation.toUpperCase();
    summary = `Showing ${pageType} where ${F1} ${C1} '${V1}' ${R1} ${F2} ${C2} '${V2}' ${R2} ${F3} ${C3} '${V3}'`;
  }

  if (inputSearch.length === 4) {
    const F1 = capitalizeWord(fieldParser(inputSearch[0].fieldName));
    const F2 = capitalizeWord(fieldParser(inputSearch[1].fieldName));
    const F3 = capitalizeWord(fieldParser(inputSearch[2].fieldName));
    const F4 = capitalizeWord(fieldParser(inputSearch[3].fieldName));
    const C1 = criteriaParser(inputSearch[0].criteria);
    const C2 = criteriaParser(inputSearch[1].criteria);
    const C3 = criteriaParser(inputSearch[2].criteria);
    const C4 = criteriaParser(inputSearch[3].criteria);
    const V1 = inputSearch[0].searchValues;
    const V2 = inputSearch[1].searchValues;
    const V3 = inputSearch[2].searchValues;
    const V4 = inputSearch[3].searchValues;
    const R1 = inputSearch[1].relation.toUpperCase();
    const R2 = inputSearch[2].relation.toUpperCase();
    const R3 = inputSearch[3].relation.toUpperCase();
    summary = `Showing ${pageType} where ${F1} ${C1} '${V1}' ${R1} ${F2} ${C2} '${V2}' ${R2} ${F3} ${C3} '${V3}' ${R3} ${F4} ${C4} '${V4}'`;
  }

  if (inputSearch.length === 5) {
    const F1 = capitalizeWord(fieldParser(inputSearch[0].fieldName));
    const F2 = capitalizeWord(fieldParser(inputSearch[1].fieldName));
    const F3 = capitalizeWord(fieldParser(inputSearch[2].fieldName));
    const F4 = capitalizeWord(fieldParser(inputSearch[3].fieldName));
    const F5 = capitalizeWord(fieldParser(inputSearch[4].fieldName));
    const C1 = criteriaParser(inputSearch[0].criteria);
    const C2 = criteriaParser(inputSearch[1].criteria);
    const C3 = criteriaParser(inputSearch[2].criteria);
    const C4 = criteriaParser(inputSearch[3].criteria);
    const C5 = criteriaParser(inputSearch[4].criteria);
    const V1 = inputSearch[0].searchValues;
    const V2 = inputSearch[1].searchValues;
    const V3 = inputSearch[2].searchValues;
    const V4 = inputSearch[3].searchValues;
    const V5 = inputSearch[4].searchValues;
    const R1 = inputSearch[1].relation.toUpperCase();
    const R2 = inputSearch[2].relation.toUpperCase();
    const R3 = inputSearch[3].relation.toUpperCase();
    const R4 = inputSearch[4].relation.toUpperCase();
    summary = `Showing ${pageType} where 
              ${F1} ${C1} '${V1}' 
        ${R1} ${F2} ${C2} '${V2}' 
        ${R2} ${F3} ${C3} '${V3}' 
        ${R3} ${F4} ${C4} '${V4}' 
        ${R4} ${F5} ${C5} '${V5}'
    `;
  }

  return summary;
}

export default advancedSearchSummary;
